import React from 'react'
import classnames from 'classnames/bind'
import { Logo } from '@miroculus/nucleo'
import styles from './Spinner.scss'

const cx = classnames.bind(styles)

const Spinner = () => (
  <div className={cx('overlay')}>
    <div className={cx('container')}>
      <Logo color='white' blueGradient animated infinite />
    </div>
  </div>
)

export default Spinner
