import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/display/autorefresh'
import 'codemirror/addon/fold/brace-fold'
import 'codemirror/addon/fold/foldcode'
import 'codemirror/addon/fold/foldgutter'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/theme/base16-light.css'
import 'codemirror/theme/base16-dark.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/javascript-lint'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/show-hint.css'

import styles from './CodeMirror.scss'
import isEqual from 'lodash.isequal'
import debounce from 'lodash.debounce'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const cx = classnames.bind(styles)

function normalizeLineEndings (str) {
  if (!str) return str
  return str.replace(/\r\n|\r/g, '\n')
}

export default class CodeMirror extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.any,
    codeMirrorInstance: PropTypes.func,
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onCursorActivity: PropTypes.func,
    onFocusChange: PropTypes.func,
    onScroll: PropTypes.func,
    options: PropTypes.object,
    path: PropTypes.string,
    value: PropTypes.string,
    preserveScrollPosition: PropTypes.bool,
    hintItems: PropTypes.arrayOf(
      PropTypes.shape({
        namespace: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            displayText: PropTypes.string,
            description: PropTypes.string
          })
        )
      })
    )
  }

  static defaultProps = {
    preserveScrollPosition: false
  }

  state = {
    isFocused: false,
    showingSnippetDescription: false,
    currentHint: null
  }

  getCodeMirrorInstance = () => {
    return this.props.codeMirrorInstance || require('codemirror')
  }

  componentDidMount () {
    const codeMirrorInstance = this.getCodeMirrorInstance()
    this.codeMirror = codeMirrorInstance.fromTextArea(this.textareaNode, this.props.options)
    this.codeMirror.on('change', this.codemirrorValueChanged)
    this.codeMirror.on('cursorActivity', this.cursorActivity)
    this.codeMirror.on('focus', this.focusChanged.bind(this, true))
    this.codeMirror.on('blur', this.focusChanged.bind(this, false))
    this.codeMirror.on('scroll', this.scrollChanged)
    this.codeMirror.on('keyup', this.checkHint)
    this.codeMirror.setValue(this.props.defaultValue || this.props.value || '')
  }

  UNSAFE_componentWillMount () {
    this.UNSAFE_componentWillReceiveProps = debounce(this.UNSAFE_componentWillReceiveProps, 0)
  }

  componentWillUnmount () {
    // is there a lighter-weight way to remove the cm instance?
    if (this.codeMirror) {
      this.codeMirror.toTextArea()
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.codeMirror && nextProps.value !== undefined && nextProps.value !== this.props.value && normalizeLineEndings(this.codeMirror.getValue()) !== normalizeLineEndings(nextProps.value)) {
      if (this.props.preserveScrollPosition) {
        const prevScrollPosition = this.codeMirror.getScrollInfo()
        this.codeMirror.setValue(nextProps.value)
        this.codeMirror.scrollTo(prevScrollPosition.left, prevScrollPosition.top)
      } else {
        this.codeMirror.setValue(nextProps.value)
      }
    }
    if (typeof nextProps.options === 'object') {
      for (const optionName in nextProps.options) {
        if (Object.prototype.hasOwnProperty.call(nextProps.options, optionName)) {
          this.setOptionIfChanged(optionName, nextProps.options[optionName])
        }
      }
    }
  }

  showHints = (currentKeyword) => {
    const snippets = this.props.hintItems
      .find(i => (i.namespace === currentKeyword)).items
      .map(i => ({
        ...i,
        render: (element) => {
          const hintText = document.createElement('div')
          hintText.className = 'hintText'
          hintText.textContent = i.displayText
          element.appendChild(hintText)

          const descriptionIcon = document.createElement('div')
          descriptionIcon.className = 'descriptionIcon'
          descriptionIcon.textContent = 'i'
          element.appendChild(descriptionIcon)

          descriptionIcon.addEventListener('click', (evt) => {
            evt.stopPropagation()
            this.setState({
              showingSnippetDescription: true
            })
          })
        }
      }))

    this.getCodeMirrorInstance().showHint(this.codeMirror, (cm, options) => {
      const cursor = this.codeMirror.getCursor()
      const token = this.codeMirror.getTokenAt(cursor)
      const start = token.start
      const end = cursor.ch
      const line = cursor.line
      const currentWord = token.string
      const tokens = this.codeMirror.getLineTokens(line)

      const list = snippets.filter(function (item) {
        return item.displayText.indexOf(currentWord) >= 0
      })

      // Define the `from` position based on the position of `.` token
      let from
      if (tokens[tokens.length - 2].string === '.') {
        from = this.getCodeMirrorInstance().Pos(line, tokens[tokens.length - 2].start)
      } else {
        from = this.getCodeMirrorInstance().Pos(line, start)
      }

      const result = {
        list: list.length ? list : snippets,
        from,
        to: this.getCodeMirrorInstance().Pos(line, end)
      }

      this.getCodeMirrorInstance().on(result, 'close', this.onCloseHints)
      this.getCodeMirrorInstance().on(result, 'select', this.onSelectHint)

      return result
    }, {
      completeSingle: false
    })
  }

  onCloseHints = () => {
    this.setState({ showingSnippetDescription: false })
  }

  onSelectHint = (currentHint) => {
    this.setState({ currentHint })
  }

  checkHint = (cm, event) => {
    const currentLineValue = cm.getLine(cm.getCursor().line)
    const namespaces = this.props.hintItems.map(i => (i.namespace))
    const currentKeyword = namespaces.find(k => (currentLineValue.includes(k)))
    if (!cm.state.completionActive && event.keyCode !== 13 && currentKeyword && !(/\(|\)/.test(currentLineValue))) {
      this.showHints(currentKeyword)
    }
  }

  setOptionIfChanged = (optionName, newValue) => {
    const oldValue = this.codeMirror.getOption(optionName)
    if (!isEqual(oldValue, newValue)) {
      this.codeMirror.setOption(optionName, newValue)
    }
  }

  getCodeMirror = () => {
    return this.codeMirror
  }

  focus = () => {
    if (this.codeMirror) {
      this.codeMirror.focus()
    }
  }

  focusChanged = (focused) => {
    this.setState({
      isFocused: focused
    })
    this.props.onFocusChange && this.props.onFocusChange(focused)
  }

  cursorActivity = (cm) => {
    this.props.onCursorActivity && this.props.onCursorActivity(cm)
  }

  scrollChanged = (cm) => {
    this.props.onScroll && this.props.onScroll(cm.getScrollInfo())
  }

  codemirrorValueChanged = (doc, change) => {
    if (this.props.onChange && change.origin !== 'setValue') {
      this.props.onChange(doc.getValue(), change)
    }
  }

  _renderDescription = () => {
    if (this.state.showingSnippetDescription) {
      const hintsElement = document.getElementsByClassName('CodeMirror-hints')
      return (
        <div
          className={cx('snippetDescription')}
          style={{
            top: `${hintsElement[0].offsetTop}px`,
            left: `${hintsElement[0].offsetLeft + hintsElement[0].offsetWidth}px`
          }}
        >
          <div className={cx('header')}>
            <h2 className={cx('title')}>{this.state.currentHint.displayText}</h2>
          </div>
          <div className={cx('body')}>
            {this.state.currentHint.description}
          </div>
        </div>
      )
    }
  }

  render () {
    return (
      <div className={classnames(
        'ReactCodeMirror',
        this.props.className,
        { 'ReactCodeMirror--focused': this.state.isFocused }
      )}
      >
        {this._renderDescription()}
        <textarea
          ref={ref => { this.textareaNode = ref }}
          name={this.props.name || this.props.path}
          defaultValue={this.props.value}
          autoComplete='off'
          autoFocus={this.props.autoFocus}
        />
      </div>
    )
  }
}
