// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ErrorButton_base__2j1ZQ{width:2.325rem;height:2.325rem;border-radius:50%;background:none;border:2px solid var(--color-primary);color:var(--color-primary);outline:none;font-size:var(--font-size-xlarge);font-weight:bold;cursor:pointer;position:relative}.ErrorButton_badge__3z0fG{font-size:var(--font-size-xxsmall);font-weight:bold;line-height:1.25em;color:var(--color-text-on-primary);background-color:var(--color-error);border-radius:50%;position:absolute;width:1.25em;height:1.25em;right:-0.325em;top:-0.325em}", ""]);
// Exports
exports.locals = {
	"base": "ErrorButton_base__2j1ZQ",
	"badge": "ErrorButton_badge__3z0fG"
};
module.exports = exports;
