import PropTypes from 'prop-types'

const organizationShape = PropTypes.shape({
  name: PropTypes.string,
  slug: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }))
})

export default organizationShape
