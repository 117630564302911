// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Login_form__1doRG>input{margin:.5rem 0}.Login_statusMessage__3tVX2{color:var(--color-error);font-weight:600;margin:2rem 0}.Login_buttonContainer__3t0KV{position:relative;text-align:center;width:100%}", ""]);
// Exports
exports.locals = {
	"form": "Login_form__1doRG",
	"statusMessage": "Login_statusMessage__3tVX2",
	"buttonContainer": "Login_buttonContainer__3t0KV"
};
module.exports = exports;
