import React, { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Tooltip
} from '@miroculus/nucleo'
import classNames from 'classnames/bind'
import styles from './OverflownTooltipText.scss'

const cx = classNames.bind(styles)

const OverflownTooltipText = memo(({ tooltipLabel, children, overflowAfterNChars = 30 }) => {
  const tooltipContentRef = useRef()

  const [isOverflown, setIsOverflown] = useState(true)

  useEffect(() => {
    if (tooltipContentRef.current) {
      const element = tooltipContentRef.current
      setIsOverflown(element?.scrollWidth > element?.clientWidth)
    }
  }, [])

  if (!isOverflown) {
    return (
      <div>
        {children}
      </div>
    )
  }

  return (
    <div
      className={cx('overflown-text-container')} ref={node => {
        if (node) {
          tooltipContentRef.current = node
        }
      }}
    >
      <Tooltip
        direction='bottom'
        label={tooltipLabel}
      >
        {children}
      </Tooltip>
    </div>
  )
})

OverflownTooltipText.propTypes = {
  tooltipLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  overflowAfterNChars: PropTypes.number
}

export default OverflownTooltipText
