export const DEVICE_STATUS_AVAILABLE = 'Available'
export const DEVICE_STATUS_BUSY = 'Busy'
export const DEVICE_STATUS_WAITING = 'Waiting'

export const TYPE_DEVICE = 'device'
export const TYPE_LINE = 'line'
export const TYPE_SCRIPT = 'script'
export const TYPE_EXPERIMENT = 'experiment'
export const TYPE_COCO_EVENT = 'coco-event'
export const TYPE_SURE_MOVE = 'sure_move'
export const TYPE_TEMP = 'temp'
export const TYPE_LOG = 'log'

export const EXPERIMENT_STATUS_FINISH = 'finish'
export const ERROR_PAYLOAD_TOO_BIG = 'PAYLOAD_TOO_BIG'

export const PROTOCOL_SOLVED_STATUS = 'solved'
export const PROTOCOL_SOLVING_STATUS = 'solving'
export const PROTOCOL_PENDING_STATUS = 'pending'

export const STATUS_DONE = 'done'
export const STATUS_ERROR = 'error'
export const STATUS_START = 'start'
export const STATUS_STOP = 'stop'

export const PROTOCOL_VISIBILITY_OPTIONS = [
  { value: 'private', label: 'Private' },
  { value: 'shared', label: 'Shared' }
]

export const ORG_SETTINGS_INSTRUMENTS_COLS = ['Instrument name', 'Status']
export const ORG_SETTINGS_USERS_COLS = ['Name', 'Role']
export const ORG_SETTINGS_TEAMS_COLS = ['Name', 'Members']
