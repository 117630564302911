export function calculateTable (variables) {
  const table = []
  const variableNames = Object.keys(variables)
  table.push(['time', ...variableNames])
  const lengths = variableNames.map((key) => variables[key].length)
  const max = Math.max(0, ...lengths)
  for (let i = 0; i < max; i++) {
    const values = variableNames.map((key) => variables[key][i])
    table.push([i, ...values])
  }
  if (max === 0) {
    table[0].push('sample')
    table.push([0, 1])
  }
  return table
}

function decToHex (number) {
  let n = number.toString(16)
  if (n.length < 2) {
    n = '0' + n
  }
  return n
}

function hue2rgb (p, q, t) {
  if (t < 0) t += 1
  if (t > 1) t -= 1
  if (t < 1 / 6) return p + (q - p) * 6 * t
  if (t < 1 / 2) return q
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
  return p
}

function hslToRgb (h, s, l) {
  let r, g, b

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return `#${decToHex(Math.round(r * 255))}${decToHex(Math.round(g * 255))}${decToHex(Math.round(b * 255))}`
}

function generateColor (colors, index) {
  const iteration = Math.floor(index / colors.length)
  index = index % colors.length
  const saturation = 96 - 9.6 * iteration
  const light = 67 - 6.7 * iteration
  const color = hslToRgb(colors[index] / 360, saturation / 100, light / 100)
  return color
}

function getColors (state, type, total) {
  const colors = { sample: '#f0f0f0' }
  const variables = state[type].sources.map((source) => source.name)
  const colorOptions = state[type].sources.map((source) => source.color)
  variables.forEach((name, index) => {
    for (let i = -1; i < total; i++) {
      const codename = `${i}:${name}`
      colors[codename] = generateColor(colorOptions, (i + 1) * variables.length + index)
    }
  })
  return colors
}

export function getDataSource (state, type, runs, variables) {
  return {
    runs: runs.map((run) => {
      return {
        index: run.index,
        name: run.notes,
        active: true
      }
    }),
    names: state[type].sources.map((source) => source.name),
    colorOptions: state[type].colorOptions || [],
    variables: variables[`${type}Variables`],
    sources: state[type].sources,
    colors: getColors(state, type, runs.length),
    dataTable: calculateTable(variables[`${type}Variables`]),
    tracking: state[type].tracking || false,
    trackInterval: 1000,
    maxDatapoints: 100
  }
}

export function getFilteredVariables (dataSource) {
  const variableNames = Object.keys(dataSource.variables)
  const variables = {}
  variableNames.filter((varName) => {
    let [runIndex, variableName] = varName.split(':')
    runIndex = parseInt(runIndex)
    const run = dataSource.runs.filter(({ index }) => index === runIndex)
    if (!run[0].active) return false
    const variable = dataSource.sources.filter((source) => source.name === variableName)
    return variable[0].active
  }).forEach((varName) => {
    variables[varName] = dataSource.variables[varName]
  })
  return variables
}

export function addCurrentRun (state, variables, runs) {
  state.temp.sources.forEach((source) => {
    variables.tempVariables['-1:' + source.name] = [0]
  })
  state.optics.sources.forEach((source) => {
    variables.opticsVariables['-1:' + source.name] = [0]
  })
  runs.splice(0, 0, { index: -1, notes: 'current run' })
  return { state, runs }
}

export function addRunData (dataType, newState, action) {
  const firstTime = dataType.dataTable[1][0]
  const lastTime = dataType.dataTable[dataType.dataTable.length - 1][0]

  const varName = `-1:${action.variable}`
  const varArray = dataType.variables[varName]
  const diffTime = lastTime - firstTime
  const emptyValues = (diffTime < varArray.length) ? 0 : (diffTime - varArray.length)

  dataType.variables[varName] = varArray.concat(new Array(emptyValues))
  dataType.variables[varName].push(action.value)

  const varLength = dataType.variables[varName].length
  const headers = newState[action.source].dataTable[0]
  const indexInTable = headers.indexOf(varName)

  for (let i = 0; i < dataType.sources.length; i++) {
    if (dataType.sources[i].name === action.variable) {
      newState[action.source].sources[i].lastValue = action.value
      break
    }
  }

  let enter = false
  if (dataType.dataTable.length <= varLength) {
    enter = true
    const arrayLength = newState[action.source].dataTable[newState[action.source].dataTable.length - 1].length
    newState[action.source].dataTable.push(new Array(arrayLength))
    newState[action.source].dataTable[newState[action.source].dataTable.length - 1][0] = lastTime + 1
  }

  newState[action.source].dataTable[newState[action.source].dataTable.length - 1][indexInTable] = action.value

  if ((dataType.dataTable.length >= dataType.maxDatapoints) && (enter)) {
    for (const item in newState[action.source].variables) {
      newState[action.source].variables[item].shift()
    }

    newState[action.source].dataTable.splice(1, 1)
  }

  return newState
}

export function addRunDatas (dataType, newState, action) {
  const headers = newState[action.source].dataTable[0]
  const tableLength = headers.length
  const data = (new Array(tableLength)).fill(0)
  const lastTime = dataType.dataTable[dataType.dataTable.length - 1][0]
  data[0] = lastTime + 1

  Object.keys(action.variables).forEach((variable) => {
    const varName = `-1:${variable}`
    const indexInTable = headers.indexOf(varName)
    const value = action.variables[variable]
    data[indexInTable] = value

    for (let i = 0; i < dataType.sources.length; i++) {
      if (dataType.sources[i].name === variable) {
        newState[action.source].sources[i].lastValue = value
        break
      }
    }
  })

  headers.forEach((value, index) => {
    if (index === 0) return
    if (dataType.variables[value]) dataType.variables[value].push(data[index])
  })
  newState[action.source].dataTable.push(data)

  if (dataType.dataTable.length >= dataType.maxDatapoints) {
    for (const item in newState[action.source].variables) {
      newState[action.source].variables[item].shift()
    }

    newState[action.source].dataTable.splice(1, 1)
  }

  return newState
}
