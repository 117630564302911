export const getLoadingWorkspaces = ({ workspaces }) =>
  workspaces.loadingWorkspaces

export const getWorkspaces = ({ workspaces }) =>
  workspaces.workspaces

export const getCurrentWorkspace = ({ workspaces }) =>
  workspaces.currentWorkspace || null

export const getCurrentWorkspaceName = ({ workspaces: { workspaces, currentWorkspace } }) =>
  workspaces.find(w => w.id === currentWorkspace)?.title

export const getCurrentWorkspacePermissions = ({
  workspaces: { workspaces, currentWorkspace }
}) => {
  const workspace = workspaces.find(w => w.id === currentWorkspace)
  return workspace
    ? workspace.$permissions
    : {}
}

export const canAccessAnaconda = (state) =>
  getCurrentWorkspacePermissions(state).canAccessAnaconda

export const membersSelector = (state) => state.workspaces.members

export const getLoadingMembers = (state) => state.workspaces.loadingMembers

export const findTeamById = (teams, teamId) => teams.find(({ id }) => id === Number(teamId))
