import React from 'react'
import { connect } from 'react-redux'
import { ProtocolListWithHeader, protocolListPropTypes, protocolListDefaultProps, useBreadcrumb } from 'components'
import {
  deleteProtocol,
  loadDrafts,
  loadProtocolTypes
} from 'reduxModules/protocols'
import {
  getProtocolsToDisplay,
  getUsedProtocolTypes
} from 'reduxModules/protocols/selectors'

const breadcrumbLevels = [
  { text: 'Dashboard' },
  { text: 'Drafts' }
]

export const Drafts = ({
  protocolsToDisplay,
  onDeleteClick,
  types,
  sendingProtocol,
  loading,
  onMount
}) => {
  useBreadcrumb(breadcrumbLevels)

  return (
    <ProtocolListWithHeader
      loading={loading}
      types={types}
      onDeleteClick={onDeleteClick}
      sendingProtocol={sendingProtocol}
      protocolsToDisplay={protocolsToDisplay}
      headingTitle='My Drafts'
      onMount={onMount}
    />
  )
}

Drafts.propTypes = protocolListPropTypes
Drafts.defaultProps = protocolListDefaultProps

const mapStateToProps = (state) => ({
  protocolsToDisplay: getProtocolsToDisplay(state),
  types: getUsedProtocolTypes(state),
  loading: state.protocols.loading,
  sendingProtocol: state.protocols.sendingProtocol
})

const mapDispatchToProps = (dispatch) => ({
  onDeleteClick: (id) => {
    dispatch(deleteProtocol(id))
  },
  onMount: () => {
    dispatch(loadDrafts())
    dispatch(loadProtocolTypes())
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(Drafts)
