// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Layout_container__Kn-G9{width:100%;height:100vh;display:flex;flex-direction:row;overflow:hidden}.Layout_body__1ch7_{display:flex;flex-direction:column;width:100%;height:100%;flex-grow:1;overflow:hidden}.Layout_workspaceMenu__1JsKx{height:100%;overflow:hidden;width:0;transition:width .3s ease-in-out}.Layout_workspaceMenu__1JsKx.Layout_visible__38qPE{width:3.5rem}.Layout_main__3dSAd{flex-grow:1;overflow:hidden}.Layout_modalContainer__1k0ZH{position:fixed;top:0;left:0;width:100vw;height:100vh;z-index:9999}", ""]);
// Exports
exports.locals = {
	"container": "Layout_container__Kn-G9",
	"body": "Layout_body__1ch7_",
	"workspaceMenu": "Layout_workspaceMenu__1JsKx",
	"visible": "Layout_visible__38qPE",
	"main": "Layout_main__3dSAd",
	"modalContainer": "Layout_modalContainer__1k0ZH"
};
module.exports = exports;
