import React, { useCallback } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import classnames from 'classnames/bind'
import { Input, Select } from '@miroculus/nucleo'
import PropTypes from 'prop-types'
import styles from './ProtocolFilters.scss'

const cx = classnames.bind(styles)

const SORT_OPTIONS = [
  {
    value: 'latest'
  },
  {
    value: 'oldest'
  }
]

const ProtocolFilters = ({
  searchText,
  dateRange,
  selectedType,
  types = [],
  onSearchTextChange,
  onDateRangeChange,
  onTypeChange,
  selectedSortMethod,
  onSortMethodChange
}) => {
  const handleTypeChange = useCallback(
    (newTypeValue) => {
      onTypeChange(types.find(({ value }) => value === newTypeValue) || null)
    },
    [types, onTypeChange]
  )

  const handleSortMethodChange = useCallback(
    (newSortMethodValue) => {
      onSortMethodChange(newSortMethodValue || SORT_OPTIONS[0].value)
    },
    [SORT_OPTIONS, onSortMethodChange]
  )

  const typeOptions = [
    { value: 'All types' },
    ...types
  ]

  return (
    <div className={cx('container')}>
      <Input
        name='search'
        value={searchText}
        onChange={onSearchTextChange}
        placeholder='Search...'
        data-testid='input-search-bar'
      />
      {onDateRangeChange &&
        <DateRangePicker
          calendarClassName={cx('calendar')}
          calendarIcon={null}
          className={cx('daterange')}
          yearAriaLabel='Year input'
          monthAriaLabel='Month input'
          dayAriaLabel='Day input'
          value={dateRange}
          onChange={onDateRangeChange}
        />}
      {types.length > 1 && (
        <div className={cx('types')}>
          <Select
            name='type'
            options={typeOptions}
            value={selectedType}
            onChange={handleTypeChange}
          />
        </div>
      )}
      {onSortMethodChange && (
        <div className={cx('sortBy')} data-testid='sortBy'>
          <Select
            name='sortBy'
            options={SORT_OPTIONS}
            value={selectedSortMethod}
            onChange={handleSortMethodChange}
          />
        </div>
      )}
    </div>
  )
}

ProtocolFilters.propTypes = {
  searchText: PropTypes.string.isRequired,
  dateRange: PropTypes.array,
  onSearchTextChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func,
  selectedType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  types: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  onTypeChange: PropTypes.func,
  selectedSortMethod: PropTypes.string,
  onSortMethodChange: PropTypes.func
}

export default ProtocolFilters
