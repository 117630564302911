// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IDE_container__z7DR9{display:flex;flex-direction:column;height:100%;width:100%}.IDE_base16-light__3_Pxs{background-color:#f5f5f5}.IDE_base16-dark__279lT{background-color:#151515}.IDE_buttonsContainer__1_gby{padding:.5rem;display:flex;justify-content:space-evenly;align-items:center;flex-grow:0;flex-shrink:0;flex-wrap:wrap}.IDE_loadContainer__FRV92{padding:1rem 1rem 0}.IDE_loadContainer__FRV92 .IDE_mainContainer__2GLau{border-top:1px solid #d3d3d3;padding:.5rem;display:flex;justify-content:space-evenly;align-items:center;flex-grow:0;flex-shrink:0;flex-wrap:wrap}.IDE_subContainer__8sHvY,.IDE_editorContainer__3Bq6T,.IDE_logContainer__2v6EU{flex-grow:1;flex-basis:15rem;min-height:15rem}.IDE_logContainer__2v6EU{display:flex;flex-direction:column}.IDE_buttonContainer__2kKqB,.IDE_stopButtonContainer__xPf40,.IDE_runButtonContainer___Agau{width:10rem;margin:.5rem}", ""]);
// Exports
exports.locals = {
	"container": "IDE_container__z7DR9",
	"base16-light": "IDE_base16-light__3_Pxs",
	"base16-dark": "IDE_base16-dark__279lT",
	"buttonsContainer": "IDE_buttonsContainer__1_gby",
	"loadContainer": "IDE_loadContainer__FRV92",
	"mainContainer": "IDE_mainContainer__2GLau",
	"subContainer": "IDE_subContainer__8sHvY",
	"editorContainer": "IDE_editorContainer__3Bq6T",
	"logContainer": "IDE_logContainer__2v6EU",
	"buttonContainer": "IDE_buttonContainer__2kKqB",
	"stopButtonContainer": "IDE_stopButtonContainer__xPf40",
	"runButtonContainer": "IDE_runButtonContainer___Agau"
};
module.exports = exports;
