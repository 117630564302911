import PropTypes from 'prop-types'

const protocolShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  team: PropTypes.number.isRequired,
  name: PropTypes.string,
  solutionStatus: PropTypes.string,
  author: PropTypes.shape({ name: PropTypes.string }),
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  canCopy: PropTypes.bool,
  canSendToDevice: PropTypes.bool,
  canShowSolution: PropTypes.bool
})

export default protocolShape
