import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  ActionsButtonIcon,
  Dropdown,
  DropdownItem,
  DropdownLink,
  MiroculusIcon,
  Tabs,
  TeamIcon,
  UsersIcon
} from '@miroculus/nucleo'
import { useParams } from 'react-router-dom'
import {
  getCurrentOrganization,
  getLoadingOrganizations
} from 'reduxModules/organizations/selectors'
import { loadOrganizationMembers } from 'reduxModules/organizations'
import { updateLoading } from 'reduxModules/devices'
import {
  ORG_SETTINGS_INSTRUMENTS_COLS,
  ORG_SETTINGS_TEAMS_COLS,
  ORG_SETTINGS_USERS_COLS
} from 'cons'
import {
  newOrganizationMemberUrl,
  editOrganizationMemberUrl,
  newOrganizationTeamUrl,
  editOrganizationTeamUrl
} from 'cons/routes'
import {
  DynamicTable,
  HeadingWithButton,
  LoadingWithText,
  ProtocolFilters,
  useBreadcrumb
} from 'components'
import socketClient from 'socketClient'
import classNames from 'classnames/bind'
import { userPlaceholder } from 'images'
import { useDebouncedValue } from 'hooks'
import styles from './OrganizationSettings.scss'

const cx = classNames.bind(styles)

const lowerIncludes = (value, searchTerm) =>
  value.toLowerCase().includes(searchTerm.toLowerCase())

const filterTeams = (searchTerm, teams) =>
  teams.filter(({ title }) => lowerIncludes(title, searchTerm))

const filterMembers = (searchTerm, members) =>
  members.filter(({ name }) => lowerIncludes(name, searchTerm))

const filterInstruments = (searchTerm, instruments) =>
  instruments.filter(
    ({ id, givenName }) => lowerIncludes(givenName ?? id, searchTerm)
  )

const InstrumentRows = ({ data: instrumentsData }) => {
  let rowKey = 'organization-settings-instrument-row'
  if (!instrumentsData?.length) {
    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td colSpan={3} className={cx('no-results')}>No instruments found</td>
      </tr>
    )
  }

  return instrumentsData.map(({ id, givenName, status }, ix) => {
    rowKey = `organization-settings-instrument-row-${ix}`
    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td className={cx('instrument-name')}>{givenName ?? id}</td>
        <td className={cx('instrument-status')}>{status}</td>
      </tr>
    )
  })
}

const UsersRows = ({ data: userData, orgSlug }) => {
  let rowKey = 'organization-settings-user-row'
  if (!userData?.length) {
    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td colSpan={3} className={cx('no-results')}>No users found</td>
      </tr>
    )
  }

  return userData.map(({ id, name, role, email }, ix) => {
    rowKey = `organization-settings-user-row-${ix}`

    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td className={cx('user-name')}>
          <div className={cx('user-avatar-image')}>
            <img src={userPlaceholder} alt='Avatar' />
          </div>
          <div>
            <div>
              {name}
            </div>
            <div className={cx('user-email')}>
              {email}
            </div>
          </div>
        </td>
        <td className={cx('user-role')}>{role}</td>
        <td className={cx('actions')}>
          <Dropdown align='full' button={<ActionsButtonIcon />}>
            <DropdownLink to={editOrganizationMemberUrl(orgSlug, id)}>
              Edit info
            </DropdownLink>
            <DropdownItem onSelect={() => { /** unimplemented */ }}>
              Remove
            </DropdownItem>
          </Dropdown>
        </td>
      </tr>
    )
  })
}

const TeamsRows = ({ data: teamsData, orgSlug }) => {
  let rowKey = 'organization-settings-team-row'
  if (!teamsData?.length) {
    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td colSpan={3} className={cx('no-results')}>No teams found</td>
      </tr>
    )
  }

  return teamsData.map(({ id, title: name, $memberCount: memberCount }, ix) => {
    rowKey = `organization-settings-team-row-${ix}`

    return (
      <tr className='organization-team-row' key={rowKey} data-testid={rowKey}>
        <td data-testid={`team-row-${id}`} className={cx('team-name')}>
          {name}
        </td>
        <td className={cx('team-members')}>
          {memberCount > 1 ? `${memberCount} Members` : `${memberCount} Member`}
        </td>
        <td className={cx('actions')}>
          <Dropdown align='full' button={<ActionsButtonIcon />}>
            <DropdownLink
              data-testid={`edit-team-${id}-button`}
              to={editOrganizationTeamUrl(orgSlug, id)}
            >
              Edit team info
            </DropdownLink>
          </Dropdown>
        </td>
      </tr>
    )
  })
}

const QuickInfo = (Icon, text) => ({ count = 0 }) => (
  <div className={cx('quick-info')}>
    <Icon />
    <span>
      {count} {text}{count > 1 ? 's' : ''}
    </span>
  </div>
)

const InstrumentsQuickInfo = QuickInfo(MiroculusIcon, 'Instrument')
const UsersQuickInfo = QuickInfo(UsersIcon, 'Member')
const TeamsQuickInfo = QuickInfo(TeamIcon, 'Team')

const TABS_TITLES = ['Instruments', 'Users', 'Teams']

const getInstrumentsTabData = (items) => ({
  title: TABS_TITLES[0],
  quickInfo: <InstrumentsQuickInfo count={items?.length} />,
  currentTabList: <InstrumentRows data={items} />,
  columnNames: ORG_SETTINGS_INSTRUMENTS_COLS,
  withActions: false
})

const getUsersTabData = (items, orgSlug) => ({
  title: TABS_TITLES[1],
  button: {
    text: '+ New User',
    link: newOrganizationMemberUrl(orgSlug)
  },
  quickInfo: <UsersQuickInfo count={items?.length} />,
  currentTabList: <UsersRows data={items} orgSlug={orgSlug} />,
  columnNames: ORG_SETTINGS_USERS_COLS,
  withActions: true
})

const getTeamsTabData = (items, orgSlug) => ({
  title: TABS_TITLES[2],
  button: {
    text: '+ New Team',
    link: newOrganizationTeamUrl(orgSlug)
  },
  quickInfo: <TeamsQuickInfo count={items?.length} />,
  currentTabList: <TeamsRows data={items} orgSlug={orgSlug} />,
  columnNames: ORG_SETTINGS_TEAMS_COLS,
  withActions: true
})

const ItemsTab = (getTabData, filterItems) => ({
  orgSlug,
  loading,
  items
}) => {
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebouncedValue(searchText, 500)

  const handleSearchTextChange = (evt) => {
    setSearchText(evt.currentTarget.value)
  }

  const filteredItems = useMemo(
    () => filterItems(debouncedSearchText, items),
    [debouncedSearchText, items]
  )
  const data = getTabData(filteredItems, orgSlug)

  return (
    <>
      <HeadingWithButton
        title={`Manage ${data.title}`}
        buttonTextElement={data.button?.text}
        buttonLinkTo={data.button?.link}
        titleAnnex={data.quickInfo}
        titleAnnexAlignment={!data.button ? 'left' : 'right'}
        showSmallTitle
      />
      <ProtocolFilters
        searchText={searchText}
        onSearchTextChange={handleSearchTextChange}
      />
      {!loading && (
        <DynamicTable
          columnNames={data.columnNames}
          body={data.currentTabList}
          withActions={data.withActions}
        />
      )}
    </>
  )
}

const FilteredInstrumentsTab = ItemsTab(getInstrumentsTabData, filterInstruments)
const FilteredUsersTab = ItemsTab(getUsersTabData, filterMembers)
const FilteredTeamsTab = ItemsTab(getTeamsTabData, filterTeams)

// Dummy component to prevent rendering a tab when is not visible
const DummyTab = () => false

export const OrganizationSettings = ({
  loading,
  loadOrganization,
  organizationName,
  instruments = [],
  users = [],
  teams = [],
  initialTab = 0,
  subscribeToDevices,
  unsubscribeFromDevices
}) => {
  // Current Tab index (0: 'Instruments, 1: 'Users', 2: 'Members')
  const [currentTab, setCurrentTab] = useState(initialTab)
  const { orgSlug } = useParams()

  useEffect(() => {
    loadOrganization?.(orgSlug)
    subscribeToDevices(orgSlug)
    return () => {
      unsubscribeFromDevices(orgSlug)
    }
  }, [orgSlug])

  useBreadcrumb(() => [
    { text: organizationName, href: `/organization/${orgSlug}` },
    { text: 'Organization Settings' },
    { text: TABS_TITLES[currentTab] }
  ], [organizationName, currentTab])

  const tabs = [
    [FilteredInstrumentsTab, instruments],
    [FilteredUsersTab, users],
    [FilteredTeamsTab, teams]
  ]

  const renderTab = ([Tab, items], index) => currentTab === index
    ? <Tab key={index} loading={loading} orgSlug={orgSlug} items={items} />
    : <DummyTab key={index} />

  return (
    <div className={cx('container')}>
      {loading && <LoadingWithText message='Loading organization settings...' />}
      <h1 className={cx('header-title')}>Organization Settings</h1>
      <Tabs
        titles={TABS_TITLES}
        defaultValue={currentTab}
        onChange={setCurrentTab}
      >
        {tabs.map(renderTab)}
      </Tabs>
    </div>
  )
}

OrganizationSettings.propTypes = {
  loading: PropTypes.bool.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    $memberCount: PropTypes.number.isRequired
  })),
  instruments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    givenName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  })),
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  })),
  organizationName: PropTypes.string,
  initialTab: PropTypes.number
}

const mapStateToProps = (state) => {
  const currentOrganization = getCurrentOrganization(state)
  const isLoading = getLoadingOrganizations(state)

  return {
    instruments: currentOrganization.devices,
    users: currentOrganization.members,
    teams: currentOrganization.teams,
    loading: isLoading,
    organizationName: currentOrganization?.name
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadOrganization: async (organizationSlug) => {
    await dispatch(loadOrganizationMembers(organizationSlug))
  },
  subscribeToDevices: async (orgSlug) => {
    try {
      await dispatch(updateLoading(true))
      await socketClient.subscribeToDevices(orgSlug)
    } finally {
      await dispatch(updateLoading(false))
    }
  },
  unsubscribeFromDevices: async (orgSlug) => {
    await socketClient.unsubscribeToDevices(orgSlug)
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(OrganizationSettings)
