export const TemplatesWalkthrough = [
  {
    target: '[data-reach-tab-list] > button:nth-child(3)',
    title: 'Template',
    content: 'A template is a standard Miroculus protocol which you can customize using Palette technology.',
    disableBeacon: true
  },
  {
    target: 'ul > li:first-child > article > nav > a',
    title: 'How to Access',
    content: 'Select the desired template and click the "Duplicate" button to save the protocol to any team and make it private or shared. From the private or shared folder, you can customize or send to your Miro Canvas.',
    disableBeacon: true
  }
]
