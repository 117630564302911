import { sort, ascend, descend, prop } from 'ramda'

/**
 * Get elements filtered between from date and to date
 * @param {Array<Date, Date>} dateRange two-dimension array containing fromDate and toDate values<p/>
 * @param {Array<Object>} elements the array of items to iterate over<p/>
 * @param {string | number} propToCompareWith each elements item property to compare with<p/>
 */
export const getElementsFilteredByDate = (dateRange, elements, propToCompareWith) => {
  if (!(dateRange?.length === 2 && dateRange[0] && dateRange[1] && elements?.length && propToCompareWith)) {
    return null
  }

  const [fromDate, toDate] = dateRange
  return elements.filter(element => fromDate.getTime() < element[propToCompareWith] && toDate.getTime() > element[propToCompareWith])
}

/**
 * Get elements sorted by certain property, it returns a copy of the original list
 * @param {Array<Object>} elements the array of items to iterate over<p/>
 * @param {string | number} propToCompareWith each elements item property to compare with<p/>
 * @param {boolean} desc indicates the sort order, descending or ascending<p/>
 */
export const getElementsSortedBy = (elements, propToCompareWith, desc = true) => {
  if (!elements?.length || !propToCompareWith) {
    return elements
  }

  const sortDirection = desc ? descend : ascend

  const sorter = sortDirection(prop(propToCompareWith))
  return sort(sorter, elements)
}
