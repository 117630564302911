import {
  useEffect,
  useRef,
  useState
} from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { organizationUrl } from 'cons/routes'
import { getElementsSortedBy } from 'utils/dates'
import { allPass } from 'ramda'

export const useQuery = () => new URLSearchParams(useLocation().search)

/**
 * Get the size of an element
 * @param {any[]} deps dependencies for the useEffect hook
 */
export const useClientRect = (deps = []) => {
  const [clientRect, setClientRect] = useState({})
  const ref = useRef()
  useEffect(() => {
    if (!ref.current) return
    setClientRect(ref.current.getBoundingClientRect())
  }, deps)

  return [ref, clientRect]
}

export const useRouteMatchOrganization = () => useRouteMatch({
  path: [
    organizationUrl(':orgSlug')
  ],
  exact: false
})?.params?.orgSlug

// Protocol Filters Hook
const filterByCreationDate = ([fromDate, toDate]) => ({ createdAt }) =>
  fromDate.getTime() < createdAt && toDate.getTime() > createdAt

const filterByName = (searchText) => ({ name }) => name.toLowerCase().includes(searchText.toLowerCase())

const filterByTypeId = (selectedProtocolType) => (something) => something.type?.id === selectedProtocolType || selectedProtocolType === 'All types'

const defaultToTrue = (fn, value) => !value
  ? () => true
  : fn(value)

export const useProtocolFilters = (protocols = [], sortMethod = 'latest') => {
  const [dateRange, setDateRange] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [selectedType, setSelectedType] = useState('All types')
  const [selectedSortMethod, setSelectedSortMethod] = useState(sortMethod)

  const getFilteredProtocols = allPass([
    defaultToTrue(filterByCreationDate, dateRange),
    defaultToTrue(filterByName, searchText),
    defaultToTrue(filterByTypeId, selectedType)
  ])

  const filteredProtocols = getElementsSortedBy(protocols.filter(getFilteredProtocols), 'createdAt', selectedSortMethod === 'latest')

  return {
    filteredProtocols,
    setDateRange,
    setSearchText,
    setSelectedType,
    setSelectedSortMethod,
    dateRange,
    searchText,
    selectedType,
    selectedSortMethod
  }
}

export function useDebouncedValue (value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, delay])

  return debouncedValue
}
