// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ForgotPassword_form__39h4W>input{margin:.5rem 0}.ForgotPassword_statusMessage__3xbif{color:var(--color-error);font-weight:600;margin:2rem 0}.ForgotPassword_buttonContainer__3oK0l{position:relative;text-align:center;width:100%}", ""]);
// Exports
exports.locals = {
	"form": "ForgotPassword_form__39h4W",
	"statusMessage": "ForgotPassword_statusMessage__3xbif",
	"buttonContainer": "ForgotPassword_buttonContainer__3oK0l"
};
module.exports = exports;
