import React, { useState, useEffect } from 'react'
import classnames from 'classnames/bind'
import styles from './ExperimentInfo.scss'
import { Button } from '@miroculus/nucleo'
import { secondsToTime } from 'utils'

const cx = classnames.bind(styles)

export default function ExperimentInfo ({
  startedAt = Date.now(),
  name,
  onFinishClick,
  connected
}) {
  const [runningTime, setRunningTime] = useState((Date.now() - startedAt) / 1000)
  const { h, m, s } = secondsToTime(runningTime)

  useEffect(() => {
    const interval = setInterval(() => {
      setRunningTime((Date.now() - startedAt) / 1000)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className={cx('footer')}>
      <div className={cx('name')}>
        {name}
      </div>
      <div className={cx('runningTime')}>
        Running time: {startedAt ? `${h}:${m}:${s}` : '00:00:00'}
      </div>
      <Button
        onClick={onFinishClick}
        disabled={!connected}
      >
        Finish
      </Button>
    </div>
  )
}
