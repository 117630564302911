import { combineReducers } from 'redux'
import auth from './auth'
import devices from './devices'
import protocols from './protocols'
import workspaces from './workspaces'
import organizations from './organizations'
import ui from './ui'
import experiment from './experiment'
import log from './log'
import codeEditor from './codeEditor'
import electrodeLayout from './electrodeLayout'
import settings from './settings'
import data from './data'
import runs from './runs'

const rootReducer = combineReducers({
  auth,
  devices,
  protocols,
  ui,
  workspaces,
  organizations,
  experiment,
  log,
  codeEditor,
  electrodeLayout,
  settings,
  data,
  runs
})

export default rootReducer
