import apiClient from '@miroculus/api-client'
import { startExperiment } from 'reduxModules/devices'
import browserHistory from 'browserHistory'
import {
  ANACONDA_URL,
  instrumentsUrl
} from 'cons/routes'
import {
  TYPE_EXPERIMENT,
  TYPE_DEVICE,
  DEVICE_STATUS_AVAILABLE,
  EXPERIMENT_STATUS_FINISH
} from 'cons'
import socketClient from 'socketClient'
import {
  updateScript,
  onScriptEnds
} from 'reduxModules/codeEditor'
import {
  updateMoveBackbone
} from 'reduxModules/electrodeLayout'

const UPDATE_PROJECTS = 'anaconda-web/experiment/UPDATE_PROJECTS'
const UPDATE_SELECTED_PROJECT_ID = 'anaconda-web/experiment/UPDATE_SELECTED_PROJECT_ID'
const UPDATE_EXPERIMENT = 'anaconda-web/experiment/UPDATE_EXPERIMENT'
const UPDATE_LOADING_PROJECTS = 'anaconda-web/protocolCreation/UPDATE_LOADING_PROJECTS'
const UPDATE_SHOWING_FINISH_MODAL = 'anaconda-web/protocolCreation/UPDATE_SHOWING_FINISH_MODAL'
const UPDATE_FEEDBACK = 'anaconda-web/protocolCreation/UPDATE_FEEDBACK'

export const finishExperiment = () => (dispatch, getState) => {
  const {
    experiment: { feedback },
    organizations: { currentOrganization }
  } = getState()

  socketClient.sendMessage({
    type: TYPE_EXPERIMENT,
    status: EXPERIMENT_STATUS_FINISH,
    feedback
  })

  socketClient.sendMessage({
    type: TYPE_DEVICE,
    status: DEVICE_STATUS_AVAILABLE
  })

  dispatch(updateScript(''))
  dispatch(onScriptEnds(Date.now()))
  dispatch(updateShowingFinishModal(false))
  dispatch(updateFeedback(''))
  dispatch(updateMoveBackbone([]))

  browserHistory.push(instrumentsUrl(currentOrganization.slug))
}

export const selectProject = (projectId) => (dispatch) => {
  dispatch(updateSelectedProjectId(projectId))
  // Demo project id
  dispatch(startExperiment())
  browserHistory.push(ANACONDA_URL)
}

export const loadProjects = () => async (dispatch, getState) => {
  const { currentOrganization } = getState().organizations

  dispatch(updateLoadingProjects(true))
  const { body } = await apiClient.get(
    '/projects',
    { organization: currentOrganization.slug }
  )
  const projects = body.map(project => ({
    name: project.title,
    id: project.id
  }))
  dispatch(updateProjects(projects))
  dispatch(updateLoadingProjects(false))
}

// actions
export const updateLoadingProjects = (loadingProjects) => ({
  payload: { loadingProjects },
  type: UPDATE_LOADING_PROJECTS
})

export const updateProjects = (projects) => ({
  type: UPDATE_PROJECTS,
  payload: { projects }
})

export const updateSelectedProjectId = (selectedProjectId) => ({
  type: UPDATE_SELECTED_PROJECT_ID,
  payload: { selectedProjectId }
})

export const updateExperiment = (experiment) => ({
  type: UPDATE_EXPERIMENT,
  payload: { experiment }
})

export const updateShowingFinishModal = (showingFinishModal) => ({
  payload: { showingFinishModal },
  type: UPDATE_SHOWING_FINISH_MODAL
})

export const updateFeedback = (feedback) => ({
  payload: { feedback },
  type: UPDATE_FEEDBACK
})

const initialState = {
  loadingProjects: false,
  projects: null,
  selectedProjectId: '',
  experiment: {},
  feedback: '',
  showingFinishModal: false
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_PROJECTS:
    case UPDATE_EXPERIMENT:
    case UPDATE_FEEDBACK:
    case UPDATE_SHOWING_FINISH_MODAL:
    case UPDATE_LOADING_PROJECTS:
    case UPDATE_SELECTED_PROJECT_ID:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
