import React from 'react'
import classNames from 'classnames/bind'
import { Label } from '@miroculus/nucleo'
import styles from './FormField.scss'

const cx = classNames.bind(styles)

const FormField = ({ title, children }) => (
  <div className={cx('field')}>
    <Label>
      <p>{title}</p>
      {children}
    </Label>
  </div>
)

export default FormField
