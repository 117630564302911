import React, { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/browser'
import { LoadingSpinner } from '@miroculus/miroculus-ui'
import {
  loadMiroLayoutId,
  loadProtocolToCopy,
  loadProtocolTypes,
  loadProtocolSolution,
  setProtocolAsCurrent,
  resetCurrentProtocol
} from 'reduxModules/protocols'

const getLoadingMessage = ({ copy, solution }) =>
  copy
    ? 'Preparing protocol to copy...'
    : solution
      ? 'Loading protocol solution...'
      : 'Loading protocol...'

const ProtocolLoader = memo(({
  copy = false,
  solution = false,
  children
}) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function loadProtocol () {
      setLoading(true)
      await dispatch(loadProtocolTypes())
      await dispatch(loadMiroLayoutId())

      const parsedId = parseInt(id, 10)
      if (!parsedId) {
        dispatch(resetCurrentProtocol())
        setLoading(false)
      } else {
        const action = copy
          ? loadProtocolToCopy
          : setProtocolAsCurrent

        try {
          await dispatch(action(parsedId))
        } catch (e) {
          Sentry.captureException(e)
          toast.error(copy
            ? 'There was a problem trying to copy the protocol'
            : 'There was a problem trying to load the protocol')
        }

        if (solution) {
          try {
            await dispatch(loadProtocolSolution(parsedId))
          } catch (e) {
            Sentry.captureException(e)
            toast.error(
              'There was a problem trying to load the protocol solution'
            )
          }
        }
        setLoading(false)
      }
    }
    loadProtocol()
  }, [copy, solution])

  return loading
    ? <LoadingSpinner message={getLoadingMessage({ copy, solution })} />
    : children
})

export default ProtocolLoader
