import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { SuccessIcon, IdleIcon, RunningIcon, IconButton } from '@miroculus/nucleo'
import { OverflownTooltipText } from 'components'
import { secondsToTime } from 'utils'
import styles from './InstrumentCard.scss'
import { CgDetailsMore } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import { instrumentDetailsUrl } from 'cons/routes'

const cx = classnames.bind(styles)

const InstrumentBodyContent = ({
  size,
  id,
  givenName,
  organizationName,
  organizationSlug,
  hideDetailsButton
}) => {
  return (
    <div className={cx('instrument-card-body', size)}>
      <div>
        <OverflownTooltipText
          overflowAfterNChars={10}
          tooltipLabel={givenName}
        >
          <h2>{givenName}</h2>
        </OverflownTooltipText>
        <OverflownTooltipText
          overflowAfterNChars={10}
          tooltipLabel={organizationName}
        >
          <h3>{organizationName}</h3>
        </OverflownTooltipText>
        {size !== 'small' && (
          <OverflownTooltipText
            overflowAfterNChars={10}
            tooltipLabel={`miroID: ${id}`}
          >
            <h3>miroID: {id}</h3>
          </OverflownTooltipText>
        )}
      </div>
      {size !== 'small' && !hideDetailsButton && (
        <Link to={instrumentDetailsUrl(organizationSlug, id)} onClick={e => e.stopPropagation()}>
          <IconButton label='Details'>
            <CgDetailsMore size='1.5em' />
          </IconButton>
        </Link>
      )}
    </div>
  )
}

InstrumentBodyContent.propTypes = {
  size: PropTypes.oneOf(['small', 'big', 'large']),
  id: PropTypes.string.isRequired,
  givenName: PropTypes.string,
  organization: PropTypes.string.isRequired,
  organizationSlug: PropTypes.string,
  hideDetailsButton: PropTypes.bool
}

const CurrentTimeAndUser = ({
  startedAt,
  user
}) => {
  const [runningTime, setRunningTime] = useState(
    startedAt ? (Date.now() - startedAt) / 1000 : 0
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setRunningTime(startedAt ? (Date.now() - startedAt) / 1000 : 0)
    }, 1000)
    return () => clearInterval(interval)
  }, [startedAt])

  const { h, m, s } = secondsToTime(runningTime)

  return (
    <h5 className={cx('timeCounter')}>{user} | {h}:{m}:{s}</h5>
  )
}

const InstrumentFooterContent = ({
  size,
  startedAt,
  user,
  status,
  connected,
  version
}) => {
  const getStatusLabel = () => {
    if (!connected) {
      return (
        <div className={cx('instrumentStatus')}>
          <IdleIcon />
          <h4 className={cx('status')}>Offline</h4>
        </div>
      )
    }

    switch (status) {
      case 'Busy':
        return (
          <div className={cx('instrumentStatus')}>
            <RunningIcon />
            <div>
              <h4 className={cx('status')}>
                {startedAt ? 'Running' : 'Setting experiment'}
              </h4>
              <CurrentTimeAndUser startedAt={startedAt} user={user} />
            </div>
          </div>
        )
      case 'Complete':
        return (
          <div className={cx(['instrumentStatus', 'complete'])}>
            <SuccessIcon />
            <h4 className={cx('status')}>Complete</h4>
          </div>
        )
      case 'Available':
        return (
          <div className={cx('instrumentStatus')}>
            <SuccessIcon />
            <h4 className={cx('status')}>Available</h4>
          </div>
        )
      case 'Missing':
      case 'Disconnected':
      case 'Offline':
        return (
          <div className={cx('instrumentStatus')}>
            <IdleIcon />
            <h4 className={cx('status')}>Inactive</h4>
          </div>
        )
      default:
        break
    }
  }

  return (
    <div className={cx('instrument-card-footer')}>
      {getStatusLabel()}
      {size !== 'small' && (
        <p className={cx('version')}>Version: {version}</p>
      )}
    </div>
  )
}

InstrumentFooterContent.propTypes = {
  size: PropTypes.oneOf(['small', 'big']),
  status: PropTypes.oneOf([
    'Busy',
    'Available',
    'Complete',
    'Disconnected',
    'Missing',
    'Offline'
  ]).isRequired,
  startedAt: PropTypes.number,
  connected: PropTypes.bool,
  version: PropTypes.string.isRequired
}

const InstrumentCard = ({
  onClick,
  disabled,
  size,
  id,
  givenName,
  organizationName,
  organizationSlug,
  status,
  startedAt,
  connected,
  version,
  user,
  hideDetailsButton,
  ...rest
}) => (
  <div
    data-testid={rest['data-testid']}
    className={cx('instrument-card', size, { disabled })}
    onClick={disabled ? undefined : onClick}
  >
    <InstrumentBodyContent
      id={id}
      givenName={givenName}
      organizationName={organizationName}
      organizationSlug={organizationSlug}
      size={size}
      hideDetailsButton={hideDetailsButton}
    />
    <InstrumentFooterContent
      status={status}
      startedAt={startedAt}
      connected={connected}
      user={user}
      version={version}
      size={size}
    />
  </div>
)

InstrumentCard.defaultProps = {
  size: 'big',
  onClick: () => {}
}

InstrumentCard.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  ...InstrumentBodyContent.propTypes,
  ...InstrumentFooterContent.propTypes
}

export default InstrumentCard
