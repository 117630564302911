import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { LOGIN_URL } from 'cons/routes'

const ValidatorRoute = memo(({
  children,
  validators,
  redirectionPath = LOGIN_URL,
  ...rest
}) => {
  const state = useSelector(wholeState => wholeState)
  const validatorsSatisfied = validators
    ? validators.map(v => v(state)).every(i => !!i)
    : true

  return (
    <Route
      {...rest} render={(props) => (
        validatorsSatisfied
          ? children
          : <Redirect to={redirectionPath} />
      )}
    />
  )
})

export default ValidatorRoute
