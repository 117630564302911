import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ProtocolList, ProtocolFilters, HeadingWithButton, protocolShape, LoadingWithText } from 'components'
import { useProtocolFilters } from 'hooks'
import classnames from 'classnames/bind'
import styles from './ProtocolListWithHeader.scss'

const cx = classnames.bind(styles)

const ProtocolListWithHeader = ({
  types = [],
  protocolsToDisplay,
  loading,
  sendingProtocol,
  onDeleteClick,
  onMount,
  headingTitle = ''
}) => {
  const {
    filteredProtocols, setDateRange, setSearchText, setSelectedType, setSelectedSortMethod,
    dateRange, searchText, selectedType, selectedSortMethod
  } = useProtocolFilters(protocolsToDisplay)

  const changeSelectedType = (newSelectedType) => {
    setSelectedType(newSelectedType?.value ?? 'All types')
  }

  const changeSearchText = (event) => {
    setSearchText(event?.target?.value)
  }

  useEffect(() => {
    onMount()
  }, [])

  return (
    <section className={cx('container')}>
      {sendingProtocol && <LoadingWithText message='Sending protocol to device...' />}
      {loading && <LoadingWithText message='Loading protocols...' />}
      <HeadingWithButton
        title={headingTitle}
        // TODO: add button props to bring back new protocol button (open cartridge)
      />
      <ProtocolFilters
        searchText={searchText}
        selectedType={selectedType}
        types={types}
        onSearchTextChange={changeSearchText}
        onTypeChange={changeSelectedType}
        dateRange={dateRange}
        onDateRangeChange={setDateRange}
        selectedSortMethod={selectedSortMethod}
        onSortMethodChange={setSelectedSortMethod}
      />
      <ProtocolList
        protocols={filteredProtocols}
        onDeleteClick={onDeleteClick}
      />
    </section>
  )
}

ProtocolListWithHeader.propTypes = {
  protocolsToDisplay: PropTypes.arrayOf(protocolShape),
  loading: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  headingTitle: PropTypes.string,
  onDeleteClick: PropTypes.func.isRequired,
  sendingProtocol: PropTypes.bool
}

export default ProtocolListWithHeader
