import { logo } from 'images'

export const notifyMe = (message) => {
  // Let's check if the browser supports notifications
  if (!window.Notification) return

  if (window.Notification.permission === 'granted') {
    try {
      // eslint-disable-next-line no-new
      new window.Notification('Anaconda', {
        body: message,
        icon: logo,
        image: logo
      })
    } catch (e) {
      // this won't work in Chrome mobile, to support it we need to implement service workers
      // https://stackoverflow.com/questions/29774836/failed-to-construct-notification-illegal-constructor
      if (e.name !== 'TypeError') throw e
    }
  }
}

export const initNotifications = () => {
  if (!window.Notification) return
  if (window.Notification.permission !== 'denied') {
    window.Notification.requestPermission()
  }
}
