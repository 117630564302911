import React, { memo, useContext } from 'react'
import {
  Breadcrumb,
  Header as NucleoHeader
} from '@miroculus/nucleo'
import styles from './Header.scss'
import classnames from 'classnames/bind'
import { BreadcrumbContext } from 'components'

const cx = classnames.bind(styles)

const Header = memo(() => {
  const { levels } = useContext(BreadcrumbContext)

  return (
    <div className={cx('container')}>
      <NucleoHeader>
        <Breadcrumb levels={levels} />
      </NucleoHeader>
    </div>
  )
})

export default Header
