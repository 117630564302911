import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { Button } from '@miroculus/nucleo'
import history from 'browserHistory'
import { DASHBOARD_URL } from 'cons/routes'
import { Header } from 'containers'
import styles from './PageNotFoundError.scss'

const cx = classnames.bind(styles)

const PageNotFoundError = ({
  title,
  code,
  message,
  buttonLabel,
  showButton,
  onButtonClick
}) => {
  const buttonClickHandler = () => onButtonClick
    ? onButtonClick()
    : history.push(DASHBOARD_URL)

  return (
    <div className={cx('container')}>
      <Header />
      <div className={cx('body')}>
        <h1 className={cx('code')}>{code}</h1>
        <h2 className={cx('title')}>{title}</h2>
        <div className={cx('messageContainer')}>
          <h3 className={cx('message')}>{message}</h3>
          {showButton
            ? (
              <div className={cx('buttonContainer')}>
                <Button
                  onClick={buttonClickHandler}
                  size='small'
                  flavor='secondary'
                >
                  {buttonLabel}
                </Button>
              </div>
              )
            : null}
        </div>
      </div>
    </div>
  )
}

PageNotFoundError.defaultProps = {
  code: '404',
  title: 'Page not found',
  message: 'The page you were trying to access does not exist, please check your URL or return to the home page.',
  buttonLabel: 'Home page',
  showButton: true
}

PageNotFoundError.propTypes = {
  title: PropTypes.string,
  code: PropTypes.string,
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  showButton: PropTypes.bool,
  onButtonClick: PropTypes.func
}

export default PageNotFoundError
