import { Spinner as LoadingSpinner } from '@miroculus/nucleo'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames/bind'
import styles from './LoadingWithText.scss'

const cx = classnames.bind(styles)

const LoadingWithText = ({ message, showMessageFirst = false }) => (
  <div className={cx('loadingSpinner')}>
    <LoadingSpinner />
    <h2 className={cx('message', { messageFirst: showMessageFirst })}>{message}</h2>
  </div>
)

LoadingWithText.prototype = {
  message: PropTypes.string.isRequired,
  showMessageFirst: PropTypes.bool
}

export default LoadingWithText
