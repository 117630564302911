// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrganizationMemberModal_modal-content__OlQen h1{text-transform:capitalize}.OrganizationMemberModal_modal-content__OlQen button{margin-top:2rem}.OrganizationMemberModal_modal-content__OlQen>div:first-child{margin-top:1.5rem}", ""]);
// Exports
exports.locals = {
	"modal-content": "OrganizationMemberModal_modal-content__OlQen"
};
module.exports = exports;
