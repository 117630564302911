import React, { useCallback } from 'react'
import classnames from 'classnames/bind'
import styles from './DynamicTable.scss'
import PropTypes from 'prop-types'

const cx = classnames.bind(styles)

const DynamicTable = ({ columnNames, body, withActions = false }) => {
  const renderTableHead = useCallback(() => {
    return (
      <tr>
        {columnNames.map((columnName) => (
          <th key={columnName}>{columnName}</th>
        ))}
        {withActions ? <th className={cx('table-head-actions')} key='table-head-actions' /> : null}
      </tr>
    )
  }, [columnNames])

  return (
    <div data-dynamic-table='' className={cx('container')}>
      <table>
        <thead>{renderTableHead()}</thead>
        <tbody>
          {body}
        </tbody>
      </table>
    </div>
  )
}

DynamicTable.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  body: PropTypes.node.isRequired,
  withActions: PropTypes.bool
}

export default DynamicTable
