import { LayoutUtils } from '@miroculus/nucleo'
import { addLog } from 'reduxModules/log'

const ELECTRODE_LAYOUT_LOAD = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_LOAD'
const ELECTRODE_LAYOUT_UPDATE = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_UPDATE'
const ELECTRODE_LAYOUT_TURN_ELECTRODE_ON = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_TURN_ELECTRODE_ON'
const ELECTRODE_LAYOUT_TURN_ELECTRODE_OFF = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_TURN_ELECTRODE_OFF'
const ELECTRODE_LAYOUT_TURN_ELECTRODES_ON = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_TURN_ELECTRODES_ON'
const ELECTRODE_LAYOUT_TURN_ELECTRODES_OFF = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_TURN_ELECTRODES_OFF'
const ELECTRODE_LAYOUT_SET_DROPLETS = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_SET_DROPLETS'
const ELECTRODE_LAYOUT_SET_DROPLET = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_SET_DROPLET'
const ELECTRODE_LAYOUT_TURN_ALL_ELECTRODES_OFF = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_TURN_ALL_ELECTRODES_OFF'
const ELECTRODE_LAYOUT_UPDATE_MOVE_BACKBONE = 'anaconda-web/electrodeLayout/ELECTRODE_LAYOUT_UPDATE_MOVE_BACKBONE'

// How much time the electrode with a droplet is red, default: one minute
const DROPLET_DETECTION_TIME = Number(process.env.DROPLET_DETECTION_TIME) || 60000
// If droplet detections are performed in less than 1.5 seconds the detections will be accumulated
// to maintain the droplets representation active in the electrolayout UI
const MAX_ACCUMULATION_TIME = 1500

const {
  loadLayout,
  getPolygonBySoftwareId
} = LayoutUtils

const MULTIELECTRODES_ELECTROPADS = ['amalthea']

// Bounded action creators
const getDetectionsAsArray = (values) => Object.entries(values)
  .filter(([k, v]) => v > 0)
  .map(([k, v]) => k)

let addingDetectionsTimeout
let addingDetections
export const setDroplets = (values) => (dispatch, getState) => {
  if (addingDetections) {
    const { polygons } = getState().electrodeLayout.layout
    const oldValues = polygons
      .filter(p => p.content > 0)
      .reduce((acc, p) => ({
        ...acc,
        [p.label]: p.content
      }), {})
    dispatch(setAllDroplets({
      ...values,
      ...oldValues
    }))
    clearTimeout(addingDetectionsTimeout)
  } else {
    addingDetections = true
    dispatch(setAllDroplets(values))
  }

  addingDetectionsTimeout = setTimeout(() => {
    addingDetections = false
  }, MAX_ACCUMULATION_TIME)

  const detectedDroplets = getDetectionsAsArray(values)
  if (detectedDroplets.length) {
    dispatch(addLog(`[DROPLETS DETECTED] ${JSON.stringify(detectedDroplets)}`, 'out', 'droplets'))
    setTimeout(() => {
      // clear electrodes after DROPLET_DETECTION_TIME
      const clearValues = {}
      Object.keys(values).forEach(key => {
        clearValues[key] = 0
      })
      dispatch(setAllDroplets(clearValues))
    }, DROPLET_DETECTION_TIME)
  }
}

export const setDroplet = (electrodeId, answer) => (dispatch) => {
  const hasDroplet = answer.length && answer[0] !== 0
  dispatch(setSingleDroplet(electrodeId, hasDroplet))

  if (hasDroplet) {
    dispatch(addLog(`[DROPLET DETECTED] ${electrodeId}`, 'out', 'droplets'))
    // clear electrode after DROPLET_DETECTION_TIME
    setTimeout(() => {
      dispatch(setSingleDroplet(electrodeId, false))
    }, DROPLET_DETECTION_TIME)
  }
}

// actions
export const updateElectrodeLayout = (payload) => ({
  type: ELECTRODE_LAYOUT_UPDATE,
  payload
})

export const updateMoveBackbone = (moveBackbone, timestamp) => ({
  type: ELECTRODE_LAYOUT_UPDATE_MOVE_BACKBONE,
  timestamp,
  moveBackbone
})

export const loadElectrodeLayout = (rawLayout) => ({
  type: ELECTRODE_LAYOUT_LOAD,
  payload: { rawLayout }
})

export function turnOffElectrode (electrodeId, timestamp) {
  return {
    type: ELECTRODE_LAYOUT_TURN_ELECTRODE_OFF,
    electrodeId: electrodeId,
    timestamp
  }
}

export function turnOnElectrode (electrodeId, timestamp) {
  return {
    type: ELECTRODE_LAYOUT_TURN_ELECTRODE_ON,
    electrodeId: electrodeId,
    timestamp
  }
}

export function turnOffAllElectrodes (timestamp) {
  return {
    type: ELECTRODE_LAYOUT_TURN_ALL_ELECTRODES_OFF,
    timestamp
  }
}

export function setAllDroplets (values) {
  return {
    type: ELECTRODE_LAYOUT_SET_DROPLETS,
    values
  }
}

export function setSingleDroplet (electrodeId, hasDroplet) {
  return {
    electrodeId,
    hasDroplet,
    type: ELECTRODE_LAYOUT_SET_DROPLET
  }
}

export function turnOnElectrodes (values, timestamp) {
  return {
    type: ELECTRODE_LAYOUT_TURN_ELECTRODES_ON,
    electrodeIds: values,
    timestamp
  }
}

export function turnOffElectrodes (values, timestamp) {
  return {
    type: ELECTRODE_LAYOUT_TURN_ELECTRODES_OFF,
    electrodeIds: values,
    timestamp
  }
}

const initialState = {
  layout: {},
  moveBackbone: [],
  moveBackboneTimestamp: 0
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  const newState = { ...state }
  switch (type) {
    case ELECTRODE_LAYOUT_LOAD:
      return {
        ...state,
        layout: loadLayout(payload.rawLayout)
      }

    case ELECTRODE_LAYOUT_UPDATE:
      return {
        ...state,
        layout: {
          ...state.layout,
          ...payload
        }
      }

    case ELECTRODE_LAYOUT_TURN_ELECTRODE_OFF: {
      newState.layout = { ...state.layout }
      newState.layout.polygons = [...state.layout.polygons]
      const polygonIndex = getPolygonBySoftwareId(newState.layout, action.electrodeId)
      const polygon = newState.layout.polygons[polygonIndex]

      const layoutName = newState.layout.name.toLowerCase()
      const multielectrode = MULTIELECTRODES_ELECTROPADS.find(e => layoutName === e)

      if (!polygon) return newState

      if (multielectrode) {
        const polygons = newState.layout.polygons.map((poly) => {
          if (polygon.label !== poly.label) return poly
          if (action.timestamp < poly.timestamp) return poly
          return {
            ...poly,
            status: false,
            timestamp: action.timestamp
          }
        })
        newState.layout.polygons = polygons
      } else {
        if (!polygon.timestamp || action.timestamp > polygon.timestamp) {
          newState.layout.polygons[polygonIndex] = {
            ...polygon,
            status: false,
            timestamp: action.timestamp
          }
        }
      }

      return newState
    }

    case ELECTRODE_LAYOUT_TURN_ELECTRODE_ON: {
      newState.layout = { ...state.layout }
      newState.layout.polygons = [...state.layout.polygons]
      const polygonIndex = getPolygonBySoftwareId(newState.layout, action.electrodeId)
      const polygon = newState.layout.polygons[polygonIndex]

      if (!polygon) return newState

      const layoutName = newState.layout.name.toLowerCase()
      const multielectrode = MULTIELECTRODES_ELECTROPADS.find(e => layoutName === e)

      if (multielectrode) {
        const polygons = newState.layout.polygons.map((poly) => {
          if (polygon.label !== poly.label) return poly
          if (action.timestamp < poly.timestamp) return poly
          return {
            ...poly,
            status: true,
            timestamp: action.timestamp
          }
        })

        newState.layout.polygons = polygons
      } else {
        if (!polygon.timestamp || action.timestamp > polygon.timestamp) {
          newState.layout.polygons[polygonIndex] = {
            ...polygon,
            status: true,
            timestamp: action.timestamp
          }
        }
      }

      return newState
    }

    case ELECTRODE_LAYOUT_UPDATE_MOVE_BACKBONE:
      return {
        ...state,
        layout: {
          ...state.layout,
          polygons: state.layout.polygons.map((poly) => {
            return {
              ...poly,
              backbone: action.moveBackbone.includes(Number(poly.label))
            }
          })
        },
        moveBackbone: action.moveBackbone,
        moveBackboneTimestamp: action.timestamp
      }

    case ELECTRODE_LAYOUT_TURN_ALL_ELECTRODES_OFF:
      newState.layout = { ...state.layout }
      newState.layout.polygons = state.layout.polygons.map((poly) => {
        if (poly.timestamp && action.timestamp < poly.timestamp) return poly
        return {
          ...poly,
          status: false,
          timestamp: action.timestamp
        }
      })
      return newState

    case ELECTRODE_LAYOUT_TURN_ELECTRODES_ON:
      newState.layout = { ...state.layout }
      newState.layout.polygons = state.layout.polygons.map((poly) => {
        if (!action.electrodeIds.includes(poly.label)) return poly
        if (poly.timestamp && action.timestamp < poly.timestamp) return poly
        return {
          ...poly,
          status: true,
          timestamp: action.timestamp
        }
      })
      return newState

    case ELECTRODE_LAYOUT_TURN_ELECTRODES_OFF:
      newState.layout = { ...state.layout }
      newState.layout.polygons = state.layout.polygons.map((poly) => {
        if (!action.electrodeIds.includes(poly.label)) return poly
        if (poly.timestamp && action.timestamp < poly.timestamp) return poly
        return {
          ...poly,
          status: false,
          timestamp: action.timestamp
        }
      })
      return newState
    case ELECTRODE_LAYOUT_SET_DROPLETS:
      newState.layout = { ...state.layout }
      newState.layout.polygons = state.layout.polygons.map((poly) => {
        const newPolygon = { ...poly }
        newPolygon.content = action.values[newPolygon.label] || 0
        return newPolygon
      })
      return newState
    case ELECTRODE_LAYOUT_SET_DROPLET: {
      newState.layout = { ...state.layout }
      newState.layout.polygons = [...state.layout.polygons]
      const polygonIndex = getPolygonBySoftwareId(newState.layout, action.electrodeId)
      const polygon = newState.layout.polygons[polygonIndex]
      newState.layout.polygons[polygonIndex] = { ...polygon, content: action.hasDroplet }
      return newState
    }
    default:
      return state
  }
}
