// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProjectSelection_container__3BMH-{display:flex;flex-direction:column;width:100%;height:100%;overflow-y:scroll;padding:0 7rem}.ProjectSelection_projectsContainer__37Fs6{display:flex;flex-direction:column;width:100%;justify-content:flex-start;align-items:center;padding:0 1rem;overflow:auto}.ProjectSelection_projectsContainer__37Fs6 .ProjectSelection_project__3uhXE:nth-child(odd){background-color:#f4f6f8}.ProjectSelection_projectsContainer__37Fs6 .ProjectSelection_project__3uhXE{font-size:2rem;padding:1rem;color:#414042;font-weight:200;cursor:pointer;width:100%;flex-basis:5rem;flex-shrink:0;margin:.5rem 0;background:#e8eef1;display:flex;align-items:center;box-shadow:2px 4px 7px rgba(0,0,0,.25);opacity:1;transition:all ease-in 250ms}.ProjectSelection_projectsContainer__37Fs6 .ProjectSelection_project__3uhXE:hover{opacity:.7}.ProjectSelection_buttonContainer__1Tpg7{padding:1rem}.ProjectSelection_titleContainer__T3tLR{display:flex;align-items:center;padding:0 1rem;flex-basis:8rem;flex-shrink:0;color:var(--color-primary)}.ProjectSelection_titleContainer__T3tLR h2{font-size:2rem;font-weight:normal}.ProjectSelection_backButton__2M0Eh{padding:1rem;cursor:pointer;transition:ease-out opacity 250ms}.ProjectSelection_backButton__2M0Eh:hover{opacity:.7}.ProjectSelection_backButtonIcon__FYEUd{font-size:3rem}.ProjectSelection_spinnerContainer__3Cu3w{height:100%;width:100%;display:flex;justify-content:center;align-items:center}.ProjectSelection_footer__3ZSSN{flex-basis:8rem;display:flex;justify-content:flex-end;align-items:center;flex-shrink:0;width:100%;padding:0 1rem}.ProjectSelection_cancelButton__gTU2K{width:10rem}", ""]);
// Exports
exports.locals = {
	"container": "ProjectSelection_container__3BMH-",
	"projectsContainer": "ProjectSelection_projectsContainer__37Fs6",
	"project": "ProjectSelection_project__3uhXE",
	"buttonContainer": "ProjectSelection_buttonContainer__1Tpg7",
	"titleContainer": "ProjectSelection_titleContainer__T3tLR",
	"backButton": "ProjectSelection_backButton__2M0Eh",
	"backButtonIcon": "ProjectSelection_backButtonIcon__FYEUd",
	"spinnerContainer": "ProjectSelection_spinnerContainer__3Cu3w",
	"footer": "ProjectSelection_footer__3ZSSN",
	"cancelButton": "ProjectSelection_cancelButton__gTU2K"
};
module.exports = exports;
