// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DatePickerField_datePicker__3K2ON input{width:100%;padding:.875rem;border-radius:.5rem;border:1px solid var(--color-neutral-400);background:var(--color-neutral-500);color:var(--color-neutral-100)}", ""]);
// Exports
exports.locals = {
	"datePicker": "DatePickerField_datePicker__3K2ON"
};
module.exports = exports;
