import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'
import DatePicker from 'react-datepicker'
import classNames from 'classnames/bind'

import styles from './DatePickerField.scss'

const cx = classNames.bind(styles)

const DatePickerField = ({ ...props }) => {
  return (
    <DatePicker
      {...props}
      wrapperClassName={cx('datePicker')}
      showYearDropdown
      showMonthDropdown
      scrollableYearDropdown
    />
  )
}

export default DatePickerField
