import React from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import classnames from 'classnames/bind'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Input } from '@miroculus/nucleo'
import { LOGIN_URL } from 'cons/routes'
import { AuthPage } from 'components'
import { canSubmit, getFirstError, getFieldError } from 'utils/forms'
import { resetPassword } from 'reduxModules/auth'
import { useQuery } from 'hooks'
import browserHistory from 'browserHistory'
import styles from './ForgotPassword.scss'

const cx = classnames.bind(styles)

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('The email you entered is invalid')
    .required('An email is required')
})

const ForgotPassword = () => {
  const query = useQuery()
  const email = query.get('email')
  const dispatch = useDispatch()
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      email: email ?? ''
    },
    onSubmit: async (values, actions) => {
      actions.setStatus(undefined)
      try {
        await dispatch(resetPassword(values.email))
        toast.success('An email has been sent! Check your inbox.')
        browserHistory.push(`${LOGIN_URL}?email=${values.email}`)
      } catch (e) {
        if (e.status === 400) {
          actions.setStatus(e.message)
        } else {
          throw e
        }
      }
    },
    validationSchema: ForgotPasswordSchema
  })

  const errorToDisplay = getFirstError(formik)

  const statusMessage = errorToDisplay || formik.status

  return (
    <AuthPage additionalInfo={'We\'ll send you an email with instructions to reset your password.'}>
      <form className={cx('form')} onSubmit={formik.handleSubmit}>
        <Input
          {...formik.getFieldProps('email')}
          name='email'
          type='email'
          placeholder='Enter your email'
          error={getFieldError(formik, 'email')}
          autoFocus
        />
        <div className={cx('buttonContainer')}>
          <p className={cx('statusMessage')}>{statusMessage || <>&nbsp;</>}</p>
          <Button type='submit' disabled={!canSubmit(formik)}>Send email</Button>
        </div>
      </form>
    </AuthPage>
  )
}

export default ForgotPassword
