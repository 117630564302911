// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PageNotFoundError_container__1vd8a{display:flex;flex-direction:column;width:100%;height:100vh;align-items:center}.PageNotFoundError_code__2oKGc{font-size:10rem;margin:0}.PageNotFoundError_title__VvwLf{font-size:2rem;margin:0}.PageNotFoundError_messageContainer__2CQfb{display:flex;flex-direction:column;justify-content:center;align-items:center;text-align:center}.PageNotFoundError_body__3Leea{height:100%;display:flex;padding:3rem;width:96%;justify-content:center;align-items:center;flex-direction:column;max-width:600px}.PageNotFoundError_buttonContainer__2113N{margin:1rem;width:11rem}", ""]);
// Exports
exports.locals = {
	"container": "PageNotFoundError_container__1vd8a",
	"code": "PageNotFoundError_code__2oKGc",
	"title": "PageNotFoundError_title__VvwLf",
	"messageContainer": "PageNotFoundError_messageContainer__2CQfb",
	"body": "PageNotFoundError_body__3Leea",
	"buttonContainer": "PageNotFoundError_buttonContainer__2113N"
};
module.exports = exports;
