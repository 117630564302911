// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CreateOrganization_form__2idVT>input{margin:.5rem 0}.CreateOrganization_notice__3r-XO{color:var(--color-primary);font-weight:500;margin-bottom:2rem}.CreateOrganization_statusMessage__13Lqq{color:var(--color-error);font-weight:600;margin:2rem 0}.CreateOrganization_buttonContainer__2_z6I{position:relative;text-align:center;width:100%}", ""]);
// Exports
exports.locals = {
	"form": "CreateOrganization_form__2idVT",
	"notice": "CreateOrganization_notice__3r-XO",
	"statusMessage": "CreateOrganization_statusMessage__13Lqq",
	"buttonContainer": "CreateOrganization_buttonContainer__2_z6I"
};
module.exports = exports;
