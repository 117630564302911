import React from 'react'
import {
  PathCanvas,
  PathCanvasUtils
} from '@miroculus/miroculus-ui/dist/pathfinder'
import miroLayout from './miro-layout.json'

const SolutionCanvas = (props) => {
  const { width, height, cartridge } = PathCanvasUtils.buildCartridge(
    'miro', props.width, props.height, miroLayout, props.paths
  )

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <PathCanvas
        cartridge={cartridge}
        theme='skyblue'
        width={width}
        height={height}
        fullView
      />
    </div>
  )
}

export default SolutionCanvas
