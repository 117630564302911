import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import socketClient from 'socketClient'
import { selectDevice, updateLoading, updateSelectedDeviceId } from 'reduxModules/devices'
import { loadDeviceRuns } from 'reduxModules/runs'
import {
  currentDeviceSelector
} from 'reduxModules/devices/selectors'
import {
  getOrganizations,
  findOrganizationBySlug
} from 'reduxModules/organizations/selectors'
import { InstrumentCard, useBreadcrumb, HeadingWithButton } from 'components'
import { unifiedVersion } from 'utils'
import { Spinner } from '@miroculus/nucleo'
import classNames from 'classnames/bind'

import styles from './DeviceDetails.scss'
import RunsList from './RunsList'
const cx = classNames.bind(styles)

function DeviceDetails () {
  const { orgSlug, deviceId } = useParams()
  const device = useSelector(currentDeviceSelector)
  const { connecting, loading } = useSelector((state) => state.devices)
  const { runs, loading: loadingRuns } = useSelector((state) => state.runs)

  const organizations = useSelector(getOrganizations)
  const organization = findOrganizationBySlug(organizations, orgSlug)
  const { canAccessAnaconda } = organization?.$permissions ?? {}

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadDeviceRuns(deviceId))

    const subscribeToDevices = async () => {
      try {
        await dispatch(updateLoading(true))
        await socketClient.subscribeToDevices(orgSlug)
      } finally {
        await dispatch(updateLoading(false))
        dispatch(updateSelectedDeviceId(deviceId))
      }
    }

    const unsubscribeFromDevices = async () => {
      await socketClient.unsubscribeToDevices(orgSlug)
    }

    subscribeToDevices()
    return () => {
      unsubscribeFromDevices()
    }
  }, [orgSlug, deviceId])

  useBreadcrumb(
    [
      {
        text: organization.name,
        href: `/organization/${orgSlug}`
      },
      {
        text: 'Instruments',
        href: `/organization/${orgSlug}/instruments`
      },
      {
        text: deviceId
      }
    ],
    [organization.name, orgSlug]
  )

  const handleInstrumentClick = (id, status) => () => {
    if (canAccessAnaconda) {
      dispatch(selectDevice(id, status))
    }
  }

  const {
    id,
    connected,
    status,
    givenName,
    by,
    firmware,
    software,
    anacondaPi
  } = device || {}

  if (connecting || loading || loadingRuns) {
    return (
      <div className={cx('loadingContainer')}>
        <Spinner />
      </div>
    )
  }

  return (
    <div className={cx('mainContainer')}>
      <section className={cx('section', 'bordered')}>
        <HeadingWithButton title={givenName || id} />
        <div className={cx('deviceId')}>
          <h3>{`DEVICE ID: ${id}`}</h3>
        </div>
      </section>
      <section className={cx('section', 'bordered')}>
        <h3 className={cx('subtitle')}>CURRENT STATUS</h3>
        <InstrumentCard
          key={`instrument-card-${id}`}
          data-testid={`instrument-card-${id}`}
          disabled={!connected || status === 'Disconnected'}
          onClick={
            canAccessAnaconda ? handleInstrumentClick(id, status) : undefined
          }
          id={id}
          givenName={givenName || id}
          organizationName={organization.name}
          status={status}
          startedAt={device?.experiment?.startedAt}
          user={by}
          connected={connected}
          version={unifiedVersion(anacondaPi, software, firmware)}
          size='large'
          hideDetailsButton
        />
      </section>
      <section className={cx('section')}>
        <h3 className={cx('subtitle')}>DEVICE HISTORY</h3>
        {runs.length
          ? (
            <RunsList runs={runs} orgSlug={orgSlug} deviceId={id} />
            )
          : (
            <h3 className={cx('noRunsMessage')}>No runs found</h3>
            )}
      </section>
    </div>
  )
}

export default DeviceDetails
