import React from 'react'
import { IoMdHelp } from 'react-icons/io'
import { IconButton } from '@miroculus/nucleo'

const HelpButton = ({ ...props }) => (
  <IconButton label='Help' {...props}>
    <IoMdHelp size='1.5em' />
  </IconButton>
)

export default HelpButton
