import history from 'browserHistory'
import * as Sentry from '@sentry/browser'
import apiClient from '@miroculus/api-client'
import { LOGIN_URL } from 'cons/routes'
import socketClient from 'socketClient'
import { redeemInvitation, loadOrganizations } from 'reduxModules/organizations'

const AUTH_UPDATE_USER = 'anaconda-web/auth/AUTH_UPDATE_USER'
const AUTH_UPDATE_LOGGED = 'anaconda-web/auth/AUTH_UPDATE_LOGGED'
const AUTH_UPDATE_LOADING = 'anaconda-web/auth/AUTH_UPDATE_LOADING'

export const loginWithPassword = ({
  email,
  password,
  inviteToken
}) => async (dispatch) => {
  const { body } = await apiClient.post('/auth/signin', { email, password })
  await dispatch(login({ ...body, inviteToken }))
}

export const signUpWithPassword = ({
  email,
  name,
  password,
  inviteToken,
  createToken
}) => async (dispatch) => {
  await apiClient.post(
    '/auth/signup',
    { email, name, password, inviteToken, createToken }
  )
  await dispatch(loginWithPassword({ email, password }))
}

export const login = ({
  jwt,
  refresh,
  name,
  email,
  inviteToken
} = {}) => {
  if (jwt && refresh && name && email) {
    apiClient.setUser({ jwt, refresh, name, email })
  }

  const user = apiClient.getUser()

  // set context for sentry error reports
  if (process.env.SENTRY_URL) Sentry.setUser(user)

  return async (dispatch) => {
    if (user) {
      dispatch(updateUser(user))

      if (inviteToken) {
        await dispatch(redeemInvitation(inviteToken, email))
      }

      await socketClient.connect()
      await dispatch(loadOrganizations())

      dispatch(updateLoading(false))
      dispatch(updateLogged(true))
    } else {
      dispatch(updateLoading(false))
      dispatch(updateLogged(false))
    }
  }
}

export const logout = () => (dispatch) => {
  socketClient.disconnect()
  apiClient.clearUser()
  window.localStorage.clear()

  dispatch(updateUser({ name: null, email: null }))
  dispatch(updateLogged(false))
  dispatch(updateLoading(false))
  history.push(LOGIN_URL)
}

export const resetPassword = (email) => async () => {
  try {
    await apiClient.post('/auth/password-reset', { email })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const resetUserPassword = () => (dispatch, getState) =>
  dispatch(resetPassword(getState().auth.user.email))

export const setNewPassword = (password, token) => async () => {
  try {
    await apiClient.post('/auth/password-reset/redeem', { password, token })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const loadUserData = () => async (dispatch) => {
  dispatch(updateLoading(true))
  try {
    const { body: userAccountData } = await apiClient.get('/users/me')
    dispatch(updateUser({ ...userAccountData }))
  } catch (e) {
    Sentry.captureException(e)
  } finally {
    dispatch(updateLoading(false))
  }
}

export const updateAccountSettings = (userData) => async (dispatch) => {
  dispatch(updateLoading(true))
  try {
    await apiClient.put('/users/me', { name: userData.name })
    dispatch(updateUser({ ...userData }))
  } catch (e) {
    Sentry.captureException(e)
  } finally {
    dispatch(updateLoading(false))
  }
}

// actions
export const updateLogged = (logged) => ({
  type: AUTH_UPDATE_LOGGED,
  payload: { logged }
})

export const updateLoading = (loading) => ({
  type: AUTH_UPDATE_LOADING,
  payload: { loading }
})

export const updateUser = (user) => ({
  type: AUTH_UPDATE_USER,
  payload: { user }
})

const initialState = {
  user: {},
  logged: false,
  loading: false
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case AUTH_UPDATE_USER:
    case AUTH_UPDATE_LOGGED:
    case AUTH_UPDATE_LOADING:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
