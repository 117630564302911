import React, { useCallback } from 'react'
import throttle from 'lodash.throttle'
import { ArrowLeftIcon, ArrowRightIcon, Button } from '@miroculus/nucleo'
import classNames from 'classnames/bind'
import styles from './MinimapContainer.scss'
import { node, func } from 'prop-types'

const cx = classNames.bind(styles)

// compute percentage of the minimap where clicked
const getPoint = (el, clientX) => {
  const { left, width } = el.getBoundingClientRect()
  return (clientX - left) / width
}

const MinimapContainer = ({ children, onClick, onLeft, onRight }) => {
  const handleMouseDown = useCallback(
    (evt) => {
      if (!onClick) return

      // prevent text selection over the app
      evt.preventDefault()

      const el = evt.currentTarget
      onClick(getPoint(el, evt.clientX))

      const handleMouseMove = throttle(({ clientX }) => {
        onClick(getPoint(el, clientX))
      }, 100)

      const handleMouseUp = ({ clientX }) => {
        onClick(getPoint(el, clientX))
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    },
    [onClick]
  )

  return (
    <>
      <div className={cx('headerOverlay', { whole: !onClick })} />
      <div className={cx('container')}>
        <div className={cx('minimap')} onMouseDown={handleMouseDown}>
          {children}
        </div>
        <div className={cx('buttons')}>
          <Button
            flavor='secondary'
            size='small'
            onClick={onLeft}
            data-testid='minimap-left'
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            flavor='secondary'
            size='small'
            onClick={onRight}
            data-testid='minimap-right'
          >
            <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </>
  )
}

MinimapContainer.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
  onLeft: func.isRequired,
  onRight: func.isRequired
}

export default MinimapContainer
