import React, { useContext, useRef, useState } from 'react'
import { ConfirmModal, Button } from '@miroculus/nucleo'

const { createContext } = require('react')

const defaultValue = {
  isShowing: false,
  level: 'warning',
  message: 'Confirm',
  options: [
    {
      text: 'OK',
      callback: () => {}
    },
    {
      text: 'Cancel',
      callback: () => {}
    }
  ],
  primaryOption: 'OK',
  defaultOption: 'Cancel'
}

export const ConfirmContext = createContext(defaultValue)

export const ConfirmProvider = ({ children }) => {
  const [value, setValue] = useState(defaultValue)

  const show = (level, message, options, primaryOption, defaultOption) => {
    setValue({
      isShowing: true,
      level,
      message,
      options,
      primaryOption,
      defaultOption
    })
  }

  const hide = () => {
    setValue({
      isShowing: false
    })
  }

  return (
    <ConfirmContext.Provider
      value={{
        ...value,
        confirm: (message, callback, { okText = 'OK', level = 'warning' } = {}) => {
          show(
            level,
            message,
            [
              { text: 'Cancel', callback: () => {} },
              { text: okText, callback }
            ],
            okText,
            'Cancel'
          )
        },
        alert: (message, callback, okText = 'OK') => {
          show(
            'warning',
            message,
            [{ text: okText, callback }],
            okText,
            okText
          )
        },
        show,
        hide
      }}
    >
      {children}
    </ConfirmContext.Provider>
  )
}

export const useConfirm = () => useContext(ConfirmContext)

export const ConfirmContainer = () => {
  const {
    isShowing,
    level,
    message,
    options,
    primaryOption,
    defaultOption,
    hide
  } = useConfirm()

  const ref = useRef(null)

  return isShowing
    ? (
      <ConfirmModal level={level} description={message} leastDestructiveRef={ref}>
        {options.map((option) => (
          <Button
            key={option.text}
            data-testid={
              option.text === primaryOption ? 'confirm-button' : 'cancel-button'
            }
            ref={option.text === defaultOption ? ref : null}
            flavor={option.text === primaryOption ? 'primary' : 'secondary'}
            onClick={() => {
              hide()
              option.callback()
            }}
          >
            {option.text}
          </Button>
        ))}
      </ConfirmModal>
      )
    : null
}
