import React, { memo } from 'react'
import classnames from 'classnames/bind'
import PropTypes from 'prop-types'
import { userPlaceholder } from 'images'
import styles from './UserAccountHeader.scss'

const cx = classnames.bind(styles)

const UserAccountHeader = memo(({ userFullName, joinedDate }) => (
  <div className={cx('user-account-header')} data-testid='user-account-header'>
    <div className={cx('user-account-header-avatar')}>
      <div className={cx('user-account-header-avatar-image')}>
        <img src={userPlaceholder} alt='Avatar' />
      </div>
    </div>
    <div className={cx('user-account-brief-info')}>
      <h1 data-testid='user-account-name' className={cx('user-account-name')}>{userFullName}</h1>
      {!!joinedDate &&
        <p
          data-testid='user-account-joined-date'
          className={cx('user-account-joined-date')}
        >
          {`Joined on ${new Date(joinedDate).toLocaleDateString(
            'en-US',
            { year: 'numeric', month: 'short', day: 'numeric' }
          )}`}
        </p>}
    </div>
  </div>
))

UserAccountHeader.propTypes = {
  userFullName: PropTypes.string.isRequired,
  joinedDate: PropTypes.number.isRequired
}

export default UserAccountHeader
