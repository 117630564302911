import { useEffect } from 'react'
import { defaultTheme, neutral } from '@miroculus/nucleo'
import addStyle from './add-style'

export default function InjectCSSVariables () {
  useEffect(() => {
    addStyle(`
      :root {
        --color-primary: ${defaultTheme.colorPrimary};
        --color-primary-disabled: ${defaultTheme.colorPrimaryDisabled};
        --color-tertiary: ${defaultTheme.colorTertiary};
        --color-text: ${defaultTheme.colorText};
        --color-text-on-primary: ${defaultTheme.colorTextOnPrimary};
        --color-control-text: ${defaultTheme.colorControlText};
        --color-control-border: ${defaultTheme.colorControlBorder};
        --color-input-background: ${defaultTheme.colorInputBackground};
        --color-input-placeholder: ${defaultTheme.colorInputPlaceholder};
        --color-input-border: ${defaultTheme.colorInputBorder};
        --color-page-background: ${defaultTheme.colorPageBackground};
        --color-title: ${defaultTheme.colorTitle};
        --color-success: ${defaultTheme.colorSuccess};
        --color-warning: ${defaultTheme.colorWarning};
        --color-error: ${defaultTheme.colorError};
        --color-white: ${defaultTheme.colorWhite};
        --color-white-rgb: 255, 255, 255;
        --color-neutral-100: ${neutral[100]};
        --color-neutral-150: ${neutral[150]};
        --color-neutral-350: ${neutral[350]};
        --color-neutral-400: ${neutral[400]};
        --color-neutral-500: ${neutral[500]};
        --font-primary: ${defaultTheme.fontPrimary};
        --color-bubble1: ${defaultTheme.colorBubble1};
        --color-bubble2: ${defaultTheme.colorBubble2};
        --color-bubble3: ${defaultTheme.colorBubble3};
        --color-border: ${defaultTheme.colorBorder};
        --font-size-xxsmall: ${defaultTheme.fontSizes.xxsmall};
        --font-size-xsmall: ${defaultTheme.fontSizes.xsmall};
        --font-size-small: ${defaultTheme.fontSizes.small};
        --font-size-medium: ${defaultTheme.fontSizes.medium};
        --font-size-large: ${defaultTheme.fontSizes.large};
        --font-size-xlarge: ${defaultTheme.fontSizes.xlarge};
        --font-size-xxlarge: ${defaultTheme.fontSizes.xxlarge};
        --font-size-x3large: ${defaultTheme.fontSizes.x3large};
        --font-size-x4large: ${defaultTheme.fontSizes.x4large};
        --font-size-x5large: ${defaultTheme.fontSizes.x5large};
        --font-size-x6large: ${defaultTheme.fontSizes.x6large};
        --font-size-huge: ${defaultTheme.fontSizes.huge};
      }
    `)
  }, [])

  return null
}
