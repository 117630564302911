import PropTypes from 'prop-types'
import protocolShape from './protocolShape'

const protocolListPropTypes = {
  protocolsToDisplay: PropTypes.arrayOf(protocolShape),
  loading: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  onDeleteClick: PropTypes.func.isRequired,
  currentTeam: PropTypes.string,
  currentTeamPermissions: PropTypes.shape({
    canCreateProtocol: PropTypes.bool
  }),
  sendingProtocol: PropTypes.bool,
  onUnmount: PropTypes.func
}

export const protocolListDefaultProps = {
  protocolsToDisplay: [],
  loading: false,
  sendingProtocol: false,
  currentTeam: null,
  currentTeamPermissions: {},
  onUnmount: () => {
    // This shouldn't be undefined
  }
}

export default protocolListPropTypes
