const LOG_ADD = 'anaconda-web/LOG_ADD'
const LOG_TOGGLE_SOURCE = 'anaconda-web/LOG_TOGGLE_SOURCE'
const LOG_RESET = 'anaconda-web/LOG_RESET'
const ADD_API_HEALTH_DATA = 'anaconda-web/ADD_API_HEALTH_DATA'

export const addLog = (message, logType = 'info', source = 'default') => ({
  type: LOG_ADD,
  message: message,
  logType: logType,
  timestamp: Date.now(),
  source: source
})

export const toggleSource = (source) => ({
  type: LOG_TOGGLE_SOURCE,
  source: source
})

export const resetLogs = () => ({
  type: LOG_RESET
})

export const addAPIHealthData = (data) => ({
  type: ADD_API_HEALTH_DATA,
  payload: data
})

const initialState = {
  registry: [],
  sources: {
    log: true
  },
  apiHealthData: {}
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case LOG_ADD: {
      return {
        ...state,
        registry: [
          ...state.registry,
          {
            message: action.message,
            logType: action.logType,
            timestamp: action.timestamp,
            source: action.source
          }
        ],
        sources: {
          ...state.sources,
          [action.source]: state.sources[action.source] !== false
        }
      }
    }
    case LOG_TOGGLE_SOURCE:
      return {
        ...state,
        sources: {
          ...state.sources,
          [action.source]: !state.sources[action.source]
        }
      }
    case LOG_RESET:
      return { ...initialState }
    case ADD_API_HEALTH_DATA:
      return {
        ...state,
        apiHealthData: action.payload
      }
    default:
      return state
  }
}
