import React, { memo, useLayoutEffect, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames/bind'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import apiClient from '@miroculus/api-client'
import { Button, Input } from '@miroculus/nucleo'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { AuthPage } from 'components'
import {
  DASHBOARD_URL,
  FORGOT_PASSWORD_URL,
  SIGNUP_URL,
  CREATE_ORGANIZATION_URL
} from 'cons/routes'
import history from 'browserHistory'
import { login, loginWithPassword } from 'reduxModules/auth'
import { getLogged } from 'reduxModules/auth/selectors'
import { useQuery } from 'hooks'
import { getRedirectUrl } from 'utils'
import { canSubmit, getFirstError, getFieldError } from 'utils/forms'
import styles from './Login.scss'

const cx = classnames.bind(styles)

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('The email you entered is invalid')
    .required('An email is required'),
  password: Yup.string()
    .required('A password is required')
})

const dataFromQuery = (query) => ({
  error: query.get('error'),
  email: query.get('email'),
  inviteToken: query.get('inviteToken'),
  jwt: query.get('jwt'),
  name: query.get('name'),
  refresh: query.get('refresh'),
  redirectTo: query.get('redirectTo')
})

const processErrorFromQuery = (error) => {
  switch (error) {
    case 'INVALID_INVITE_TOKEN':
      toast.error('This invitation is invalid, please ask for another one.')
      break
    case 'SERVER_ERROR':
      toast.error('There was a server error, please try again.')
      break
    case 'INVALID_INVITE_EMAIL':
      toast.error('Your email doesn\'t match the invitation.')
      break
    case 'MISSING_INVITE_TOKEN':
      toast.error('You need an invitation to login.')
      break
    case 'EXPIRED_INVITE':
      toast.error('Your invitation expired, please ask for another one.')
      break
    case 'INVALID_ORGANIZATION':
      toast.error('Your email is not from a valid organization.')
      break
    default:
      toast.error(`Code: ${error}`)
      break
  }
}

const getInviteMessage = (inviteToken, redirectTo) => {
  if (inviteToken) {
    return 'Please sign in to accept the team invitation.'
  }

  if (redirectTo?.includes(CREATE_ORGANIZATION_URL)) {
    return 'Please sign in to create a new organization.'
  }

  return null
}

export const Login = memo(({
  onSubmit,
  onGoogleLogin,
  onLoggedIn,
  onLoginFromQuery,
  logged
}) => {
  const {
    error, email, inviteToken, jwt, name, refresh, redirectTo
  } = dataFromQuery(useQuery())

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      email: email ?? '',
      password: ''
    },
    onSubmit: async (values, actions) => {
      actions.setStatus(undefined)
      try {
        await onSubmit({
          ...values,
          inviteToken
        })
      } catch (e) {
        if (e.status === 400) {
          actions.setStatus(e.message)
        } else {
          throw e
        }
      }
    },
    validationSchema: LoginSchema
  })

  const handleGoogleLogin = useCallback(() => {
    onGoogleLogin(inviteToken, redirectTo)
  }, [])

  // by using a layout effect we prevent the user from seeing the first render
  useLayoutEffect(() => {
    if (logged) {
      onLoggedIn(redirectTo)
    } else if (jwt && refresh) {
      onLoginFromQuery({
        jwt, refresh, email, name, inviteToken
      })
    }
  }, [logged, jwt, refresh, email, name, inviteToken])

  useEffect(() => {
    if (error) {
      processErrorFromQuery(error)
    }
  }, [error])

  const inviteMessage = getInviteMessage(inviteToken, redirectTo)
  const errorToDisplay = getFirstError(formik)
  const statusMessage = errorToDisplay || formik.status
  const signUpUrl = `${SIGNUP_URL}${window.location.search}`
  const forgotPasswordUrl = `${FORGOT_PASSWORD_URL}?email=${formik.values.email}`

  return (
    <AuthPage
      additionalInfo={inviteMessage}
      ssoButton={
        <Button onClick={handleGoogleLogin}>Sign in with Google</Button>
      }
      footerText={
        <>
          <p><Link to={forgotPasswordUrl}>Forgot password?</Link></p>
          <p>Don't have an account? <Link to={signUpUrl}>Sign up</Link></p>
        </>
      }
    >
      <form className={cx('form')} onSubmit={formik.handleSubmit}>
        <Input
          {...formik.getFieldProps('email')}
          name='email'
          type='email'
          placeholder='Enter your email'
          error={getFieldError(formik, 'email')}
          autoFocus
        />
        <Input
          {...formik.getFieldProps('password')}
          name='password'
          type='password'
          placeholder='Enter your password'
          maxLength={512}
          error={getFieldError(formik, 'password')}
        />
        <div className={cx('buttonContainer')}>
          <p className={cx('statusMessage')}>{statusMessage || <>&nbsp;</>}</p>
          <Button type='submit' disabled={!canSubmit(formik)}>Sign in</Button>
        </div>
      </form>
    </AuthPage>
  )
})

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onGoogleLogin: PropTypes.func.isRequired,
  onLoginFromQuery: PropTypes.func.isRequired,
  onLoggedIn: PropTypes.func.isRequired,
  logged: PropTypes.bool
}

Login.defaultProps = {
  logged: false
}

const mapStateToProps = (state) => ({
  logged: getLogged(state)
})

const mapDispatchToProps = dispatch => ({
  onLoggedIn: async (redirectTo) => {
    history.push(getRedirectUrl(redirectTo, DASHBOARD_URL))
  },
  onLoginFromQuery: async (data) => {
    await dispatch(login(data))
  },
  onSubmit: async (data) => {
    await dispatch(loginWithPassword(data))
  },
  onGoogleLogin: (inviteToken, redirectTo) => {
    const baseUrl = apiClient.getGoogleAuthUrl({
      redirectUrl: window.location.origin + getRedirectUrl(redirectTo, DASHBOARD_URL)
    })
    const url = inviteToken ? `${baseUrl}&inviteToken=${inviteToken}` : baseUrl

    window.location.replace(url)
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(Login)
