import apiClient from '@miroculus/api-client'

const SETTINGS_UPDATE_CAMERA_ID = 'anaconda-web/settings/SETTINGS_UPDATE_CAMERA_ID'
const SETTINGS_UPDATE_CAMERAS = 'anaconda-web/settings/SETTINGS_UPDATE_CAMERAS'
const SETTINGS_TOGGLE_CAMERA = 'anaconda-web/settings/SETTINGS_TOGGLE_CAMERA'
const SETTINGS_TOGGLE_HEATER_OPENED = 'anaconda-web/settings/SETTINGS_TOGGLE_HEATER_OPENED'
const SETTINGS_UPDATE_HEATER_P = 'anaconda-web/settings/SETTINGS_UPDATE_HEATER_P'
const SETTINGS_UPDATE_HEATER_I = 'anaconda-web/settings/SETTINGS_UPDATE_HEATER_I'
const SETTINGS_UPDATE_HEATER_D = 'anaconda-web/settings/SETTINGS_UPDATE_HEATER_D'
const SETTINGS_UPDATE_HEATER_GAIN = 'anaconda-web/settings/SETTINGS_UPDATE_HEATER_GAIN'
const SETTINGS_UPDATE_HEATER_OFFSET = 'anaconda-web/settings/SETTINGS_UPDATE_HEATER_OFFSET'

// Bound action creators
export const loadCameras = (cameras) => {
  return (dispatch, getState) => {
    dispatch(updateCameras(cameras))
    const cameraId = getState().settings.cameraId
    const index = cameras.map(camera => camera.id).indexOf(cameraId)
    if (cameras && cameras.length) {
      if (index > -1) {
        dispatch(updateCameraId(cameraId))
      } else {
        dispatch(updateCameraId(cameras[0].id))
      }
    }
  }
}

// actions
export const updateCameraId = (cameraId) => ({
  type: SETTINGS_UPDATE_CAMERA_ID,
  payload: { cameraId }
})

export const toggleCamera = () => ({
  type: SETTINGS_TOGGLE_CAMERA
})

export const toggleHeaterOpened = (heaterName) => ({
  type: SETTINGS_TOGGLE_HEATER_OPENED,
  heaterName
})

export const updateCameras = (cameras) => ({
  type: SETTINGS_UPDATE_CAMERAS,
  payload: { cameras }
})

export const updateHeaterP = (heaterName, value) => ({
  type: SETTINGS_UPDATE_HEATER_P,
  heaterName,
  param: 'p',
  value
})

export const updateHeaterI = (heaterName, value) => ({
  type: SETTINGS_UPDATE_HEATER_I,
  heaterName,
  param: 'i',
  value
})

export const updateHeaterD = (heaterName, value) => ({
  type: SETTINGS_UPDATE_HEATER_D,
  heaterName,
  param: 'd',
  value
})

export const updateHeaterGain = (heaterName, value) => ({
  type: SETTINGS_UPDATE_HEATER_GAIN,
  heaterName,
  value,
  param: 'gain'
})

export const updateHeaterOffset = (heaterName, value) => ({
  type: SETTINGS_UPDATE_HEATER_OFFSET,
  heaterName,
  value,
  param: 'offset'
})

const initialState = {
  cameras: [],
  cameraId: '',
  cameraOn: false,
  calibration: []
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SETTINGS_TOGGLE_CAMERA:
      return {
        ...state,
        cameraOn: !state.cameraOn
      }
    case SETTINGS_TOGGLE_HEATER_OPENED: {
      const index = state.calibration.map(heater => heater.name).indexOf(action.heaterName)

      return {
        ...state,
        calibration: [
          ...state.calibration.slice(0, index),
          {
            ...state.calibration[index],
            opened: !state.calibration[index].opened
          },
          ...state.calibration.slice(index + 1)
        ]
      }
    }
    case SETTINGS_UPDATE_HEATER_P:
    case SETTINGS_UPDATE_HEATER_I:
    case SETTINGS_UPDATE_HEATER_D:
    case SETTINGS_UPDATE_HEATER_GAIN:
    case SETTINGS_UPDATE_HEATER_OFFSET: {
      const indexH = state.calibration.map(heater => heater.name).indexOf(action.heaterName)

      return {
        ...state,
        calibration: [
          ...state.calibration.slice(0, indexH),
          {
            ...state.calibration[indexH],
            [action.param]: action.value
          },
          ...state.calibration.slice(indexH + 1)
        ]
      }
    }
    case SETTINGS_UPDATE_CAMERA_ID:
      apiClient.storage.set('cameraId', payload.cameraId)
      return {
        ...state,
        ...payload
      }
    case SETTINGS_UPDATE_CAMERAS:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
