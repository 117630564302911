import React from 'react'
import {
  Button,
  Modal,
  TextArea
} from '@miroculus/miroculus-ui'
import classNames from 'classnames/bind'
import styles from './FinishModal.scss'

const cx = classNames.bind(styles)

const FinishModal = ({
  onConfirmClick,
  onCancelClick,
  feedback,
  onFeedbackChange
}) => (
  <div className={cx('modalContainer')}>
    <Modal
      hideCloseButton
    >
      <div className={cx('content')}>
        <div className={cx('body')}>
          <h1 className={cx('mainMessage')}>If you finish the experiment, your progress will be lost. Do you want to continue?</h1>
          <h2 className={cx('secondaryMessage')}>Could you leave us some feedback?</h2>
          <div className={cx('feedbackSection')}>
            <TextArea
              placeholder='Tell us how was everything ...'
              value={feedback}
              onChange={(evt) => { onFeedbackChange(evt.target.value) }}
              maxLength={1000}
            />
          </div>
          <div className={cx('buttons')}>
            <div className={cx('button')}>
              <Button
                label='CANCEL'
                onClick={onCancelClick}
              />
            </div>
            <div className={cx('button')}>
              <Button
                label='FINISH'
                onClick={onConfirmClick}
                type='filled'
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
)

export default FinishModal
