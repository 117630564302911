import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatchOrganization } from 'hooks'
import { switchToOrganization } from 'reduxModules/organizations'

/**
 * Wrapper component that load current organization into the store
 */
const OrganizationLoader = memo(({ children }) => {
  const dispatch = useDispatch()
  const currentOrganizationSlug = useRouteMatchOrganization()

  useEffect(() => {
    if (currentOrganizationSlug) {
      dispatch(switchToOrganization(currentOrganizationSlug))
    }
  }, [])

  return children
})

export default OrganizationLoader
