// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeadingWithButton_headingWithButtonContainer__2fKLo{color:var(--color-primary);display:flex;flex-flow:row;justify-content:space-between;align-items:center}.HeadingWithButton_headingWithButtonContainer__2fKLo.HeadingWithButton_withButton__aulWg{margin-right:-2px}.HeadingWithButton_headingWithButtonContainer__2fKLo h1,.HeadingWithButton_headingWithButtonContainer__2fKLo h2{margin:0;text-transform:capitalize}.HeadingWithButton_headingWithButtonContainer__2fKLo .HeadingWithButton_headingButton__ovpMr{display:flex;justify-content:space-between;align-items:center}.HeadingWithButton_headingWithButtonContainer__2fKLo .HeadingWithButton_headingButton__ovpMr a:last-of-type,.HeadingWithButton_headingWithButtonContainer__2fKLo .HeadingWithButton_headingButton__ovpMr button:last-of-type{margin-left:2rem}.HeadingWithButton_headingWithButtonContainer__2fKLo .HeadingWithButton_headingButton__ovpMr button{margin-left:3rem}", ""]);
// Exports
exports.locals = {
	"headingWithButtonContainer": "HeadingWithButton_headingWithButtonContainer__2fKLo",
	"withButton": "HeadingWithButton_withButton__aulWg",
	"headingButton": "HeadingWithButton_headingButton__ovpMr"
};
module.exports = exports;
