// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FinishModal_modalContainer__1p6Go{position:fixed;top:0;left:0;width:100%;height:100%;z-index:10000}.FinishModal_content__2sIKk{display:flex;flex-direction:column;width:100%;height:100%}.FinishModal_body__1PH6G{padding:2rem;overflow:hidden;display:flex;flex-direction:column;flex-grow:1;align-items:center;justify-content:center}.FinishModal_secondaryMessage__1Vki7{margin:0;font-size:1rem;margin-bottom:2rem}.FinishModal_mainMessage__DRisv{font-size:1.5rem}.FinishModal_feedbackSection__3ZCsj{flex-basis:9rem;width:50rem}.FinishModal_buttons__1sKOK{display:flex;width:100%;justify-content:center;flex-basis:3rem;align-items:center;margin:3rem}.FinishModal_buttons__1sKOK .FinishModal_button__Dfhkd{flex-basis:10rem;margin:0 .75rem}", ""]);
// Exports
exports.locals = {
	"modalContainer": "FinishModal_modalContainer__1p6Go",
	"content": "FinishModal_content__2sIKk",
	"body": "FinishModal_body__1PH6G",
	"secondaryMessage": "FinishModal_secondaryMessage__1Vki7",
	"mainMessage": "FinishModal_mainMessage__DRisv",
	"feedbackSection": "FinishModal_feedbackSection__3ZCsj",
	"buttons": "FinishModal_buttons__1sKOK",
	"button": "FinishModal_button__Dfhkd"
};
module.exports = exports;
