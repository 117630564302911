// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ErrorModalContent_container__2JdG7{position:absolute;top:0;left:0;width:100%;height:100%}.ErrorModalContent_container__2JdG7 .ErrorModalContent_titleContainer__2vA4I{height:50%;background-color:#f57b23;display:flex;justify-content:center;align-items:center}.ErrorModalContent_container__2JdG7 .ErrorModalContent_titleContainer__2vA4I .ErrorModalContent_title__3nUiE{text-transform:uppercase;font-size:10rem;color:rgba(255,255,255,.4);font-weight:lighter}.ErrorModalContent_container__2JdG7 .ErrorModalContent_mainContainer__12A64{height:50%;display:flex;justify-content:center;align-items:flex-start;padding-top:3rem}.ErrorModalContent_container__2JdG7 .ErrorModalContent_mainContainer__12A64 .ErrorModalContent_explanation__3q7yF{text-align:center;font-size:.9rem;line-height:1.5}.ErrorModalContent_container__2JdG7 .ErrorModalContent_mainContainer__12A64 .ErrorModalContent_question__3eiw_{text-align:center;font-weight:bold;font-size:1.5rem}.ErrorModalContent_container__2JdG7 .ErrorModalContent_mainContainer__12A64 .ErrorModalContent_buttonsContainer__1c18B{display:flex}.ErrorModalContent_container__2JdG7 .ErrorModalContent_mainContainer__12A64 .ErrorModalContent_buttonsContainer__1c18B button{margin:.5rem}", ""]);
// Exports
exports.locals = {
	"container": "ErrorModalContent_container__2JdG7",
	"titleContainer": "ErrorModalContent_titleContainer__2vA4I",
	"title": "ErrorModalContent_title__3nUiE",
	"mainContainer": "ErrorModalContent_mainContainer__12A64",
	"explanation": "ErrorModalContent_explanation__3q7yF",
	"question": "ErrorModalContent_question__3eiw_",
	"buttonsContainer": "ErrorModalContent_buttonsContainer__1c18B"
};
module.exports = exports;
