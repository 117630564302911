import React from 'react'
import { getFormattedDate } from 'utils'
import { IconButton } from '@miroculus/nucleo'
import { AiOutlineEdit } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { instrumentRunDetailsUrl } from 'cons/routes'

import classNames from 'classnames/bind'
import styles from './RunsList.scss'

const cx = classNames.bind(styles)

const RunsList = ({ runs = [], orgSlug, deviceId }) => {
  return (
    <table className={cx('table')}>
      <thead>
        <tr className={cx('header')}>
          <th>Protocol</th>
          <th>Version</th>
          <th>Completion</th>
          <th>Recovery</th>
          <th>User</th>
          <th>Cartridge</th>
          <th>Date</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {runs.map(({
          protocol,
          status,
          recoveryStatus,
          startedAt,
          user,
          cartridge,
          id
        }) => (
          <tr key={id} className={cx('entry')}>
            <td className={cx('protocolName')}>{protocol.name}</td>
            <td>{protocol.version}</td>
            <td>{status}</td>
            <td>{recoveryStatus}</td>
            <td>{user.name}</td>
            <td>{cartridge.type}</td>
            <td>{getFormattedDate(startedAt)}</td>
            <td>
              <Link to={instrumentRunDetailsUrl(orgSlug, deviceId, id)} onClick={e => e.stopPropagation()}>
                <IconButton label='Edit'>
                  <AiOutlineEdit size='1.5em' />
                </IconButton>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

RunsList.propTypes = {}

export default RunsList
