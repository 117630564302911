import apiClient from '@miroculus/api-client'
import {
  handleMessage,
  handleAddOrUpdateDevice,
  handleUpdateDevices,
  handleProtocolsUpdate
} from 'remoteMessage'

class SocketHandler {
  deviceId = null
  connected = false

  connect = () => {
    if (this.connected) return Promise.resolve()
    this.connected = true

    apiClient.socket.on('connect', () => {
      console.log('Socket is now connected!')

      apiClient.socket.on('deviceUpdate', (device) => {
        handleAddOrUpdateDevice(device)
      })

      apiClient.socket.on('device', ({ payload }) => {
        handleMessage(payload)
      })

      apiClient.socket.on('protocols', (updatedProtocols) => {
        const protocols = updatedProtocols.map(protocol => ({
          ...protocol,
          cartridge: {
            label: protocol.cartridge.name,
            id: protocol.cartridge.id
          }
        }))
        handleProtocolsUpdate(protocols)
      })
    })

    return apiClient.socket.connect()
  }

  disconnect = () => {
    if (!this.connected) return
    this.connected = false
    apiClient.socket.disconnect()
  }

  updateDevices = (devices) => {
    handleUpdateDevices(devices)
  }

  async sendMessage (message) {
    try {
      return await apiClient.socket.post('/chat/public', {
        deviceId: this.deviceId,
        msg: JSON.stringify(message)
      })
    } catch (e) {
      throw new Error(`Error trying to send message: ${e}`)
    }
  }

  _subscribe (subscribeFn, ...args) {
    apiClient.socket.on('connect', () => { subscribeFn(...args) })
    return subscribeFn(...args)
  }

  _subscribeToDevice = async (deviceId) => {
    this.deviceId = deviceId
    try {
      return await apiClient.socket.get(`/devices/subscribe/${deviceId}`)
        .then(({ body }) => body)
    } catch (e) {
      throw new Error(`Error trying to subscribe to device ${deviceId}: ${e}`)
    }
  }

  subscribeToDevice (deviceId) {
    return this._subscribe(this._subscribeToDevice, deviceId)
  }

  async unsubscribeToDevice (deviceId) {
    if (!this.connected) return
    try {
      return await apiClient.socket.get(`/devices/unsubscribe/${deviceId}`)
    } catch (e) {
      throw new Error(`Error trying to unsubscribe to device ${deviceId}: ${e}`)
    }
  }

  _subscribeToProtocols = async (teamId, visibility) => {
    try {
      return await apiClient.socket.get('/protocols/subscribe', { teamId, visibility })
    } catch (e) {
      throw new Error(`Error trying to subscribe to protocols: ${e}`)
    }
  }

  subscribeToProtocols (teamId, visibility) {
    return this._subscribe(this._subscribeToProtocols, teamId, visibility)
  }

  async unsubscribeToProtocols (teamId, visibility) {
    if (!this.connected) return
    try {
      return await apiClient.socket.del('/protocols/subscribe', { teamId, visibility })
    } catch (e) {
      throw new Error(`Error trying to unsubscribe to protocols: ${e}`)
    }
  }

  prepareProtocol (graph, protocolId) {
    return apiClient.socket.post('/protocols/prepare', { graph, protocolId })
  }

  _subscribeToDevices = async (organization) => {
    // Get the current list of devices. This will also subscribe us to
    // update and destroy events for the devices.
    try {
      const { body } = await apiClient.socket.get(
        '/devices/subscribe',
        { organization }
      )
      this.updateDevices(body)
    } catch (e) {
      throw new Error(`Error trying to subscribe to devices: ${e}`)
    }
  }

  subscribeToDevices (organizationId) {
    return this._subscribe(this._subscribeToDevices, organizationId)
  }

  async unsubscribeToDevices (organization) {
    if (!this.connected) return
    try {
      await apiClient.socket.get('/devices/unsubscribe', { organization })
    } catch (e) {
      throw new Error(`Error trying to unsubscribe to devices: ${e}`)
    }
  }
}

export default (function () {
  let socketHandler = null

  function init () {
    if (socketHandler === null) {
      socketHandler = new SocketHandler()
    }
  }

  return {
    init,
    connect: () => {
      return socketHandler.connect()
    },
    disconnect: () => {
      return socketHandler.disconnect()
    },
    sendMessage: (data, user) => {
      if (socketHandler) socketHandler.sendMessage(data, user)
    },
    subscribeToDevice: (device) => {
      if (socketHandler) return socketHandler.subscribeToDevice(device)
    },
    unsubscribeToDevice: (id) => {
      if (socketHandler) socketHandler.unsubscribeToDevice(id)
    },
    subscribeToProtocols: async (teamId, visibility = 'shared') => {
      if (socketHandler) {
        const { body } = await socketHandler.subscribeToProtocols(teamId, visibility)
        return body
      }
    },
    unsubscribeToProtocols: async (teamId, visibility = 'shared') => {
      if (socketHandler) await socketHandler.unsubscribeToProtocols(teamId, visibility)
    },
    prepareProtocol: (graph, protocolId) => {
      if (socketHandler) return socketHandler.prepareProtocol(graph, protocolId)
    },
    subscribeToDevices: async (organizationId) => {
      if (socketHandler) await socketHandler.subscribeToDevices(organizationId)
    },
    unsubscribeToDevices: async (organizationId) => {
      if (socketHandler) await socketHandler.unsubscribeToDevices(organizationId)
    }
  }
})()
