export const INIT_RUNS = 'anaconda-web/data/INIT_RUNS'
export const DATA_SET_SOURCES_TEMP = 'anaconda-web/data/DATA_SET_SOURCES_TEMP'
export const DATA_ADD_VALUE = 'anaconda-web/data/DATA_ADD_VALUE'
export const DATA_ADD_VALUES = 'anaconda-web/data/DATA_ADD_VALUES'
export const DATA_TOGGLE_TRACK = 'anaconda-web/data/DATA_TOGGLE_TRACK'
export const DATA_TOGGLE_VARIABLE = 'anaconda-web/data/DATA_TOGGLE_VARIABLE'
export const DATA_RESET_TEMP_DATA = 'anaconda-web/data/DATA_RESET_TEMP_DATA'
export const DATA_SET_HV = 'anaconda-web/data/DATA_SET_HV'

export function initRuns () {
  return {
    type: INIT_RUNS
  }
}

export function setTempSources (sources) {
  return {
    type: DATA_SET_SOURCES_TEMP,
    sources: sources
  }
}

export function resetTempData (sources) {
  return {
    type: DATA_RESET_TEMP_DATA
  }
}

export function toggleVariable (source, variable) {
  return {
    type: DATA_TOGGLE_VARIABLE,
    source: source,
    variable: variable
  }
}

export function addValue (source, cocoscriptStatus, experiment, variable, value) {
  return {
    type: DATA_ADD_VALUE,
    source: source,
    variable: variable,
    value: value,
    experiment: experiment,
    cocoscriptStatus: cocoscriptStatus
  }
}

export function addValues (source, cocoscriptStatus, experiment, variables) {
  return {
    type: DATA_ADD_VALUES,
    source: source,
    variables: variables,
    experiment: experiment,
    cocoscriptStatus: cocoscriptStatus
  }
}

export function toggleTracking (source) {
  return {
    type: DATA_TOGGLE_TRACK,
    source: source
  }
}

export const setHV = (hv) => ({
  type: DATA_SET_HV, hv
})
