import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './ErrorButton.scss'

const cx = classNames.bind(styles)

const ErrorButton = ({ errorCount, onClick }) => (
  <button
    className={cx('base')}
    onClick={onClick}
  >
    !
    {errorCount > 0 &&
      <div className={cx('badge')}>
        {errorCount}
      </div>}
  </button>
)

ErrorButton.propTypes = {
  errorCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ErrorButton
