// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProtocolInfoPanel_container__1lWRt{background-color:var(--color-page-background);border-left:1px solid var(--color-input-border);height:100%;display:flex;flex-direction:column;width:28.75rem}.ProtocolInfoPanel_container__1lWRt section{padding:1.5rem 2rem}.ProtocolInfoPanel_container__1lWRt section:not(:last-child){border-bottom:1px solid var(--color-input-border)}.ProtocolInfoPanel_container__1lWRt label{font-size:var(--font-size-small);font-weight:500;letter-spacing:.05em}.ProtocolInfoPanel_container__1lWRt p{color:var(--color-title)}.ProtocolInfoPanel_main-info__27KgZ h2{color:var(--color-primary);font-size:var(--font-size-x4large);margin:.5em 0 1em;letter-spacing:.01em}.ProtocolInfoPanel_main-info__27KgZ p{margin:.5em 0 0;font-size:var(--font-size-large);font-weight:600}.ProtocolInfoPanel_two-columns__1aA0K{-moz-columns:2;columns:2}.ProtocolInfoPanel_description__4kg4n{flex:1}.ProtocolInfoPanel_description__4kg4n p{font-size:var(--font-size-small);line-height:1.4}.ProtocolInfoPanel_reagents__3DrIg ul{list-style:none;margin:0;padding:0;display:flex;flex-flow:row wrap;justify-content:space-between}.ProtocolInfoPanel_reagents__3DrIg ul li{padding-top:.5rem}.ProtocolInfoPanel_buttons__1mSAH{display:flex;flex-flow:row wrap;gap:1.5rem}.ProtocolInfoPanel_buttons__1mSAH>*{width:calc(50% - .75rem)}.ProtocolInfoPanel_buttons__1mSAH svg{height:1.5em;margin:-0.45em 0}", ""]);
// Exports
exports.locals = {
	"container": "ProtocolInfoPanel_container__1lWRt",
	"main-info": "ProtocolInfoPanel_main-info__27KgZ",
	"two-columns": "ProtocolInfoPanel_two-columns__1aA0K",
	"description": "ProtocolInfoPanel_description__4kg4n",
	"reagents": "ProtocolInfoPanel_reagents__3DrIg",
	"buttons": "ProtocolInfoPanel_buttons__1mSAH"
};
module.exports = exports;
