import apiClient from '@miroculus/api-client'
import * as Sentry from '@sentry/browser'

const UPDATE_WORKSPACES = 'app/workspaces/UPDATE_WORKSPACES'
const UPDATE_MEMBERS = 'app/workspaces/UPDATE_MEMBERS'
const UPDATE_LOADING_WORKSPACES = 'app/workspaces/UPDATE_LOADING_WORKSPACES'
const UPDATE_LOADING_MEMBERS = 'app/workspaces/UPDATE_LOADING_MEMBERS'
const UPDATE_CREATING_MEMBER = 'app/workspaces/UPDATE_CREATING_MEMBER'
const UPDATE_LOADING_DEVICES = 'app/workspaces/UPDATE_LOADING_DEVICES'
const UPDATE_DEVICES = 'app/workspaces/UPDATE_DEVICES'
const UPDATE_REGISTERING_DEVICE = 'app/workspaces/UPDATE_REGISTERING_DEVICE'

const WORKSPACE_ORDER_ITEM = 'workspace-order'

export const MEMBER_ROLES = {
  admin: 'Admin',
  collaborator: 'Collaborator'
}

// bound action creators
export const loadWorkspaceMembers = () => async (dispatch) => {
  dispatch(updateLoadingMembers(true))
  try {
    const { body } = await apiClient.get('/team-members')
    const members = body.map(({ id, role, user, active }) => ({
      id,
      role,
      active,
      name: user.name,
      email: user.email
    }))
    dispatch(updateMembers(members))
  } catch (e) {
    Sentry.captureException(e)
  }
  dispatch(updateLoadingMembers(false))
}

export const updateWorkspaceMember = ({ id, ...values }) => async (dispatch, getState) => {
  const { members } = getState().workspaces
  const memberIndex = members.findIndex(m => m.id === id)
  await apiClient.post(`/team-members/${id}`, values)
  dispatch(updateMembers([
    ...members.slice(0, memberIndex),
    {
      ...members[memberIndex],
      ...values
    },
    ...members.slice(memberIndex + 1)
  ]))
}

export const updateWorkspaceOrder = (workspaces) => (dispatch, getState) => {
  apiClient.storage.set(
    WORKSPACE_ORDER_ITEM,
    workspaces.map(({ id }) => id)
  )
  // call UPDATE_WORKSPACES action to reorder the store state
  dispatch(updateWorkspaces(getState().workspaces.workspaces))
}

// actions
export const updateWorkspaces = (workspaces) => ({
  type: UPDATE_WORKSPACES,
  payload: { workspaces }
})

export const updateLoadingWorkspaces = (loadingWorkspaces) => ({
  type: UPDATE_LOADING_WORKSPACES,
  payload: { loadingWorkspaces }
})

export const updateMembers = (members) => ({
  type: UPDATE_MEMBERS,
  payload: { members }
})

export const updateLoadingMembers = (loadingMembers) => ({
  type: UPDATE_LOADING_MEMBERS,
  payload: { loadingMembers }
})

export const updateCreatingMember = (creatingMember) => ({
  type: UPDATE_CREATING_MEMBER,
  payload: { creatingMember }
})

export const updateLoadingDevices = (loadingDevices) => ({
  type: UPDATE_LOADING_DEVICES,
  payload: { loadingDevices }
})

export const updateDevices = (devices) => ({
  type: UPDATE_DEVICES,
  payload: { devices }
})

export const updateRegisteringDevice = (registeringDevice) => ({
  type: UPDATE_REGISTERING_DEVICE,
  payload: { registeringDevice }
})

const initialState = {
  currentWorkspace: null,
  loadingMembers: false,
  creatingMember: false,
  devices: [],
  registeringDevice: false,
  loadingDevices: false,
  members: [],
  loadingWorkspaces: false,
  workspaces: []
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_WORKSPACES: {
      const { workspaces } = payload
      const workspaceOrder = apiClient.storage.get(WORKSPACE_ORDER_ITEM)
      if (workspaceOrder) {
        // workspace list may have changed from the one stored,
        // so we first get the available ordered workspaces
        const orderedWorkspaces = workspaceOrder
          .map(id => workspaces.find(w => w.id === id))
          .filter(w => !!w)
        // then we get extra workspaces that the user may not have already ordered
        const extraWorkspaces = workspaces
          .filter(w => !workspaceOrder.includes(w.id))
        // then we concatenate both lists
        return {
          ...state,
          workspaces: [...orderedWorkspaces, ...extraWorkspaces]
        }
      }
      return {
        ...state,
        ...payload
      }
    }
    case UPDATE_MEMBERS:
    case UPDATE_LOADING_WORKSPACES:
    case UPDATE_LOADING_MEMBERS:
    case UPDATE_CREATING_MEMBER:
    case UPDATE_REGISTERING_DEVICE:
    case UPDATE_DEVICES:
    case UPDATE_LOADING_DEVICES:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
