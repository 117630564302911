import React, { useCallback, useState } from 'react'
import classnames from 'classnames/bind'
import styles from './Collapsible.scss'
import { useClientRect } from 'hooks'

const cx = classnames.bind(styles)

const Collapsible = ({
  header,
  children
}) => {
  const [show, toggle] = useState(true)
  const handleClick = useCallback(() => toggle(!show), [show, toggle])
  const [ref, { height }] = useClientRect([children])

  return (
    <>
      <div className={cx('header')} onClick={handleClick}>
        {header(show)}
      </div>
      <div
        className={cx('content', { show })}
        style={{ height: show ? height : 0 }}
      >
        <div ref={ref}>
          {children}
        </div>
      </div>
    </>
  )
}

export default Collapsible
