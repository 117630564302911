// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Spinner_overlay__1KCDQ{position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.4);display:flex;justify-content:center;align-items:center}.Spinner_container__2_LtS{width:6rem;height:6rem}", ""]);
// Exports
exports.locals = {
	"overlay": "Spinner_overlay__1KCDQ",
	"container": "Spinner_container__2_LtS"
};
module.exports = exports;
