import { apply, curry } from 'ramda'

export const canSubmit = (formik) => Object.values(formik.values)
  .every(fieldValue => !!fieldValue)

export const getFirstError = (formik) => Object.keys(formik.errors)
  .map(formik.getFieldMeta)
  .find(m => m.touched && m.error)?.error

export const getFieldError = (formik, field) =>
  formik.touched[field] && formik.errors[field]

/**
 * Debounce function (credits to https://gist.github.com/tommmyy/daf61103d6022cd23d74c71b0e8adc0d)
 *
 * @param {Boolean} immediate If true run `fn` at the start of the timeout
 * @param  timeMs {Number} Debounce timeout
 * @param  fn {Function} Function to debounce
 *
 * @return {Number} timeout
 * @example
 *
 *   const say = (x) => console.log(x)
 *   const debouncedSay = debounce_(false, 1000, say)();
 *
 *   debouncedSay("1")
 *   debouncedSay("2")
 *   debouncedSay("3")
 *
 */
export const debounce = curry((immediate, timeMs, fn) => {
  let timeout

  return (...args) => {
    const later = () => {
      timeout = null

      if (!immediate) {
        apply(fn, args)
      }
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)
    timeout = setTimeout(later, timeMs)

    if (callNow) {
      apply(fn, args)
    }

    return timeout
  }
})
