import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  DraftsIcon,
  CogIcon,
  MiroculusIcon,
  UsersIcon,
  Link,
  ArrowRightIcon
} from '@miroculus/nucleo'
import {
  switchToOrganization
} from 'reduxModules/organizations'
import {
  getCurrentOrganization, getLoadingOrganizations
} from 'reduxModules/organizations/selectors'
import { updateLoading } from 'reduxModules/devices'
import { teamUrl, instrumentsUrl, organizationSettingsUrl } from 'cons/routes'
import {
  HeadingWithButton,
  LoadingWithText,
  InstrumentCard,
  useBreadcrumb
} from 'components'
import socketClient from 'socketClient'
import classnames from 'classnames/bind'
import styles from './Organization.scss'

const cx = classnames.bind(styles)

const TeamCard = ({ id, name, memberCount, instrumentCount, protocolCount }) => {
  return (
    <li>
      <Link to={teamUrl(id)} className={cx('team-card')}>
        <h3>{name}</h3>
        <div className={cx('team-card_content')}>
          <ul>
            <li><UsersIcon />{`${memberCount} Members`}</li>
            <li><MiroculusIcon />{`${instrumentCount} Instruments`}</li>
            <li><DraftsIcon />{`${protocolCount} Protocols`}</li>
          </ul>
        </div>
      </Link>
    </li>
  )
}

TeamCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  memberCount: PropTypes.number.isRequired,
  instrumentCount: PropTypes.number.isRequired,
  protocolCount: PropTypes.number.isRequired
}

export const Organization = ({
  currentOrganization,
  onOrganizationChange,
  subscribeToDevices,
  unsubscribeFromDevices,
  loading
}) => {
  const {
    devices: instruments = [],
    teams = [],
    $permissions = {}
  } = currentOrganization
  const { orgSlug } = useParams()
  const { canEditOrganization } = $permissions

  useEffect(() => {
    onOrganizationChange(orgSlug)
    subscribeToDevices(orgSlug)
    return () => {
      unsubscribeFromDevices(orgSlug)
    }
  }, [orgSlug])

  useBreadcrumb(() => [{
    text: currentOrganization?.name
  }], [currentOrganization])

  const protocolCount = teams.reduce((sum, team) => sum + team.$protocolCount, 0)

  const renderQuickInfo = () => {
    return (
      <div className={cx('quick-info')}>
        <ul>
          <li><UsersIcon />{currentOrganization.$memberCount}</li>
          <li><MiroculusIcon />{instruments.length}</li>
          <li><DraftsIcon />{protocolCount}</li>
        </ul>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <section key='organization-header' className={cx('container--header')}>
        <HeadingWithButton title={currentOrganization.name} titleAnnex={renderQuickInfo()} />
        <div className={cx('heading-options')}>
          <h3>ORG ID: {orgSlug}</h3>
          {canEditOrganization && (
            <Link
              to={organizationSettingsUrl(orgSlug)}
              data-testid={`org-settings-button-${orgSlug}`}
            >
              <CogIcon /> Admin Settings
            </Link>
          )}
        </div>
      </section>
    )
  }

  const instrumentsToRender = instruments.slice(0, 3)
  const renderInstruments = () => (
    <section key='organization-instruments' className={cx('container--instruments')}>
      <div className={cx('heading-options')}>
        <h2 className={cx('title')}>Instruments</h2>
        {instrumentsToRender.length > 0 && (
          <Link
            to={instrumentsUrl(orgSlug)}
            data-testid={`all-instruments-button-${orgSlug}`}
          >
            All Instruments <ArrowRightIcon />
          </Link>
        )}
      </div>
      {!instrumentsToRender?.length
        ? (
          <p className={cx('no-data-message')}>
            {canEditOrganization
              ? 'You don\'t have any instruments assigned to this organization yet, you can do so from the instrument screen, Settings >  Connect to Organization and enter the ORG ID shown above'
              : 'You don\'t have any instruments yet, please contact the Organization admin'}
          </p>)
        : (
          <ul>
            {instrumentsToRender?.map(({
              id,
              givenName,
              connected,
              status,
              workspace: organization,
              startedAt
            }) => (
              <InstrumentCard
                data-testid='instrument-card'
                key={id}
                givenName={givenName}
                id={id}
                connected={connected}
                status={status}
                organizationName={organization}
                organizationSlug={orgSlug}
                startedAt={startedAt}
                size='small'
              />
            ))}
          </ul>)}
    </section>
  )

  const renderTeams = () => (
    <section key='organization-teams' className={cx('container--teams')}>
      <h2 className={cx('title')}>Teams</h2>
      {!teams?.length
        ? (
          <p className={cx('no-data-message')}>
            {canEditOrganization
              ? 'You don\'t have any teams yet, please create one from Admin Settings > Teams'
              : 'You don\'t have any teams yet, please contact the Organization admin'}
          </p>)
        : (
          <ul>
            {teams?.map((teamInfo) => (
              <TeamCard
                key={teamInfo.id}
                id={teamInfo.id}
                name={teamInfo.title}
                memberCount={teamInfo.$memberCount ?? 0}
                instrumentCount={teamInfo.$deviceCount ?? 0}
                protocolCount={teamInfo.$protocolCount ?? 0}
              />
            ))}
          </ul>)}
    </section>
  )

  return (
    <div className={cx('container')}>
      {loading && <LoadingWithText message='Loading organization...' />}
      {!loading && [
        renderHeader(),
        renderInstruments(),
        renderTeams()
      ]}
    </div>
  )
}

Organization.propTypes = {
  currentOrganization: PropTypes.shape({
    name: PropTypes.string,
    $permissions: PropTypes.shape({
      canEditOrganization: PropTypes.bool.isRequired
    }).isRequired,
    $memberCount: PropTypes.number.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      releaseChannel: PropTypes.string,
      $protocolCount: PropTypes.number,
      $memberCount: PropTypes.number,
      $deviceCount: PropTypes.number
    })).isRequired,
    devices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      givenName: PropTypes.string,
      status: PropTypes.string
    })).isRequired
  }),
  onOrganizationChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

Organization.defaultProps = {
  currentOrganization: {}
}

const mapStateToProps = (state) => {
  const currentOrganization = getCurrentOrganization(state)
  const isLoading = getLoadingOrganizations(state)
  return {
    currentOrganization,
    loading: isLoading
  }
}

const mapDispatchToProps = (dispatch) => ({
  onOrganizationChange: (organizationSlug) => {
    dispatch(switchToOrganization(organizationSlug))
  },
  subscribeToDevices: async (orgSlug) => {
    try {
      await dispatch(updateLoading(true))
      await socketClient.subscribeToDevices(orgSlug)
    } finally {
      await dispatch(updateLoading(false))
    }
  },
  unsubscribeFromDevices: async (orgSlug) => {
    await socketClient.unsubscribeToDevices(orgSlug)
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(Organization)
