// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Members_arrowTitle__1h5hQ{font-weight:600;font-size:var(--font-size-large);text-transform:uppercase;color:var(--color-text);cursor:pointer;transition:opacity ease-out .5s;opacity:1}.Members_arrowTitle__1h5hQ:hover{opacity:.6}.Members_arrowTitle__1h5hQ .Members_arrow__1iT2w{display:inline-block;font-size:inherit;transition:transform ease .2s}.Members_arrowTitle__1h5hQ .Members_arrow__1iT2w.Members_show__20ren{transform:translateX(0.75rem)}.Members_arrowTitle__1h5hQ .Members_arrow__1iT2w:not(.Members_show__20ren){transform:translateX(0.75rem) rotate(180deg)}.Members_container__3rwcl{padding:2rem;position:relative;height:100%;width:100%;overflow-y:auto}.Members_container__3rwcl .Members_avatar-name__35Ktn:first-of-type{display:flex;align-items:center}.Members_container__3rwcl .Members_avatar-name__35Ktn p{margin:0}.Members_container__3rwcl .Members_avatar-name__35Ktn .Members_avatar__1tr3t{width:50px;height:50px;background:currentColor;border-radius:50%;margin-right:1rem}.Members_container__3rwcl .Members_avatar-name__35Ktn .Members_name__37ECG{font-size:var(--font-size-x4large);font-weight:bold;color:var(--color-title)}.Members_container__3rwcl .Members_avatar-name__35Ktn .Members_email__t2n_d{font-size:var(--font-size-small)}.Members_title__2WzXn{color:var(--color-primary);display:flex;flex-flow:row;justify-content:space-between;align-items:center;margin-right:-2px}.Members_title__2WzXn h1{margin:0}", ""]);
// Exports
exports.locals = {
	"arrowTitle": "Members_arrowTitle__1h5hQ",
	"arrow": "Members_arrow__1iT2w",
	"show": "Members_show__20ren",
	"container": "Members_container__3rwcl",
	"avatar-name": "Members_avatar-name__35Ktn",
	"avatar": "Members_avatar__1tr3t",
	"name": "Members_name__37ECG",
	"email": "Members_email__t2n_d",
	"title": "Members_title__2WzXn"
};
module.exports = exports;
