import React, { memo } from 'react'
import classnames from 'classnames/bind'
import { defaultTheme } from '@miroculus/nucleo'
import { miroPalette } from 'images'
import styles from './AuthPage.scss'

const cx = classnames.bind(styles)

const ThreeBubbles = () => (
  <svg width={180} height={40}>
    <circle r={20} cx={20} cy={20} fill={defaultTheme.colorBubble1} />
    <circle r={20} cx={90} cy={20} fill={defaultTheme.colorBubble2} />
    <circle r={20} cx={160} cy={20} fill={defaultTheme.colorBubble3} />
  </svg>
)

const AuthPage = memo(({
  children,
  additionalInfo,
  ssoButton,
  footerText
}) => (
  <div className={cx('container')}>
    <main>
      <img src={miroPalette} alt='Miro Palette' />
      {additionalInfo && (
        <p className={cx('additionalInfo')}>{additionalInfo}</p>
      )}
      {ssoButton && (
        <>
          {ssoButton}
          <p className={cx('or')}>or</p>
        </>
      )}
      <section className={cx('form-container')}>
        {children}
      </section>
      <footer>
        {footerText}
      </footer>
    </main>
    <aside>
      <h1>Science Simplified</h1>
      <ThreeBubbles />
      <p>By putting intuitive tools in the hands of people everywhere, we can advance science and improve lives faster, together.</p>
    </aside>
  </div>
))

export default AuthPage
