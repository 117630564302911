// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormField_field__1lco2 label{display:block;margin-top:2rem}.FormField_field__1lco2 label>p{margin:0;margin-left:.25rem;margin-bottom:.5rem;text-align:left;text-transform:inherit;font-size:var(--font-size-small);font-weight:500}", ""]);
// Exports
exports.locals = {
	"field": "FormField_field__1lco2"
};
module.exports = exports;
