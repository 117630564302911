import { DEVICE_STATUS_BUSY } from 'cons'

export const currentDeviceSelector = ({ devices }) =>
  devices.devices.find((device) => device.id === devices.selectedDeviceId)

const isOnline = (device) => !!device.connected
const isStatusBusy = (device) => device.status === DEVICE_STATUS_BUSY
const hasExperiment = (device) =>
  isOnline(device) && isStatusBusy(device) && device.experiment

const DEFAULT_STATUS = 'Offline'

export const normalizeDevice = (device) => ({
  id: device.id,
  givenName: device.givenName || device.id,
  cartridge: device.cartridge,
  by:
    hasExperiment(device) && device.experiment.user
      ? device.experiment.user.name
      : '',
  status: device.connected
    ? device.status || DEFAULT_STATUS
    : DEFAULT_STATUS,
  startedAt: hasExperiment(device)
    ? device.experiment.startedAt
    : undefined,
  disabled: false,
  firmware: device.firmware,
  software: device.build,
  anacondaPi: device.anacondaPi,
  experiment:
    hasExperiment(device) && device.experiment.project
      ? device.experiment.project.name
      : undefined,
  connected: !!device.connected,
  ready: !!device.ready,
  workspace: device.workspace
})

export function sortDevices (a, b) {
  if (a.status !== b.status) {
    return a.status < b.status ? -1 : 1
  }
  return a.givenName.localeCompare(b.givenName)
}

export const devicesSelector = ({ devices }) =>
  devices.devices
    .map(normalizeDevice)
    .sort(sortDevices)

export const currentProjectSelector = (state) => {
  const {
    experiment: { projects, selectedProjectId }
  } = state

  return projects.find((project) => project.id === selectedProjectId)
}
