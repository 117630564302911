import {
  addOrUpdateDevice,
  onMessage,
  updateDevices
} from 'reduxModules/devices'
import { updateProtocols } from 'reduxModules/protocols'

class RemoteMessage {
  constructor (store) {
    this.dispatch = store.dispatch
  }

  handle (message) { this.dispatch(onMessage(message)) }
}

let instance = null
const devicesUpdateTimeouts = {}

export function initMessage (store) {
  if (!store) return

  if (instance === null) {
    instance = new RemoteMessage(store)
  }
}

export function clearMessage () {
  instance = null
}

export function handleMessage (data) {
  if (!instance) throw new Error('Message need to be initialized')
  instance.handle(data)
}

export function handleAddOrUpdateDevice (device) {
  if (!instance) throw new Error('Message need to be initialized')
  if (devicesUpdateTimeouts[device.id]) clearTimeout(devicesUpdateTimeouts[device.id])
  devicesUpdateTimeouts[device.id] = setTimeout(() => {
    instance.dispatch(addOrUpdateDevice(device))
  }, 1000)
}

export function handleProtocolsUpdate (protocols) {
  if (!instance) throw new Error('Message need to be initialized')
  instance.dispatch(updateProtocols(protocols))
}

export function handleUpdateDevices (devices) {
  if (!instance) throw new Error('Message need to be initialized')
  instance.dispatch(updateDevices(devices))
}
