// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RunsList_table__li0qZ{width:100%}.RunsList_header__2IXmN th{border-bottom:1px solid var(--color-neutral-350);padding:1.5rem 0;text-align:left;font-weight:700;letter-spacing:1px;text-transform:uppercase;color:var(--color-control-text)}.RunsList_entry__3jr4i td{border-bottom:1px solid var(--color-neutral-350);padding:1.5rem 0;color:var(--color-control-text);font-weight:600}.RunsList_entry__3jr4i td.RunsList_protocolName__2EGJh{color:var(--color-neutral-150);font-size:var(--font-size-xlarge)}", ""]);
// Exports
exports.locals = {
	"table": "RunsList_table__li0qZ",
	"header": "RunsList_header__2IXmN",
	"entry": "RunsList_entry__3jr4i",
	"protocolName": "RunsList_protocolName__2EGJh"
};
module.exports = exports;
