import PropTypes from 'prop-types'

const teamShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  releaseChannel: PropTypes.string,
  permissions: PropTypes.shape({
    canAccessAnaconda: PropTypes.bool,
    canCreateProtocol: PropTypes.bool,
    canEditProtocol: PropTypes.bool,
    canEditWorkspace: PropTypes.bool,
    canSendProtocol: PropTypes.bool
  })
})

export default teamShape
