import React from 'react'
import classnames from 'classnames/bind'
import { ProtocolCard } from '@miroculus/nucleo'
import { protocolShape } from 'components'
import PropTypes from 'prop-types'
import {
  protocolUrl,
  editProtocolUrl,
  copyProtocolUrl,
  protocolRouteUrl,
  sendProtocolUrl
} from 'cons/routes'
import styles from './ProtocolList.scss'

const cx = classnames.bind(styles)

const ProtocolList = ({ protocols, team, onDeleteClick }) =>
  <ul className={cx('protocolList')}>
    {protocols.map((protocol) => {
      const teamId = team ?? protocol.team
      return (
        <li key={protocol.id}>
          <ProtocolCard
            id={protocol.id}
            name={protocol.name}
            author={protocol.author.name}
            status={protocol.solutionStatus || 'pending'}
            viewUrl={protocolUrl(teamId, protocol.id)}
            editUrl={protocol.canEdit && editProtocolUrl(teamId, protocol.id)}
            routeUrl={protocol.canSeeRoutes && protocolRouteUrl(teamId, protocol.id)}
            copyUrl={protocol.canCopy && copyProtocolUrl(teamId, protocol.id)}
            onDelete={protocol.canEdit && onDeleteClick}
            sendUrl={protocol.canSend && sendProtocolUrl(teamId, protocol.id)}
            version={protocol.version}
            cartridge={protocol.cartridge}
            original={protocol.original}
          />
        </li>
      )
    })}
  </ul>

ProtocolList.propTypes = {
  protocols: PropTypes.arrayOf(protocolShape).isRequired,
  // if passed a team, it will override the protocol.team for the urls
  team: PropTypes.number,
  onDeleteClick: PropTypes.func
}

export default ProtocolList
