// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SendProtocolModal_modal-content__nSxdq h3{margin-top:2rem;color:var(--color-primary);text-transform:uppercase}.SendProtocolModal_modal-content__nSxdq button{margin-top:2rem}.SendProtocolModal_modal-content__nSxdq .SendProtocolModal_device-name__2fvzS{position:relative;margin-bottom:3rem}.SendProtocolModal_modal-content__nSxdq .SendProtocolModal_device-name__2fvzS label{margin-top:0}.SendProtocolModal_modal-content__nSxdq .SendProtocolModal_device-name__2fvzS .SendProtocolModal_select__3roTV{position:absolute;width:100%;height:18rem;pointer-events:none}.SendProtocolModal_modal-content__nSxdq .SendProtocolModal_device-name__2fvzS .SendProtocolModal_select__3roTV>*{pointer-events:initial}", ""]);
// Exports
exports.locals = {
	"modal-content": "SendProtocolModal_modal-content__nSxdq",
	"device-name": "SendProtocolModal_device-name__2fvzS",
	"select": "SendProtocolModal_select__3roTV"
};
module.exports = exports;
