// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CodeEditor_container__rk6SI{overflow:auto;visibility:hidden;height:100%}.CodeEditor_container__rk6SI:hover{visibility:visible}.CodeEditor_container__rk6SI:focus{visibility:visible}.CodeEditor_container__rk6SI>div{visibility:visible;height:100%}.CodeEditor_styledBackground__2yFth{background-color:#66c}.CodeEditor_pausedBackground__20bON{background-color:#e1c47e}.CodeEditor_errorBackground__3ANMY{background-color:#c66}.CodeMirror{height:100%}", ""]);
// Exports
exports.locals = {
	"container": "CodeEditor_container__rk6SI",
	"styledBackground": "CodeEditor_styledBackground__2yFth",
	"pausedBackground": "CodeEditor_pausedBackground__20bON",
	"errorBackground": "CodeEditor_errorBackground__3ANMY"
};
module.exports = exports;
