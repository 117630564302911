import React from 'react'
import * as JsSearch from 'js-search'
import apiClient from '@miroculus/api-client'
import browserHistory from 'browserHistory'
import * as Sentry from '@sentry/browser'
import {
  PROTOCOL_SOLVED_STATUS,
  PROTOCOL_SOLVING_STATUS
} from 'cons'
import {
  teamUrlWithVisibility, saveProtocolUrl
} from 'cons/routes'
import { toast } from 'react-toastify'
import {
  protocolTimeSelector,
  getProtocolValidationErrors,
  currentProtocolSelector
} from './selectors'
import socketClient from 'socketClient'
import { showModal, hideModal } from 'reduxModules/ui/layout.js'
import { ErrorModalContent } from 'components'

const UPDATE_PROTOCOLS = 'SAM/protocols/UPDATE_PROTOCOLS'
const UPDATE_SAVING = 'SAM/protocols/UPDATE_SAVING'
const UPDATE_SELECTED_TYPE = 'SAM/ui/PROTOCOL_SELECTION_UPDATE_SELECTED_TYPE'
const UPDATE_PROTOCOLS_FILTERS = 'SAM/ui/PROTOCOL_SELECTION_UPDATE_PROTOCOLS_FILTERS'
const UPDATE_LOADING = 'SAM/ui/protocols/UPDATE_LOADING'
const UPDATE_PROTOCOL_NAME = 'SAM/protocolCreation/UPDATE_PROTOCOL_NAME'
const UPDATE_PROTOCOL_SOLUTION = 'SAM/protocolCreation/UPDATE_PROTOCOL_SOLUTION'
const UPDATE_PROTOCOL_LOADING_SOLUTION = 'SAM/protocolCreation/UPDATE_PROTOCOL_LOADING_SOLUTION'
const UPDATE_PROTOCOL_DESCRIPTION = 'SAM/protocolCreation/UPDATE_PROTOCOL_DESCRIPTION'
const UPDATE_PROTOCOL_TYPE = 'SAM/protocolCreation/UPDATE_PROTOCOL_TYPE'
const UPDATE_PROTOCOL_DURATION = 'SAM/protocolCreation/UPDATE_PROTOCOL_DURATION'
const UPDATE_PROTOCOL_CREATED_AT = 'SAM/protocolCreation/UPDATE_PROTOCOL_CREATED_AT'
const UPDATE_PROTOCOL_CARTRIDGE = 'SAM/protocolCreation/UPDATE_PROTOCOL_CARTRIDGE'
const UPDATE_PROTOCOL_ID = 'SAM/protocolCreation/UPDATE_PROTOCOL_ID'
const RESET_CURRENT_PROTOCOL = 'SAM/protocolCreation/RESET_CURRENT_PROTOCOL'
const UPDATE_PROTOCOL_TYPES = 'SAM/protocolCreation/UPDATE_PROTOCOL_TYPES'
const UPDATE_PROTOCOL_GRAPH = 'SAM/protocolCreation/UPDATE_PROTOCOL_GRAPH'
const UPDATE_PROTOCOL_AUTHOR = 'SAM/protocolCreation/UPDATE_PROTOCOL_AUTHOR'
const UPDATE_SHOWING_SOLUTION = 'SAM/protocolCreation/UPDATE_SHOWING_SOLUTION'
const UPDATE_PROTOCOL_TO_SEND = 'SAM/protocolCreation/UPDATE_PROTOCOL_TO_SEND'
const UPDATE_SENDING_PROTOCOL = 'SAM/protocolCreation/UPDATE_SENDING_PROTOCOL'
const UPDATE_PROTOCOL_SOLUTION_STATUS = 'SAM/protocolCreation/UPDATE_PROTOCOL_SOLUTION_STATUS'
const UPDATE_PROTOCOL_LIBRARY = 'SAM/protocolCreation/UPDATE_PROTOCOL_LIBRARY'
const UPDATE_PROTOCOL_VALIDATION_ERRORS = 'SAM/protocolCreation/UPDATE_PROTOCOL_VALIDATION_ERRORS'
const UPDATE_PROTOCOL_MODIFIED = 'SAM/protocolCreation/UPDATE_PROTOCOL_MODIFIED'
const UPDATE_PROTOCOL_TEAM = 'SAM/protocolCreation/UPDATE_PROTOCOL_TEAM'
const UPDATE_PROTOCOL_VISIBILITY = 'SAM/protocolCreation/UPDATE_PROTOCOL_VISIBILITY'
const UPDATE_PROTOCOL_PERMISSIONS = 'SAM/protocolCreation/UPDATE_PROTOCOL_PERMISSIONS'
const UPDATE_PROTOCOL_TEMPLATE = 'SAM/protocolCreation/UPDATE_PROTOCOL_TEMPLATE'
const UPDATE_SHOWING_DEVICES = 'SAM/protocolCreation/UPDATE_SHOWING_DEVICES'
const UPDATE_MIRO_LAYOUT_ID = 'SAM/protocolCreation/UPDATE_MIRO_LAYOUT_ID'
const UPDATE_REAGENT_PROFILES = 'SAM/protocolCreation/UPDATE_REAGENT_PROFILES'
const UPDATE_ERROR_CYCLE_INDEX = 'SAM/protocolCreation/UPDATE_ERROR_CYCLE_INDEX'

const search = new JsSearch.Search('id')
search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()

search.addIndex('name')
search.addIndex(['type', 'label'])
search.addIndex(['cartridge', 'label'])

export const loadProtocolSolution = (id) => async (dispatch) => {
  dispatch(updateLoadingSolution(true))

  try {
    const { body } = await apiClient.get(`/protocol/solutions/${id}`)
    dispatch(updateProtocolSolution(JSON.parse(body.solution)))
    dispatch(updateShowingSolution(true))
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong loading the protocol solution')
  }
  dispatch(updateLoadingSolution(false))
}

export const sendProtocolToDevice = (protocol, deviceId) => async (dispatch) => {
  dispatch(updateSendingProtocol(true))
  try {
    await apiClient.post(`/protocols/send/${protocol}/${deviceId}`)
    toast.success('The protocol was sent successfully')
  } catch (e) {
    Sentry.captureException(e)
    toast.error('There was a problem sending the protocol to the device')
  }
  dispatch(updateSendingProtocol(false))
}

export const unsubscribeToProtocols = (teamId, visibility) => async () => {
  await socketClient.unsubscribeToProtocols(teamId, visibility)
}

export const saveReagents = (teamId) => async (dispatch, getState) => {
  const {
    protocols: {
      currentProtocol: {
        library
      }
    }
  } = getState()

  // Just save new reagents
  const reagentsToSave = library.reagents.filter(r => r.new)

  try {
    await Promise.all(reagentsToSave.map(r => apiClient.post('/reagents', {
      className: r.name,
      team: teamId
    })))

    // set current protocol library reagents as not-new
    dispatch(updateProtocolLibrary({
      ...library,
      reagents: [...library.reagents.map(r => ({ ...r, new: undefined }))]
    }))
  } catch (e) {
    Sentry.captureException(e)
    toast.error('There was a problem trying to save the new reagents')
  }
}

export const saveProtocol = (values) => async (dispatch, getState) => {
  const {
    protocols: {
      currentProtocol: {
        id
      }
    }
  } = getState()

  await dispatch(updateProtocolName(values.name))
  await dispatch(updateProtocolType(values.type))
  await dispatch(updateProtocolDescription(values.description))
  await dispatch(updateProtocolVisibility(values.visibility))
  await dispatch(updateProtocolTeam(values.teamId))

  await dispatch(saveReagents(values.teamId))

  // check if protocols.currentProtocol.id exists,
  // if it exists, update the protocol,
  // if it doesn't, create a new protocol and add it to the global state
  await dispatch(id ? updateProtocol(values) : addProtocol(values))
  toast.success(`The ${values.name} protocol was stored successfully`)

  browserHistory.push(teamUrlWithVisibility(values.teamId, values?.visibility))
}

export const checkProtocolToSave = () => (dispatch, getState) => {
  const state = getState()
  const { id } = currentProtocolSelector(state)
  const validationErrors = getProtocolValidationErrors(state)

  const goOn = () => {
    browserHistory.push(saveProtocolUrl(id))
  }

  const errorCount = validationErrors.length

  if (errorCount > 0) {
    dispatch(showModal(
      <ErrorModalContent
        errorCount={errorCount}
        onCancel={() => {
          dispatch(hideModal())
          goOn()
        }}
        onConfirm={() => {
          dispatch(hideModal())
          dispatch(updateErrorCycleIndex(0))
        }}
      />
    ))
  } else {
    goOn()
  }
}

export const loadProtocolTypes = () => async (dispatch) => {
  dispatch(updateLoading(true))
  const { body } = await apiClient.get('/protocol/options')
  const protocolTypes = body.map(type => ({
    label: type.label,
    value: type.id
  }))
  dispatch(updateProtocolTypes(protocolTypes))
  dispatch(updateLoading(false))
}

export const loadMiroLayoutId = () => async (dispatch) => {
  try {
    const { body } = await apiClient.get('/electrode-layouts')
    const miroLayoutId = body.find(c => c.name.toLowerCase() === 'miro').id
    dispatch(updateMiroLayoutId(miroLayoutId))
  } catch (e) {
    Sentry.captureException(e)
    toast.error('There was a problem loading the available layouts.')
  }
}

export const loadSupportedProtocols = (organization) => async (dispatch) => {
  try {
    const { body } = await apiClient.get('/protocols/supported', {
      organization
    })
    dispatch(updateProtocols(body))
  } catch (e) {
    Sentry.captureException(e)
    toast.error('There was a problem loading the supported protocols.')

    dispatch(updateProtocols([]))
  }
}

export const loadProtocols = (teamId, visibility) => async (dispatch) => {
  dispatch(updateLoading(true))
  try {
    const protocols = await socketClient.subscribeToProtocols(teamId, visibility)
    await dispatch(updateProtocols(protocols))
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong loading the protocols')
  } finally {
    dispatch(updateLoading(false))
  }
}
/*
  The id attribute on the protocol object into the protocolCreation
  Redux module determines if a protocol is being edited or created,
  if id exists it means protocol is being updated but if it does not exist
  it means protocol is being created
*/
// Button actions
export const deleteProtocol = (id) => async (dispatch, getState) => {
  const {
    protocols: {
      protocols
    }
  } = getState()
  const index = protocols.map(p => p.id).indexOf(id)
  const protocol = protocols[index]
  try {
    await apiClient.del(`/protocols/${id}`)
    await dispatch(updateProtocols([
      ...protocols.slice(0, index),
      ...protocols.slice(index + 1)
    ]))
    toast.success(`The ${protocol.name} protocol was deleted successfully`)
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong trying to delete the protocol')
  }
}

export const setProtocolAsCurrent = (id) => async (dispatch) => {
  const { body } = await apiClient.get(`/protocols/${id}`)

  dispatch(updateProtocolAuthor(body.author))
  dispatch(updateProtocolName(body.name))
  dispatch(updateProtocolType(body.type.id))
  dispatch(updateProtocolDescription(body.description))
  dispatch(updateProtocolCartridge(body.cartridge))
  dispatch(updateProtocolId(body.id))
  dispatch(updateProtocolDuration(body.time))
  dispatch(updateProtocolCreatedAt(body.createdAt))
  dispatch(updateProtocolGraph(body.protocol))
  dispatch(prepareProtocol(body.protocol))
  dispatch(updateProtocolSolutionStatus(body.solutionStatus))
  dispatch(updateProtocolTeam(body.team))
  dispatch(updateProtocolVisibility(body.visibility))
  dispatch(updateProtocolPermissions(body.$permissions))
  dispatch(updateProtocolTemplate(body.template))
}

export const loadProtocolToCopy = (protocolId) => async (dispatch, getState) => {
  const {
    auth: {
      user
    }
  } = getState()

  dispatch(updateProtocolId(undefined))
  dispatch(updateProtocolAuthor(user))

  const { body } = await apiClient.get(`/protocols/${protocolId}`)

  dispatch(updateProtocolType(body.type.id))
  dispatch(updateProtocolName(`Copy of ${body.name}`))
  dispatch(updateProtocolDescription(body.description))
  dispatch(updateProtocolCartridge(body.cartridge))
  dispatch(updateProtocolGraph(body.protocol))
  dispatch(updateProtocolVisibility(body.visibility))
}

// API calls
const addProtocol = ({
  name, description, type, visibility, teamId, protocolCopyFromId
}) => async (dispatch, getState) => {
  const {
    protocols: {
      miroLayoutId,
      protocols,
      currentProtocol: {
        graph
      }
    },
    auth: {
      user
    }
  } = getState()

  const newProtocol = {
    name,
    description,
    type,
    time: protocolTimeSelector(getState()),
    cartridge: miroLayoutId,
    author: user,
    protocol: graph,
    steps: [],
    visibility,
    team: teamId
  }
  dispatch(updateSaving(true))

  const { body } = await apiClient.post(
    protocolCopyFromId
      ? `/protocols/${protocolCopyFromId}/copy/${teamId}`
      : '/protocols',
    newProtocol
  )

  dispatch(updateProtocols([
    ...protocols,
    {
      ...newProtocol,
      id: body.id,
      protocol: graph
    }
  ]))
  dispatch(updateProtocolId(body.id))
  dispatch(updateProtocolSolutionStatus(body.solutionStatus))
  dispatch(updateProtocolAuthor(user))
  dispatch(updateSaving(false))
}

const updateProtocol = ({
  name, description, type, visibility, teamId: team
}) => async (dispatch, getState) => {
  const {
    protocols: {
      currentProtocol: {
        id,
        cartridge,
        graph
      }
    },
    auth: {
      user
    }
  } = getState()

  const time = protocolTimeSelector(getState())
  const newProtocol = {
    id,
    name,
    description,
    time,
    cartridge,
    type,
    author: user.email,
    protocol: graph,
    visibility,
    team
  }

  dispatch(updateSaving(true))
  const { body } = await apiClient.post(`/protocols/${id}`, { ...newProtocol })
  dispatch(updateProtocolSolutionStatus(body.solutionStatus))
  dispatch(updateSaving(false))
}

const parseActionModule = ({
  title,
  graph = {},
  $permissions = {},
  ...am
}) => ({
  ...am,
  name: title,
  actions: graph.actions || [],
  canEdit: $permissions.canEdit
})

export const addActionModuleToLibrary = (actionModule) => async (dispatch, getState) => {
  const state = getState()
  const { library } = state.protocols.currentProtocol
  const currentProtocol = currentProtocolSelector(state)

  // @TODO: allow to add action modules when creating a new protocol
  if (!currentProtocol?.team) return

  const { body } = await apiClient.post('/action-modules', {
    title: actionModule.name,
    graph: { actions: actionModule.actions },
    team: currentProtocol.team
  })

  dispatch(updateProtocolLibrary({
    ...library,
    actionModules: [
      ...library.actionModules,
      parseActionModule(body)
    ]
  }))

  toast.success('Action module saved in library')
}

export const updateActionModuleInLibrary = (id, actionModule) => (dispatch, getState) => {
  const state = getState()
  const { library } = state.protocols.currentProtocol

  apiClient.post(`/action-modules/${id}`, {
    graph: { actions: actionModule.actions }
  })

  dispatch(updateProtocolLibrary({
    ...library,
    actionModules: library.actionModules.map(
      (am) => am.id === id
        ? { ...am, actions: actionModule.actions }
        : am
    )
  }))
}

export const editActionModule = (actionModule) => (dispatch, getState) => {
  apiClient.post(`/action-modules/${actionModule.id}`, {
    title: actionModule.name
  })

  const { library } = getState().protocols.currentProtocol

  dispatch(updateProtocolLibrary({
    ...library,
    actionModules: library.actionModules.map(
      (am) => am.id === actionModule.id ? actionModule : am
    )
  }))
}

export const deleteActionModule = (index) => (dispatch, getState) => {
  const { library } = getState().protocols.currentProtocol
  const actionModule = library.actionModules[index]

  apiClient.del(`/action-modules/${actionModule.id}`)

  dispatch(updateProtocolLibrary({
    ...library,
    actionModules: library.actionModules.filter((_, i) => i !== index)
  }))
}

const parseReagent = ({
  className,
  $permissions = {},
  ...reagent
}) => ({
  name: className,
  canEdit: $permissions.canEdit,
  ...reagent
})

export const addReagentToLibrary = (reagentName) => async (dispatch, getState) => {
  const state = getState()
  const { library } = state.protocols.currentProtocol

  // don't add another copy of the same reagent class
  if (library.reagents.find((r) => r.name === reagentName)) return

  dispatch(updateProtocolLibrary({
    ...library,
    // Mark created reagents as "new", these ones will be saved on the DB
    reagents: [...library.reagents, { name: reagentName, canEdit: true, new: true }]
  }))
}

export const removeReagentFromLibrary = (index) => (dispatch, getState) => {
  const { library } = getState().protocols.currentProtocol
  const reagent = library.reagents[index]

  apiClient.del(`/reagents/${reagent.id}`)

  dispatch(updateProtocolLibrary({
    ...library,
    reagents: library.reagents.filter((_, i) => i !== index)
  }))
}

export const updateReagents = (reagents) => (dispatch, getState) => {
  const { library } = getState().protocols.currentProtocol

  dispatch(updateProtocolLibrary({
    ...library,
    reagents
  }))
}

export const prepareProtocol = (protocolGraph) => async (dispatch, getState) => {
  let errors = []
  let updatedGraph = null
  const { protocols: { currentProtocol } } = getState()

  try {
    const { body } = await socketClient.prepareProtocol(protocolGraph, currentProtocol.id)
    errors = body.errors
    updatedGraph = body.graph
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong trying to validate the protocol')
    return
  }

  dispatch(updateProtocolGraph(updatedGraph))
  dispatch(updateProtocolValidationErrors(errors))
}

export const loadReagentProfiles = () => async (dispatch) => {
  const { body } = await apiClient.get('/reagent-profiles')
  dispatch(updateReagentProfiles(body))
}

export const loadLibrary = () => async (dispatch, getState) => {
  const currentProtocol = currentProtocolSelector(getState())
  const teamId = currentProtocol?.team || undefined
  const { body: actionModules } =
    await apiClient.get('/action-modules', { teamId })
  const { body: reagents } =
    await apiClient.get('/reagents', { teamId })

  dispatch(updateProtocolLibrary({
    actionModules: actionModules.map(parseActionModule),
    reagents: reagents.map(parseReagent)
  }))
}

export const updateProtocols = (protocols) => (dispatch, getState) => {
  const oldProtocols = getState().protocols.protocols
  for (const protocol of protocols) {
    // Show notification if a solving protocol is solved!
    if (
      protocol.solutionStatus === PROTOCOL_SOLVED_STATUS &&
      oldProtocols.find((p) => p.id === protocol.id)?.solutionStatus ===
        PROTOCOL_SOLVING_STATUS
    ) {
      toast.success(`The ${protocol.name} protocol has been solved`)
    }
  }
  dispatch(_updateProtocols(protocols))
  dispatch(updateProtocolsFilters())
}

export const loadRecentProtocols = () => async (dispatch) => {
  dispatch(updateLoading(true))

  try {
    const { body } = await apiClient.get('/protocols/recent')
    dispatch(updateProtocols(body))
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong loading recent protocols')
  } finally {
    dispatch(updateLoading(false))
  }
}

export const loadDrafts = () => async (dispatch) => {
  dispatch(updateLoading(true))

  try {
    const { body } = await apiClient.get('/protocols/drafts')
    dispatch(updateProtocols(body))
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong loading drafts')
  } finally {
    dispatch(updateLoading(false))
  }
}

// actions
export const updateShowingDevices = (showingDevices) => ({
  payload: { showingDevices },
  type: UPDATE_SHOWING_DEVICES
})

export const updateProtocolsFilters = () => ({
  type: UPDATE_PROTOCOLS_FILTERS
})

export const updateProtocolSolutionStatus = (solutionStatus) => ({
  type: UPDATE_PROTOCOL_SOLUTION_STATUS,
  payload: { solutionStatus }
})

export const updateProtocolAuthor = (author) => ({
  payload: { author },
  type: UPDATE_PROTOCOL_AUTHOR
})

export const updateSelectedProtocolType = (selectedProtocolType) => ({
  type: UPDATE_SELECTED_TYPE,
  payload: { selectedProtocolType }
})

// Don't use this one, this is just exported for the tests
export const _updateProtocols = (protocols) => ({
  type: UPDATE_PROTOCOLS,
  payload: { protocols }
})

export const updateLoading = (loading) => ({
  payload: { loading },
  type: UPDATE_LOADING
})

export const updateProtocolName = (name) => ({
  payload: { name },
  type: UPDATE_PROTOCOL_NAME
})

export const updateProtocolGraph = (graph) => ({
  payload: { graph },
  type: UPDATE_PROTOCOL_GRAPH
})

export const updateProtocolId = (id) => ({
  payload: { id },
  type: UPDATE_PROTOCOL_ID
})

export const updateProtocolTypes = (protocolTypes) => ({
  payload: { protocolTypes },
  type: UPDATE_PROTOCOL_TYPES
})

export const updateProtocolLibrary = (library) => ({
  payload: { library },
  type: UPDATE_PROTOCOL_LIBRARY
})

export const updateProtocolValidationErrors = (validationErrors) => ({
  payload: { validationErrors },
  type: UPDATE_PROTOCOL_VALIDATION_ERRORS
})

export const updateProtocolCartridge = (cartridge) => ({
  payload: { cartridge },
  type: UPDATE_PROTOCOL_CARTRIDGE
})

export const updateProtocolDuration = (time) => ({
  payload: { time },
  type: UPDATE_PROTOCOL_DURATION
})

export const updateProtocolPermissions = ($permissions) => ({
  payload: { $permissions },
  type: UPDATE_PROTOCOL_PERMISSIONS
})

export const updateProtocolCreatedAt = (createdAt) => ({
  payload: { createdAt },
  type: UPDATE_PROTOCOL_CREATED_AT
})

export const updateProtocolTemplate = (template) => ({
  payload: { template },
  type: UPDATE_PROTOCOL_TEMPLATE
})

export const resetCurrentProtocol = () => ({
  type: RESET_CURRENT_PROTOCOL
})

export const updateProtocolDescription = (description) => ({
  payload: { description },
  type: UPDATE_PROTOCOL_DESCRIPTION
})

export const updateProtocolType = (type) => ({
  payload: { type },
  type: UPDATE_PROTOCOL_TYPE
})

export const updateSaving = (saving) => ({
  type: UPDATE_SAVING,
  payload: { saving }
})

export const updateSendingProtocol = (sendingProtocol) => ({
  type: UPDATE_SENDING_PROTOCOL,
  payload: { sendingProtocol }
})

export const updateProtocolModified = (modified) => ({
  type: UPDATE_PROTOCOL_MODIFIED,
  payload: { modified }
})

export const updateProtocolTeam = (team) => ({
  type: UPDATE_PROTOCOL_TEAM,
  payload: { team }
})

export const updateProtocolVisibility = (visibility) => ({
  type: UPDATE_PROTOCOL_VISIBILITY,
  payload: { visibility }
})

export const updateMiroLayoutId = (miroLayoutId) => ({
  type: UPDATE_MIRO_LAYOUT_ID,
  payload: { miroLayoutId }
})

export const updateReagentProfiles = (reagentProfiles) => ({
  type: UPDATE_REAGENT_PROFILES,
  payload: { reagentProfiles }
})

export const updateProtocolSolution = (solution) => ({
  payload: { solution },
  type: UPDATE_PROTOCOL_SOLUTION
})

export const updateLoadingSolution = (loadingSolution) => ({
  payload: { loadingSolution },
  type: UPDATE_PROTOCOL_LOADING_SOLUTION
})

export const updateShowingSolution = (showingSolution) => ({
  payload: { showingSolution },
  type: UPDATE_SHOWING_SOLUTION
})

export const updateErrorCycleIndex = (errorCycleIndex) => ({
  payload: { errorCycleIndex },
  type: UPDATE_ERROR_CYCLE_INDEX
})

export const initialProtocols = []

const initialState = {
  miroLayoutId: 0,
  selectedProtocolType: null,
  protocols: initialProtocols,
  filteredProtocols: initialProtocols,
  searchText: '',
  currentProtocol: {
    solution: null,
    graph: {
      actions: [],
      actionModules: [],
      edges: {}
    },
    library: {
      actionModules: [],
      reagents: []
    },
    id: undefined,
    name: '',
    description: '',
    type: null,
    cartridge: null,
    time: 0,
    author: {},
    solutionStatus: '',
    validationErrors: [],
    errorCycleIndex: -1,
    team: null,
    visibility: null,
    template: null
  },
  loading: false,
  protocolTypes: [],
  cartridges: [],
  saving: false,
  sendingProtocol: false,
  showingDevices: false,
  showingSolution: false,
  loadingSolution: false
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_PROTOCOLS_FILTERS:
      return {
        ...state,
        filteredProtocols: (state.searchText ? search.search(state.searchText) : state.protocols)
          .filter(protocol => {
            return (state.selectedProtocolType ? protocol.type && protocol.type.id === state.selectedProtocolType.value : true)
          })
      }
    case UPDATE_SELECTED_TYPE:
    case UPDATE_PROTOCOLS:
      search.addDocuments(state.protocols)
      return {
        ...state,
        ...payload
      }
    case UPDATE_LOADING:
    case UPDATE_PROTOCOL_TYPES:
    case UPDATE_SAVING:
    case UPDATE_PROTOCOL_TO_SEND:
    case UPDATE_SENDING_PROTOCOL:
    case UPDATE_SHOWING_DEVICES:
    case UPDATE_MIRO_LAYOUT_ID:
    case UPDATE_REAGENT_PROFILES:
    case UPDATE_SHOWING_SOLUTION:
    case UPDATE_PROTOCOL_LOADING_SOLUTION:
      return {
        ...state,
        ...payload
      }
    case UPDATE_PROTOCOL_NAME:
    case UPDATE_PROTOCOL_SOLUTION:
    case UPDATE_PROTOCOL_TYPE:
    case UPDATE_PROTOCOL_DESCRIPTION:
    case UPDATE_PROTOCOL_DURATION:
    case UPDATE_PROTOCOL_PERMISSIONS:
    case UPDATE_PROTOCOL_CREATED_AT:
    case UPDATE_PROTOCOL_CARTRIDGE:
    case UPDATE_PROTOCOL_ID:
    case UPDATE_PROTOCOL_GRAPH:
    case UPDATE_PROTOCOL_AUTHOR:
    case UPDATE_PROTOCOL_SOLUTION_STATUS:
    case UPDATE_PROTOCOL_LIBRARY:
    case UPDATE_PROTOCOL_VALIDATION_ERRORS:
    case UPDATE_PROTOCOL_MODIFIED:
    case UPDATE_PROTOCOL_TEAM:
    case UPDATE_PROTOCOL_VISIBILITY:
    case UPDATE_PROTOCOL_TEMPLATE:
    case UPDATE_ERROR_CYCLE_INDEX:
      return {
        ...state,
        currentProtocol: {
          ...state.currentProtocol,
          ...payload
        }
      }
    case RESET_CURRENT_PROTOCOL:
      return {
        ...state,
        currentProtocol: {
          ...initialState.currentProtocol
        }
      }
    default:
      return state
  }
}
