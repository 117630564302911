import React, { useCallback } from 'react'
import { Button, Input, Modal } from '@miroculus/nucleo'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import classNames from 'classnames/bind'
import browserHistory from 'browserHistory'
import { useParams } from 'react-router-dom'
import { organizationSettingsUrl } from 'cons/routes'
import { FormField, LoadingWithText } from 'components'
import { createUpdateOrganizationTeam } from 'reduxModules/organizations'
import { getUpdatingOrganization, teamsSelector } from 'reduxModules/organizations/selectors'
import styles from './TeamModal.scss'
import PropTypes from 'prop-types'

const cx = classNames.bind(styles)

const TeamForm = ({ id, organization, teamName = '' }) => {
  const dispatch = useDispatch()

  const submitForm = async (values) => {
    await dispatch(createUpdateOrganizationTeam({ teamId: id, organization, title: values.title }))
  }

  const initialValues = { title: teamName }

  const { dirty, handleSubmit, getFieldProps } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        await submitForm(values)
        browserHistory.push(organizationSettingsUrl(organization))
      } catch (e) {
        toast.error(e.message)
      }
    }
  })

  const teamNameProps = getFieldProps('title')

  return (
    <form className={cx('modal-content')} onSubmit={handleSubmit}>
      <h1 className={id ? '' : cx('creating')}>{id ? 'Edit Team' : 'Create a Team'}</h1>
      <FormField title='Team name'>
        <Input {...teamNameProps} placeholder='Enter a team name' />
      </FormField>
      <Button type='submit' size='small' disabled={!id && !dirty}>
        {id ? 'Update' : 'Create team'}
      </Button>
    </form>
  )
}

TeamForm.propTypes = {
  organization: PropTypes.string.isRequired,
  teamName: PropTypes.string,
  id: PropTypes.number
}

const TeamModal = () => {
  const { orgSlug, id } = useParams()
  const teamId = parseInt(id, 10)

  const organizationTeam = useSelector((state) =>
    teamsSelector(state).find((team) => team.id === teamId) ?? {}
  )

  const handleClose = useCallback(() => {
    browserHistory.push(organizationSettingsUrl(orgSlug))
  }, [])

  const updatingOrganization = useSelector(getUpdatingOrganization)

  if (updatingOrganization) {
    return (
      <LoadingWithText message={!id ? 'Creating team...' : 'Updating team...'} />
    )
  }

  return (
    <Modal label='TeamModal' onClose={handleClose}>
      <TeamForm
        organization={orgSlug}
        teamName={organizationTeam.title}
        id={organizationTeam.title ? teamId : undefined}
      />
    </Modal>
  )
}

export default TeamModal
