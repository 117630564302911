import React from 'react'
import { getLogged } from 'reduxModules/auth/selectors'
import ValidatorRoute from './ValidatorRoute'
import { LOGIN_URL, SIGNUP_URL } from 'cons/routes'

const getCurrentPath = () =>
  encodeURIComponent(window.location.pathname + window.location.search)

const PrivateRoute = ({
  redirect,
  signUp,
  ...props
}) => {
  const url = signUp ? SIGNUP_URL : LOGIN_URL
  const redirectionPath = redirect
    ? `${url}?redirectTo=${getCurrentPath()}`
    : url

  return (
    <ValidatorRoute
      {...props}
      redirectionPath={redirectionPath}
      validators={[getLogged]}
    />
  )
}

export default PrivateRoute
