// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserAccountHeader_user-account-header__2XReU{display:flex;padding:0;margin:0;position:relative}.UserAccountHeader_user-account-header-avatar__n7kwP{align-self:center;margin-right:.625rem}.UserAccountHeader_user-account-header-avatar-image__3bvxT{border-radius:50%;overflow:hidden;height:3.5rem;width:3.5rem}.UserAccountHeader_user-account-header-avatar-image__3bvxT img{width:100%;min-height:2.5rem;-o-object-fit:cover;object-fit:cover}.UserAccountHeader_user-account-header__2XReU .UserAccountHeader_user-account-brief-info__1wIik{display:flex;flex-direction:column;justify-content:center}.UserAccountHeader_user-account-header__2XReU .UserAccountHeader_user-account-brief-info__1wIik p,.UserAccountHeader_user-account-header__2XReU .UserAccountHeader_user-account-brief-info__1wIik h1{margin:0;font-weight:500}.UserAccountHeader_user-account-header__2XReU .UserAccountHeader_user-account-brief-info__1wIik .UserAccountHeader_user-account-name__3U4YE{text-align:left;font-weight:600;font-size:var(--font-size-x6large);color:var(--color-primary)}.UserAccountHeader_user-account-header__2XReU .UserAccountHeader_user-account-brief-info__1wIik .UserAccountHeader_user-account-joined-date__3aDff{text-align:left;font-size:var(--font-size-small);color:#8491b3}", ""]);
// Exports
exports.locals = {
	"user-account-header": "UserAccountHeader_user-account-header__2XReU",
	"user-account-header-avatar": "UserAccountHeader_user-account-header-avatar__n7kwP",
	"user-account-header-avatar-image": "UserAccountHeader_user-account-header-avatar-image__3bvxT",
	"user-account-brief-info": "UserAccountHeader_user-account-brief-info__1wIik",
	"user-account-name": "UserAccountHeader_user-account-name__3U4YE",
	"user-account-joined-date": "UserAccountHeader_user-account-joined-date__3aDff"
};
module.exports = exports;
