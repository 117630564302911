import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { login } from 'reduxModules/auth'

/**
 * Wrapper component that checks if logged in and auto-fills the store
 */
const AutoLogin = memo(({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(login())
  }, [])

  return children
})

export default AutoLogin
