import React, { lazy, Suspense } from 'react'
import { LoadingSpinner } from '@miroculus/miroculus-ui'

const ProtocolCanvas = lazy(() => import(
  /* webpackChunkName: 'sam' */
  './ProtocolCanvas'
))

const loading = <LoadingSpinner message='Loading Palette...' />

export default (props) => (
  <Suspense fallback={loading}>
    <ProtocolCanvas {...props} />
  </Suspense>
)
