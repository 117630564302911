import React, { memo, useLayoutEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames/bind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import apiClient from '@miroculus/api-client'
import { Button, Input } from '@miroculus/nucleo'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { AuthPage } from 'components'
import { LOGIN_URL, CREATE_ORGANIZATION_URL, DASHBOARD_URL } from 'cons/routes'
import history from 'browserHistory'
import { signUpWithPassword } from 'reduxModules/auth'
import { getLogged } from 'reduxModules/auth/selectors'
import { useQuery } from 'hooks'
import { getRedirectUrl } from 'utils'
import { canSubmit, getFirstError, getFieldError } from 'utils/forms'
import styles from './SignUp.scss'

const cx = classnames.bind(styles)

const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .required('A name is required'),
  email: Yup.string()
    .email('The email you entered is invalid')
    .required('An email is required'),
  password: Yup.string()
    .min(12, 'The password should contain at least 12 characters')
    .required('A password is required')
})

const dataFromQuery = (query) => ({
  email: query.get('email'),
  inviteToken: query.get('inviteToken') ?? undefined,
  redirectTo: query.get('redirectTo'),
  invitedToOrganization: query.get('org')
})

const getInviteMessage = (inviteToken, redirectTo, invitedToOrganization, loginUrl) => {
  if (inviteToken) {
    if (invitedToOrganization) {
      return (
        <>
          Please sign up to accept the organization invitation. If you already have an account, please <Link to={loginUrl}>sign in</Link> instead.
        </>
      )
    }

    return (
      <>
        Please sign up to accept the team invitation. If you already have an account, please <Link to={loginUrl}>sign in</Link> instead.
      </>
    )
  }

  if (redirectTo?.includes(CREATE_ORGANIZATION_URL)) {
    return (
      <>
        Please sign up to create a new organization. If you already have an account, please <Link to={loginUrl}>sign in</Link> instead.
      </>
    )
  }

  return null
}

const getCreateToken = (redirectTo) => {
  if (!redirectTo?.includes(CREATE_ORGANIZATION_URL)) return undefined

  const params = new URLSearchParams(
    redirectTo.replace(CREATE_ORGANIZATION_URL, '')
  )
  return params.get('token')
}

export const SignUp = memo(({
  onSubmit,
  logged,
  onLoggedIn,
  onGoogleSignUp
}) => {
  const {
    email, inviteToken, redirectTo, invitedToOrganization
  } = dataFromQuery(useQuery())

  const createToken = getCreateToken(redirectTo)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: email || '',
      password: ''
    },
    onSubmit: async (values, actions) => {
      actions.setStatus(undefined)
      try {
        await onSubmit({
          ...values,
          inviteToken,
          createToken
        })
      } catch (e) {
        if (e.status === 400) {
          actions.setStatus(e.message)
        } else {
          throw e
        }
      }
    },
    validationSchema: SignUpSchema
  })

  // by using a layout effect we prevent the user from seeing the first render
  useLayoutEffect(() => {
    if (logged) onLoggedIn(redirectTo)
  }, [logged])

  const handleGoogleSignUp = useCallback(() => {
    onGoogleSignUp(inviteToken, redirectTo)
  }, [])

  const loginUrl = `${LOGIN_URL}${window.location.search}`
  const inviteMessage = getInviteMessage(inviteToken, redirectTo, invitedToOrganization, loginUrl)
  const errorToDisplay = getFirstError(formik)
  const statusMessage = errorToDisplay || formik.status

  return (
    <AuthPage
      additionalInfo={inviteMessage}
      ssoButton={
        <Button onClick={handleGoogleSignUp}>Sign up with Google</Button>
      }
      footerText={<p>Have an account? <Link to={loginUrl}>Sign in</Link></p>}
    >
      <form className={cx('form')} onSubmit={formik.handleSubmit}>
        <Input
          {...formik.getFieldProps('name')}
          name='name'
          type='name'
          placeholder='Enter your name'
          error={getFieldError(formik, 'name')}
          autoFocus
        />
        <Input
          {...formik.getFieldProps('email')}
          name='email'
          type='email'
          placeholder='Enter your email'
          error={getFieldError(formik, 'email')}
        />
        <Input
          {...formik.getFieldProps('password')}
          name='password'
          type='password'
          placeholder='Enter your password'
          minLength={12}
          maxLength={512}
          error={getFieldError(formik, 'password')}
        />
        <div className={cx('buttonContainer')}>
          <p className={cx('statusMessage')}>{statusMessage || <>&nbsp;</>}</p>
          <Button type='submit' disabled={!canSubmit(formik)}>Sign up</Button>
        </div>
      </form>
    </AuthPage>
  )
})

SignUp.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onGoogleSignUp: PropTypes.func.isRequired,
  logged: PropTypes.bool
}

SignUp.defaultProps = {
  logged: false
}

const mapStateToProps = (state) => ({
  logged: getLogged(state)
})

const mapDispatchToProps = dispatch => ({
  onLoggedIn: async (redirectTo) => {
    history.push(getRedirectUrl(redirectTo, DASHBOARD_URL))
  },
  onSubmit: async (data) => {
    await dispatch(signUpWithPassword(data))
  },
  onGoogleSignUp: (inviteToken, redirectTo) => {
    const baseUrl = apiClient.getGoogleAuthUrl({
      redirectUrl: window.location.origin + getRedirectUrl(redirectTo, DASHBOARD_URL)
    })
    const url = inviteToken ? `${baseUrl}&inviteToken=${inviteToken}` : baseUrl

    window.location.replace(url)
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(SignUp)
