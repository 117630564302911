import React, { useCallback } from 'react'
import { Button, Input, Modal, Select } from '@miroculus/nucleo'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import classNames from 'classnames/bind'
import browserHistory from 'browserHistory'
import { useParams } from 'react-router-dom'
import { organizationSettingsUrl } from 'cons/routes'
import { FormField, LoadingWithText, UserAccountHeader } from 'components'
import { MEMBER_ROLES } from 'reduxModules/workspaces'
import { inviteUserToOrganization, updateOrganizationMember } from 'reduxModules/organizations'
import { getSendingInvitation, getUpdatingOrganization, membersSelector } from 'reduxModules/organizations/selectors'
import styles from './OrganizationMemberModal.scss'
import PropTypes from 'prop-types'

const cx = classNames.bind(styles)

const MEMBER_ROLE_OPTIONS = Object.entries(MEMBER_ROLES).map(([value, label]) => ({
  value,
  label
}))

const ACTIVE_OPTIONS = [
  {
    value: 'true',
    label: 'Yes'
  },
  {
    value: 'false',
    label: 'No'
  }
]

const OrganizationMemberForm = ({ id, organization, active, createdAt, name, email = '', role = 'collaborator' }) => {
  const dispatch = useDispatch()

  const submitForm = async (values) => {
    if (id) {
      await dispatch(updateOrganizationMember({ memberId: id, role: values.role, active: JSON.parse(values.active) }))
      return
    }

    await dispatch(inviteUserToOrganization(values.email, values.role, organization))
  }

  const initialValues = id ? { email, role, active: JSON.stringify(active) } : { email, role }

  const { dirty, handleSubmit, getFieldProps, setFieldValue } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        await submitForm(values)
        browserHistory.push(organizationSettingsUrl(organization))
      } catch (e) {
        toast.error(e.message)
      }
    }
  })

  const emailProps = getFieldProps('email')
  const roleProps = getFieldProps('role')
  const activeProps = getFieldProps('active')

  const handleRoleChange = useCallback((value) => {
    setFieldValue('role', value)
  }, [])

  const handleActiveChange = useCallback((value) => {
    setFieldValue('active', value)
  }, [])

  return (
    <form className={cx('modal-content')} onSubmit={handleSubmit}>
      {id ? <UserAccountHeader userFullName={name} joinedDate={createdAt} /> : <h1>Invite new user</h1>}
      {id && (
        <FormField title='Full name'>
          <Input frozen value={name} />
        </FormField>
      )}
      <FormField title='Email address'>
        <Input
          {...emailProps}
          type='email'
          placeholder='Enter an email address to invite'
          frozen={!!id}
        />
      </FormField>
      <FormField title='User role'>
        <Select
          {...roleProps}
          onChange={handleRoleChange}
          options={MEMBER_ROLE_OPTIONS}
        />
      </FormField>
      {id && (
        <FormField title='Active'>
          <Select
            {...activeProps}
            onChange={handleActiveChange}
            options={ACTIVE_OPTIONS}
          />
        </FormField>
      )}
      <Button type='submit' size='small' disabled={!id && !dirty}>
        {id ? 'Update user' : 'Invite user'}
      </Button>
    </form>
  )
}

OrganizationMemberForm.propTypes = {
  email: PropTypes.string,
  role: PropTypes.string,
  organization: PropTypes.string.isRequired,
  active: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.number,
  createdAt: PropTypes.number
}

const OrganizationMemberModal = () => {
  const { orgSlug, id } = useParams()
  const memberId = parseInt(id, 10)

  const organizationMember = useSelector((state) =>
    membersSelector(state).find((member) => member.id === memberId) ?? {}
  )

  const handleClose = useCallback(() => {
    browserHistory.push(organizationSettingsUrl(orgSlug))
  }, [])

  const sendingInvitation = useSelector(getSendingInvitation)
  const updatingOrganization = useSelector(getUpdatingOrganization)

  if (sendingInvitation || updatingOrganization) {
    return (
      <LoadingWithText message={sendingInvitation ? 'Sending invitation...' : 'Updating member...'} />
    )
  }

  return (
    <Modal label='OrganizationMemberModal' onClose={handleClose}>
      <OrganizationMemberForm
        email={organizationMember.email}
        name={organizationMember.name}
        active={organizationMember.active}
        role={organizationMember.role}
        organization={orgSlug}
        id={organizationMember.name ? memberId : undefined}
        createdAt={organizationMember.createdAt}
      />
    </Modal>
  )
}

export default OrganizationMemberModal
