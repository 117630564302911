const SHOW_MODAL = 'APP/ui/layout/SHOW_MODAL'
const HIDE_MODAL = 'APP/ui/layout/HIDE_MODAL'

// actions
export const showModal = (modalOpts) => ({
  type: SHOW_MODAL,
  payload: {
    currentModal: modalOpts
  }
})

export const hideModal = () => ({
  type: HIDE_MODAL,
  payload: { currentModal: null }
})

const initialState = {
  currentModal: null
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SHOW_MODAL:
    case HIDE_MODAL:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
