import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames/bind'
import styles from './Members.scss'
import PropTypes from 'prop-types'
import { Button, Dropdown, DropdownItem, DropdownLink } from '@miroculus/nucleo'
import { LoadingSpinner } from '@miroculus/miroculus-ui'
import { NEW_MEMBER_URL, editMemberUrl } from 'cons/routes'
import { useConfirm, DynamicTable } from 'components'
import {
  loadWorkspaceMembers,
  loadPendingInvites,
  removeInvite,
  MEMBER_ROLES
} from 'reduxModules/workspaces'
import {
  membersSelector,
  getCurrentWorkspace,
  getLoadingMembers
} from 'reduxModules/workspaces/selectors'

const cx = classNames.bind(styles)

const COLUMN_NAMES = ['Name', 'Status', 'Role']

const MemberDropdown = ({ id }) => (
  <Dropdown button='...'>
    <DropdownLink to={editMemberUrl(id)}>
      Edit
    </DropdownLink>
  </Dropdown>
)

const InviteDropdown = ({ id, email, onRemove }) => {
  const { confirm } = useConfirm()

  const handleRemove = () => {
    confirm(
      `Are you sure you want to remove the invitation for ${email}?`,
      () => onRemove(id)
    )
  }

  return (
    <Dropdown button='...'>
      <DropdownItem onClick={handleRemove}>
        Remove
      </DropdownItem>
    </Dropdown>
  )
}

const MemberItem = ({ name, id, email, status, role, onRemove, canEdit = true }) => (
  <tr data-testid={!onRemove ? 'member' : 'pending-invite'}>
    <td>
      <div className={cx('avatar-name')}>
        <div className={cx('avatar')} />
        <div>
          <p className={cx('name')}>{name}</p>
          <p className={cx('email')}>{email}</p>
        </div>
      </div>
    </td>
    <td>{status}</td>
    <td>{MEMBER_ROLES[role]}</td>
    <td>
      {onRemove
        ? <InviteDropdown id={id} email={email} onRemove={onRemove} />
        : canEdit
          ? <MemberDropdown id={id} />
          : undefined}
    </td>
  </tr>
)

export const Members = ({
  members,
  onWorkspaceChange,
  loadingMembers,
  currentUser,
  currentWorkspace,
  onMount,
  onInviteRemove,
  pendingInvites
}) => {
  useEffect(() => {
    onWorkspaceChange()
  }, [currentWorkspace])

  useEffect(() => {
    onMount()
  }, [])

  const memberRowsAndActions = useMemo(() => members.map(({ id, email, name, active, role }) => (
    <MemberItem
      key={id}
      id={id}
      name={name}
      email={email}
      status={active ? 'Active' : 'Inactive'}
      role={role}
      canEdit={email !== currentUser}
    />
  )), [members])

  const pendingInviteRowsAndActions = useMemo(() => pendingInvites.map(({ id, email, role }) => (
    <MemberItem
      key={email}
      id={id}
      name={email}
      email={email}
      status='Invited'
      role={role}
      onRemove={onInviteRemove}
    />
  )), [pendingInvites])

  return (
    <div className={cx('container')}>
      {loadingMembers
        ? <LoadingSpinner message='Loading users...' />
        : (
          <>
            <div className={cx('title')}>
              <h1>Members</h1>
              <Button to={NEW_MEMBER_URL} size='small'>
                <span aria-hidden>+</span> New member
              </Button>
            </div>
            <DynamicTable
              columnNames={COLUMN_NAMES}
              body={[memberRowsAndActions, pendingInviteRowsAndActions]}
              withActions
            />
          </>
          )}
    </div>
  )
}

Members.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string
      }),
      id: PropTypes.number
    })
  ),
  currentUser: PropTypes.string
}

Members.defaultProps = {
  members: [],
  pendingInvites: [],
  loadingMembers: false,
  currentUser: ''
}

const mapStateToProps = (state) => ({
  members: membersSelector(state),
  loadingMembers: getLoadingMembers(state),
  currentUser: state.auth.user.email,
  currentWorkspace: getCurrentWorkspace(state),
  pendingInvites: state.workspaces.pendingInvites
})

const mapDispatchToProps = dispatch => ({
  onWorkspaceChange: () => {
    dispatch(loadWorkspaceMembers())
  },
  onInviteRemove: async (id) => {
    await dispatch(removeInvite(id))
    await dispatch(loadPendingInvites())
  },
  onMount: () => {
    dispatch(loadPendingInvites())
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(Members)
