import 'regenerator-runtime/runtime'
import '@miroculus/nucleo/static/fonts/Montserrat.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'app.scss'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ToastContainer, toast, Flip } from 'react-toastify'
import { Link, Router } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import apiClient from '@miroculus/api-client'
import { Context as MiroculusUIContext } from '@miroculus/miroculus-ui'
import { NucleoProvider, GlobalStyles } from '@miroculus/nucleo'
import socketClient from 'socketClient'
import { initMessage } from 'remoteMessage'
import history from 'browserHistory'
import configureStore from './store/configureStore'
import Routes from './routes'
import { LOGOUT_URL } from 'cons/routes'
import { initNotifications } from 'notifications'
import { updateCameraId } from 'reduxModules/settings'
import { addAPIHealthData } from 'reduxModules/log'
import InjectCSSVariables from './utils/InjectCSSVariables'
import { ConfirmContainer, ConfirmProvider, BreadcrumbProvider } from 'components'

const SENTRY_URL = process.env.SENTRY_URL
const API_URL = process.env.API_URL

if (SENTRY_URL) {
  const BUILD_VERSION = process.env.BUILD_VERSION || 'N/A'
  const environment = process.env.BUILD_VERSION
    ? process.env.BUILD_VERSION.split('.')[0]
    : 'development'

  Sentry.init({
    dsn: SENTRY_URL,
    release: BUILD_VERSION,
    environment
  })
}

apiClient.init({
  apiUrl: API_URL,
  encryptionKey: window.navigator.userAgent,
  onLogout: () => {
    history.push(LOGOUT_URL)
  }
})

// set up the initial redux state with the user, if any
const user = apiClient.getUser()
const auth = user
  ? { user, logged: true, loading: true }
  : undefined

const store = configureStore({ auth })

apiClient.head('/health').then(({ res }) => {
  const data = {}
  res.headers.forEach((value, header) => {
    if (header.startsWith('x-miroculus-')) {
      const key = header.replace('x-miroculus-', '').replace('-', ' ')
      data[key] = value
    }
  })
  store.dispatch(addAPIHealthData(data))
})

initNotifications()
initMessage(store)
socketClient.init()

const storedCameraId = apiClient.storage.get('cameraId')
if (storedCameraId) {
  store.dispatch(updateCameraId(storedCameraId))
}

render(
  <Provider store={store}>
    <InjectCSSVariables />
    <GlobalStyles />
    <MiroculusUIContext.Provider value={{ LinkComponent: Link }}>
      <NucleoProvider value={{ LinkComponent: Link }}>
        <ConfirmProvider>
          <BreadcrumbProvider>
            <Router history={history}>
              <Routes />
              <ToastContainer transition={Flip} />
              <ConfirmContainer />
            </Router>
          </BreadcrumbProvider>
        </ConfirmProvider>
      </NucleoProvider>
    </MiroculusUIContext.Provider>
  </Provider>,
  document.getElementById('root')
)

if (window.Cypress) {
  window.store = store
  window.apiClient = apiClient
}

if (process.env.NODE_ENV === 'development') {
  // To debug toast notifications!
  window.toast = toast
}
