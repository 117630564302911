import apiClient from '@miroculus/api-client'
import {
  INIT_RUNS,
  DATA_ADD_VALUE,
  DATA_ADD_VALUES,
  DATA_TOGGLE_TRACK,
  DATA_TOGGLE_VARIABLE,
  DATA_RESET_TEMP_DATA,
  DATA_SET_SOURCES_TEMP,
  DATA_SET_HV
} from './actions'

import {
  calculateTable,
  getDataSource,
  getFilteredVariables,
  addCurrentRun,
  addRunData,
  addRunDatas
} from './utils'

export const DEVICE_HV_STATUS = {
  HIGH: 'high',
  LOW: 'low',
  OFF: 'off'
}

const initialState = {
  temp: {
    dataTable: [[]],
    sources: [],
    runs: [],
    variables: {},
    tracking: true,
    trackInterval: 1000,
    maxDatapoints: 100
  },
  optics: {
    dataTable: [[]],
    sources: [
      { name: 'detector1', color: 112, active: true, lastValue: 0, tracking: true },
      { name: 'detector2', color: 184, active: true, lastValue: 0, tracking: true }
    ],
    runs: [],
    variables: {},
    tracking: false,
    trackInterval: 1000,
    maxDatapoints: 100
  }
}

export default function data (state = initialState, action = {}) {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case DATA_SET_SOURCES_TEMP: {
      newState.temp.sources = action.sources
      return newState
    }
    case INIT_RUNS: {
      const variables = {
        opticsVariables: {},
        tempVariables: {}
      }
      let runs = []
      const response = addCurrentRun(newState, variables, runs)
      newState = response.state
      runs = response.runs
      newState.temp = getDataSource(state, 'temp', runs, variables)
      newState.optics = getDataSource(state, 'optics', runs, variables)
      // Preserve tracking checkbox between runs
      newState.temp.tracking = state.temp.tracking
      return newState
    }
    case DATA_TOGGLE_VARIABLE: {
      const dataType = newState[action.source]
      dataType.sources = dataType.sources.map((source) => {
        if (source.name === action.variable) source.active = !source.active
        return source
      })
      const filteredVariables = getFilteredVariables(dataType)
      newState[action.source].dataTable = calculateTable(filteredVariables)
      return newState
    }
    case DATA_ADD_VALUE: {
      const dataType = newState[action.source]
      newState = addRunData(dataType, newState, action)

      // we don't save data if we're not running under a experiment
      if (!action.experiment || !action.experiment.currentExperiment.id) return newState

      // we don't save data if there's no script running
      if (!action.cocoscriptStatus || action.cocoscriptStatus !== 1) return newState

      // value of the index of the last point saved
      const currentIndex = newState[action.source].dataTable[newState[action.source].dataTable.length - 1][0]

      // send data to backend only if it reaches a limit ended with "00" (100, 200... 1.000, 25.400)
      // we can send repeated points, backend will handle. Also, we check if there is a current experiment
      // and a run going on (We don't save points when there's any of these)
      if (!currentIndex.toString().endsWith('00')) return newState

      const experimentId = action.experiment.currentExperiment.id
      const runId = action.experiment.currentRun.id
      if (!experimentId || !runId) return newState

      // this api must be called once...
      const table = newState[action.source].dataTable
      const lastValue = table[table.length - 1]
      let readyToSave = true
      for (let i = 0; i < lastValue.length; i++) {
        readyToSave = readyToSave && lastValue[i]
      }
      if (!readyToSave) return newState

      apiClient.put(`experiments/${experimentId}/${runId}`, {
        results: newState[action.source].dataTable.slice(1)
      })

      return newState
    }
    case DATA_ADD_VALUES: {
      const dataType = newState[action.source]
      newState = addRunDatas(dataType, newState, action)
      return newState
    }
    case DATA_TOGGLE_TRACK: {
      newState[action.source].tracking = !newState[action.source].tracking
      return newState
    }
    case DATA_RESET_TEMP_DATA:
      return {
        ...initialState
      }
    case DATA_SET_HV:
      return {
        ...newState,
        hv: action.hv
      }
    default: {
      return newState
    }
  }
}
