import React, { createContext, useContext, useState, useEffect } from 'react'

const initialLevels = []

export const BreadcrumbContext = createContext(initialLevels)

export const BreadcrumbProvider = ({ children }) => {
  const [levels, setLevels] = useState(initialLevels)

  return (
    <BreadcrumbContext.Provider
      value={{
        levels,
        updateBreadcrumb: setLevels
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  )
}

export const useBreadcrumb = (levels, deps = []) => {
  const context = useContext(BreadcrumbContext)

  if (!context) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider')
  }

  useEffect(() => context.updateBreadcrumb(levels), deps)
}
