import apiClient from '@miroculus/api-client'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery, useProtocolFilters } from 'hooks'
import PropTypes from 'prop-types'
import { LoadingSpinner } from '@miroculus/miroculus-ui'
import { Button, Tabs } from '@miroculus/nucleo'
import classnames from 'classnames/bind'
import {
  loadProtocols,
  deleteProtocol,
  unsubscribeToProtocols,
  loadSupportedProtocols,
  loadProtocolTypes,
  loadMiroLayoutId
} from 'reduxModules/protocols'
import {
  ProtocolList,
  useConfirm,
  ProtocolFilters,
  useBreadcrumb,
  teamShape,
  organizationShape,
  Walkthrough,
  HelpButton
} from 'components'
import {
  getProtocolsToDisplay,
  getUsedProtocolTypes
} from 'reduxModules/protocols/selectors'
import { findTeamById } from 'reduxModules/workspaces/selectors'
import { findOrganizationByTeamId } from 'reduxModules/organizations/selectors'
import { editProtocolUrl } from 'cons/routes'
import styles from './ProtocolSelection.scss'
import { TemplatesWalkthrough } from './ProtocolSelection.walkthrough'

const cx = classnames.bind(styles)

const TABS_TITLES = ['Private', 'Shared', 'Templates']
const getTabIndex = (visibility = 'private') => {
  const currentTabIndex = TABS_TITLES.map(title => title.toLowerCase()).indexOf(visibility)

  return (currentTabIndex === -1 ? 0 : currentTabIndex)
}

export const ProtocolSelection = memo(({
  loading,
  protocols,
  sendingProtocol,
  types,
  onDeleteClick,
  onMount,
  onSupportedProtocolsLoad,
  subscribeToTeam,
  unsubscribeFromTeam,
  organizations,
  teams
}) => {
  const history = useHistory()
  const visibilityParam = useQuery().get('visibility')

  const {
    filteredProtocols, setDateRange, setSearchText, setSelectedType,
    dateRange, searchText, selectedType
  } = useProtocolFilters(protocols)
  const walkthroughs = apiClient.storage.get('walkthroughs') || {}
  const [tabIndex, setTabIndex] = useState(getTabIndex(visibilityParam))
  const [templatesWalkthroughComplete, setTemplatesWalkthroughComplete] = useState(walkthroughs?.templates?.complete)

  const { teamId } = useParams()

  const changeSelectedType = (newSelectedType) => {
    setSelectedType(newSelectedType?.value ?? 'All types')
  }

  const changeSearchText = (event) => {
    setSearchText(event?.target?.value)
  }

  useEffect(() => {
    onMount()
  }, [])

  useEffect(() => {
    if (tabIndex < 2) {
      const protocolVisibility = ['private', 'shared'][tabIndex]
      history.replace(`?visibility=${protocolVisibility}`)

      subscribeToTeam(teamId, protocolVisibility)
      return () => {
        unsubscribeFromTeam(teamId, protocolVisibility)
      }
    } else {
      history.replace('?visibility=templates')
      onSupportedProtocolsLoad(organization.slug)
    }
  }, [teamId, tabIndex])

  const team = useMemo(() => findTeamById(teams, teamId), [teamId, teams])
  const teamName = team?.title
  const organization = useMemo(
    () => findOrganizationByTeamId(organizations, teamId),
    [teamId, organizations]
  )

  useBreadcrumb([
    {
      text: organization?.name,
      href: `/organization/${organization?.slug}`
    },
    {
      text: teamName ?? teamId
    }
  ], [organization, teamId])

  const { confirm } = useConfirm()

  const handleDelete = useCallback((id) => {
    const { name } = filteredProtocols.find(p => p.id === id)
    confirm(
      `Are you sure you want to delete the "${name}" protocol?`,
      () => onDeleteClick(id)
    )
  }, [filteredProtocols])

  const handleTemplateWalkthroughFinish = () => {
    apiClient.storage.set('walkthroughs', { ...walkthroughs, templates: { complete: true } })
    setTemplatesWalkthroughComplete(true)
  }

  const handleHelpButtonClick = () => {
    setTemplatesWalkthroughComplete(false)
  }

  const renderProtocolList = (protocolList) => (
    <ProtocolList
      protocols={protocolList}
      onDeleteClick={handleDelete}
    />
  )

  return (
    <div className={cx('container')}>
      {visibilityParam === 'templates' && !loading && !templatesWalkthroughComplete && (
        <Walkthrough steps={TemplatesWalkthrough} onFinish={handleTemplateWalkthroughFinish} disableScrolling />
      )}
      {sendingProtocol && (
        <LoadingSpinner message='Sending protocol to device...' />
      )}
      {loading && <LoadingSpinner message='Loading protocols...' />}
      <div className={cx('body')}>
        <div className={cx('title')}>
          <h1>Protocols</h1>
          {team?.$permissions?.canCreateProtocol && (
            <Button
              to={editProtocolUrl(teamId)}
              size='small'
              data-testid='new-protocol-button'
            >
              <span aria-hidden>+</span> New Protocol
            </Button>
          )}
        </div>
        <ProtocolFilters
          searchText={searchText}
          selectedType={selectedType}
          types={types}
          onSearchTextChange={changeSearchText}
          onTypeChange={changeSelectedType}
          dateRange={dateRange}
          onDateRangeChange={setDateRange}
        />
        <Tabs defaultValue={tabIndex} titles={TABS_TITLES} onChange={setTabIndex}>
          {!loading && renderProtocolList(tabIndex === 0 ? filteredProtocols : [])}
          {!loading && renderProtocolList(tabIndex === 1 ? filteredProtocols : [])}
          {!loading && (
            <div className={cx('templatesList')}>
              <div className={cx('helpButton')} data-testid='help-button'>
                <HelpButton onClick={handleHelpButtonClick} />
              </div>
              <ProtocolList
                protocols={tabIndex === 2 ? filteredProtocols : []}
                team={teamId}
              />
            </div>
          )}
        </Tabs>
      </div>
    </div>
  )
})

ProtocolSelection.propTypes = {
  protocols: PropTypes.array,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  onDeleteClick: PropTypes.func.isRequired,
  subscribeToTeam: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onSupportedProtocolsLoad: PropTypes.func.isRequired,
  unsubscribeFromTeam: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  sendingProtocol: PropTypes.bool,
  organizations: PropTypes.arrayOf(organizationShape),
  teams: PropTypes.arrayOf(teamShape).isRequired
}

ProtocolSelection.defaultProps = {
  protocols: [],
  teams: [],
  organizations: [],
  currentTeamName: '',
  loading: false,
  sendingProtocol: false,
  onMount: () => { /** intentional */ },
  onSupportedProtocolsLoad: () => { /** intentional */ },
  subscribeToTeam: () => { /** intentional */ },
  unsubscribeFromTeam: () => { /** intentional */ }
}

const mapStateToProps = (state) => {
  const protocols = getProtocolsToDisplay(state)
  const { workspaces: { workspaces: teams }, organizations: { organizations } } = state

  return {
    protocols,
    types: getUsedProtocolTypes(state),
    selectedType: state.protocols.selectedProtocolType,
    loading: state.protocols.loading,
    sendingProtocol: state.protocols.sendingProtocol,
    organizations,
    teams
  }
}

const mapDispatchToProps = (dispatch) => ({
  onDeleteClick: (id) => {
    dispatch(deleteProtocol(id))
  },
  onMount: () => {
    dispatch(loadProtocolTypes())
    dispatch(loadMiroLayoutId())
  },
  onSupportedProtocolsLoad: (organization) => {
    dispatch(loadSupportedProtocols(organization))
  },
  subscribeToTeam: (teamId, visibility) =>
    dispatch(loadProtocols(teamId, visibility)),
  unsubscribeFromTeam: (teamId, visibility) =>
    dispatch(unsubscribeToProtocols(teamId, visibility))
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(ProtocolSelection)
