import { insertNotifyMeEmail } from 'utils'
import socketClient from 'socketClient'
import apiClient from '@miroculus/api-client'
import {
  TYPE_SCRIPT
} from 'cons'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'
import { updateMoveBackbone } from '../electrodeLayout'

export const SCRIPT_UPDATE = 'anaconda-web/codeEditor/SCRIPT_UPDATE'
export const SCRIPT_PLAY = 'anaconda-web/codeEditor/SCRIPT_PLAY'
export const SCRIPT_LINE_EXECUTING = 'anaconda-web/codeEditor/SCRIPT_LINE_EXECUTING'
export const SCRIPT_LINE_ERROR = 'anaconda-web/codeEditor/SCRIPT_LINE_ERROR'
export const SCRIPT_LINE_DONE = 'anaconda-web/codeEditor/SCRIPT_LINE_DONE'
export const SCRIPT_ENDS = 'anaconda-web/codeEditor/SCRIPT_ENDS'
export const SCRIPT_TOGGLE_THEME = 'anaconda-web/codeEditor/SCRIPT_TOGGLE_THEME'
export const EDITOR_UPDATE_STATUS = 'anaconda-web/codeEditor/EDITOR_UPDATE_STATUS'
export const UPDATE_HINTS = 'anaconda-web/codeEditor/UPDATE_HINTS'

const SCRIPT_STATUS_STOP = 'stop'

export const IDE_THEMES = {
  light: 'base16-light',
  dark: 'base16-dark'
}

export const LINE_STATUS = {
  RUNNING: 1,
  DONE: 2,
  ERROR: 3
}

export const EDITOR_STATUS = {
  RUNNING: 1,
  DONE: 2,
  STOP: 3,
  PAUSED: 4,
  ERROR: 5
}

export const loadHints = () => async (dispatch) => {
  try {
    const { body } = await apiClient.get('/cocoscript/hints')
    dispatch(updateHints([body]))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export function changeScript (script, change) {
  return (dispatch, getState) => {
    dispatch(updateScript(script))
  }
}

export function onScriptPlay () {
  return (dispatch, getState) => {
    // Grab the script from the state
    const script = getState().codeEditor.script
    const { email } = getState().auth.user

    // Update state script without the notifyMe emails
    dispatch(updateScript(script))
    dispatch(playScript())

    // Play new script
    const updatedScript = insertNotifyMeEmail(script, email)
    socketClient.sendMessage({
      script: updatedScript,
      type: TYPE_SCRIPT
    })
  }
}

export const stopScript = () => (dispatch, getState) => {
  // clear moveBackbone
  dispatch(updateMoveBackbone([], 0))

  socketClient.sendMessage({
    type: TYPE_SCRIPT,
    status: SCRIPT_STATUS_STOP
  })
}

export const sendScriptAsQC = () => async (_, getState) => {
  const { auth, codeEditor, devices } = getState()
  const cocoscript = insertNotifyMeEmail(codeEditor.script, auth.user.email)
  const deviceId = devices.selectedDeviceId

  let name = 'QC script'
  const nameRegex = /\/\/ ?name:(.*)/gmi
  const nameResult = nameRegex.exec(cocoscript)

  if (nameResult) {
    name = nameResult[1].trim()
  }

  try {
    await apiClient.post('/cocoscript/send', {
      cocoscript,
      deviceId,
      name
    })
  } catch (e) {
    Sentry.captureException(e)
    if (e.status) {
      toast.error(`Error sending script: ${e.message} (${e.status})`)
    }
  }
}

export const sendScriptAsProtocol = (name) => async (_, getState) => {
  const { auth, codeEditor, devices } = getState()
  const cocoscript = insertNotifyMeEmail(codeEditor.script, auth.user.email)
  const deviceId = devices.selectedDeviceId

  try {
    await apiClient.post('/cocoscript/send-as-protocol', {
      cocoscript,
      deviceId,
      name
    })
    toast.success('Script sent as protocol succesfully')
  } catch (e) {
    Sentry.captureException(e)
    if (e.status) {
      toast.error(`Error sending script: ${e.message} (${e.status})`)
    }
  }
}

// actions
export function playScript () {
  return {
    type: SCRIPT_PLAY
  }
}

export function updateScript (script) {
  return {
    type: SCRIPT_UPDATE,
    payload: { script }
  }
}

export function updateEditorStatus (status) {
  return {
    type: EDITOR_UPDATE_STATUS,
    payload: { status }
  }
}

export function onExecutingLine (line, timestamp) {
  return {
    type: SCRIPT_LINE_EXECUTING,
    line,
    timestamp
  }
}

export function onLineError (err, line, timestamp) {
  return {
    type: SCRIPT_LINE_ERROR,
    err,
    line,
    timestamp
  }
}

export function onLineDone (line, timestamp) {
  return {
    type: SCRIPT_LINE_DONE,
    line,
    timestamp
  }
}

export function onScriptEnds (timestamp) {
  return {
    type: SCRIPT_ENDS,
    timestamp
  }
}

export function toggleEditorTheme () {
  return {
    type: SCRIPT_TOGGLE_THEME
  }
}
export const updateHints = (hints) => ({
  type: UPDATE_HINTS,
  payload: { hints }
})

const defaultState = {
  script: '',
  currentLine: {
    number: -1,
    status: 0,
    timestamp: 0
  },
  status: 0,
  statusTimestamp: 0,
  theme: IDE_THEMES.light,
  hints: []
}

export default function cocoscriptHandler (state = defaultState, action = {}) {
  const newState = { ...state }
  const { payload, type } = action
  const editorIsRunning = state.status === EDITOR_STATUS.RUNNING
  switch (type) {
    case SCRIPT_TOGGLE_THEME:
      return {
        ...state,
        theme: state.theme === IDE_THEMES.light ? IDE_THEMES.dark : IDE_THEMES.light
      }
    case SCRIPT_PLAY:
      return ({
        ...state,
        status: EDITOR_STATUS.RUNNING
      })
    case SCRIPT_LINE_EXECUTING:
      if (action.timestamp >= newState.currentLine.timestamp && editorIsRunning) {
        newState.currentLine = {
          number: action.line,
          status: LINE_STATUS.RUNNING,
          timestamp: action.timestamp
        }
      }
      return newState
    case SCRIPT_LINE_ERROR:
      if (action.timestamp >= newState.currentLine.timestamp && editorIsRunning) {
        newState.currentLine = {
          number: action.line,
          status: LINE_STATUS.ERROR,
          timestamp: action.timestamp
        }
        newState.status = EDITOR_STATUS.ERROR
      }
      return newState
    case SCRIPT_LINE_DONE:
      if (action.timestamp >= newState.currentLine.timestamp && editorIsRunning) {
        newState.currentLine = {
          number: action.line,
          status: LINE_STATUS.DONE,
          timestamp: action.timestamp
        }
      }
      return newState
    case SCRIPT_ENDS:
      if (action.timestamp >= newState.statusTimestamp) {
        return {
          ...state,
          status: EDITOR_STATUS.DONE,
          statusTimestamp: action.timestamp,
          currentLine: {
            number: -1,
            status: 0,
            timestamp: action.timestamp
          }
        }
      }
      return newState
    case EDITOR_UPDATE_STATUS:
    case SCRIPT_UPDATE:
    case UPDATE_HINTS:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
