import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Input, Modal, TextArea } from '@miroculus/nucleo'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames/bind'
import { FormField, DatePickerField } from 'components'
import styles from './RunDetails.scss'
import { updateRunInformation, loadRunInformation } from 'reduxModules/runs'
import { currentRunSelector } from 'reduxModules/runs/selectors'
import { instrumentDetailsUrl } from 'cons/routes'
import { secondsToTimeString, getFormattedDate } from 'utils'

const cx = classnames.bind(styles)

const StaticField = ({ label, value }) => (
  <div className={cx('staticField')}>
    <div className={cx('label')}>{label}</div>
    <div className={cx('value')}>{value}</div>
  </div>
)

function RunDetailsForm ({
  cartridgeType,
  deviceId,
  errors,
  experimentStatus,
  getFieldProps,
  handleClose,
  handleSubmit,
  protocolName,
  protocolVersion,
  recoveryStatus,
  startedAt,
  status,
  setFieldValue,
  totalTime,
  userName,
  submitting
}) {
  const sampleIdProps = getFieldProps('sampleId')
  const batchInformationProps = getFieldProps('batchInformation')
  const cartridgeIdProps = getFieldProps('cartridgeId')
  const reagentLotProps = getFieldProps('reagentLot')
  const reagentExpirationDateProps = getFieldProps('reagentExpirationDate')
  const descriptionProps = getFieldProps('description')

  const handleReagentExpirationDateChange = value => setFieldValue('reagentExpirationDate', value)

  return (
    <form className={cx('form')} onSubmit={handleSubmit}>
      <div className={cx('staticFieldsContainer')}>
        <StaticField label='Date' value={getFormattedDate(startedAt)} />
        <StaticField label='Device ID' value={deviceId} />
        <StaticField label='Protocol Name' value={protocolName} />
        <StaticField label='Protocol Version' value={protocolVersion} />
        <StaticField label='Cartridge Type' value={cartridgeType} />
        <StaticField label='Status' value={experimentStatus} />
        <StaticField label='Recovery Status' value={recoveryStatus} />
        <StaticField label='Total Time' value={secondsToTimeString(totalTime)} />
        <StaticField label='User Name' value={userName} />
      </div>
      <FormField title='Sample ID'>
        <div className={cx('field')}>
          <Input
            {...sampleIdProps}
          />
        </div>
      </FormField>
      <FormField title='Batch Information'>
        <div className={cx('field')}>
          <Input
            {...batchInformationProps}
          />
        </div>
      </FormField>
      <FormField title='Cartridge ID'>
        <div className={cx('field')}>
          <Input
            {...cartridgeIdProps}
          />
        </div>
      </FormField>
      <FormField title='Reagent Lot'>
        <div className={cx('field')}>
          <Input
            {...reagentLotProps}
          />
        </div>
      </FormField>
      <FormField title='Reagent Expiration Date'>
        <div className={cx('field')}>
          <DatePickerField
            {...reagentExpirationDateProps}
            selected={reagentExpirationDateProps?.value}
            onChange={handleReagentExpirationDateChange}
          />
        </div>
      </FormField>
      <FormField title='Description'>
        <TextArea
          {...descriptionProps}
          maxLength={640}
        />
      </FormField>
      <section className={cx('buttons')}>
        <Button
          flavor='secondary'
          size='small'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          size='small'
          disabled={submitting}
        >
          {submitting ? 'Saving...' : 'Save'}
        </Button>
      </section>
    </form>
  )
}

const Schema = Yup.object().shape({
  sampleId: Yup.string(),
  batchInformation: Yup.string(),
  cartridgeId: Yup.string(),
  reagentLot: Yup.string(),
  reagentExpirationDate: Yup.string(),
  description: Yup.string()
})

const RunDetails = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading, saving } = useSelector((state) => state.runs)
  const { orgSlug, deviceId, runId } = useParams()

  useEffect(() => {
    dispatch(loadRunInformation(deviceId, runId))
  }, [dispatch, runId])

  const runDetails = useSelector(currentRunSelector)

  if (!runDetails) {
    return (
      <div>Not Found</div>
    )
  }

  const {
    errors,
    status,
    handleSubmit,
    getFieldProps,
    setFieldValue
  } = useFormik({
    initialValues: {
      sampleId: runDetails.sampleId,
      batchInformation: runDetails.batchInformation,
      cartridgeId: runDetails.cartridgeId,
      reagentLot: runDetails.reagentLot,
      reagentExpirationDate: runDetails.reagentExpirationDate,
      description: runDetails.description
    },
    onSubmit: async (values) => {
      await dispatch(updateRunInformation({ values, orgSlug, deviceId }))
    },
    validationSchema: Schema
  })

  const handleClose = () => {
    history.push(instrumentDetailsUrl(orgSlug, deviceId))
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Modal label='RunDetails' onClose={handleClose}>
      <h1>Run details</h1>
      <RunDetailsForm
        errors={errors}
        getFieldProps={getFieldProps}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        handleClose={handleClose}
        recoveryStatus={runDetails.recoveryStatus}
        deviceId={runDetails.deviceId}
        status={status}
        experimentStatus={runDetails.status}
        totalTime={runDetails.totalTime}
        startedAt={runDetails.startedAt}
        userName={runDetails.user.name}
        protocolName={runDetails.protocol.name}
        protocolVersion={runDetails.protocol.version}
        cartridgeType={runDetails.cartridge.type}
        submitting={saving}
      />
    </Modal>
  )
}

export default RunDetails
