// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DynamicTable_container__1Ql-D table{margin-top:3rem;width:100%;border-collapse:collapse;color:var(--color-control-text);table-layout:fixed}.DynamicTable_container__1Ql-D table th,.DynamicTable_container__1Ql-D table td{padding:1rem 0}.DynamicTable_container__1Ql-D table th{text-align:left;text-transform:uppercase;letter-spacing:1px}.DynamicTable_container__1Ql-D table th.DynamicTable_table-head-actions__nTwrd{width:11rem}.DynamicTable_container__1Ql-D table tbody tr{border-top:1px solid #ddd}", ""]);
// Exports
exports.locals = {
	"container": "DynamicTable_container__1Ql-D",
	"table-head-actions": "DynamicTable_table-head-actions__nTwrd"
};
module.exports = exports;
