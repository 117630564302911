import isAbsoluteUrl from 'is-absolute-url'

/**
 * This function leaves only the first parameter passed to the notifyMe() fn call.
 * @param {string} string coco script
 * @returns {string} coco script without the notifyMe second param
 */
export const cleanNotifyMe = (string) =>
  string.replace(/notifyMe\((.*)\)/gm, (match, paramsMatch) => {
    if (!paramsMatch.trim()) return match
    const params = paramsMatch.split(',').map(str => str.trim())
    if (params.length <= 1) return match
    return `notifyMe(${params[0]})`
  })

export const secondsToTime = (secs) => {
  secs = Math.round(secs)
  const hours = Math.floor(secs / (60 * 60))

  const divisorForMinutes = secs % (60 * 60)
  const minutes = Math.floor(divisorForMinutes / 60)

  const divisorForSeconds = divisorForMinutes % 60
  const seconds = Math.ceil(divisorForSeconds)

  const obj = {
    h: hours < 10 ? `0${hours}` : hours,
    m: minutes < 10 ? `0${minutes}` : minutes,
    s: seconds < 10 ? `0${seconds}` : seconds
  }
  return obj
}

export function secondsToTimeString (secs) {
  const { h, m, s } = secondsToTime(secs)
  return `${h}:${m}:${s}`
}

export const insertNotifyMeEmail = (script, email) => {
  const NOTIFY_REGEX = /notifyMe\(.*\);?/g
  let updatedScript = script
  if (NOTIFY_REGEX.test(script)) {
    const notifyMeSubstrings = updatedScript.match(NOTIFY_REGEX)
    notifyMeSubstrings.forEach(match => {
      const newValue = match.substring(0, match.length - 1) + ', "' + email + '")'
      updatedScript = updatedScript.replace(match, newValue)
    })
  }
  return updatedScript
}

export const parseJSON = (str) => {
  try {
    return JSON.parse(str)
  } catch (_) {
    return undefined
  }
}

/**
 * Returns the first param decoded if it's provided and is a relative url,
 * else returns the second param, a fallback url
 */
export function getRedirectUrl (redirectTo, fallbackUrl) {
  if (!redirectTo) return fallbackUrl

  const decoded = decodeURIComponent(redirectTo)
  return isAbsoluteUrl(decoded) ? fallbackUrl : decoded
}

/**
 * Return the Unified Version™ of the instrument, mixing all the components
 * into a single string (this function was copied over from anaconda-pi)
 */
export function unifiedVersion (anacondaPi = 'x', conda = 'x', fw = 'x') {
  const [aMajor, aMinor, aPatch] = anacondaPi.split('.')
  const [cMajor, cMinor, cPatch] = conda.split('.')
  let diff = ''
  if (cMajor !== aMajor) {
    diff = conda
  } else if (cMinor !== aMinor) {
    diff = `${cMinor}.${cPatch}`
  } else if (cPatch !== aPatch) {
    diff = `${cPatch}`
  }
  diff = diff && `-${diff}`
  return `${anacondaPi}${diff}/${fw.replace(/^0\./, '')}`
}

/**
 * Get string date in this format: MM.DD.YYYY
 * @param {number} timestamp
 */
export function getFormattedDate (timestamp) {
  const date = new Date(timestamp)
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}
