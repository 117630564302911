import React, {
  memo,
  useEffect
} from 'react'
import {
  useSelector,
  useDispatch
} from 'react-redux'
import { getLogged } from 'reduxModules/auth/selectors'
import { Redirect } from 'react-router-dom'
import apiClient from '@miroculus/api-client'
import { Spinner } from 'components'
import { LOGIN_URL } from 'cons/routes'
import { redeemInvitation } from 'reduxModules/organizations'
import { useQuery } from 'hooks'

const OrganizationInvite = memo(({ onInvite }) => {
  const query = useQuery()
  const logged = useSelector(getLogged)
  const inviteToken = query.get('inviteToken')
  const email = query.get('email')
  const dispatch = useDispatch()

  useEffect(() => {
    if (logged) {
      dispatch(redeemInvitation(inviteToken, email))
    } else {
      window.location.replace(
        apiClient.getOrganizationInviteRedeemUrl({ inviteToken, email })
      )
    }
  }, [])

  if (!inviteToken || !email) return <Redirect to={LOGIN_URL} />

  return (
    <Spinner />
  )
})

export default OrganizationInvite
