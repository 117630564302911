import { PROTOCOL_PENDING_STATUS } from 'cons'
import { graphToDocument } from '@miroculus/nucleo'
import { uniqBy } from 'ramda'

/*
  The keys of this object match with the
  stored names in the API DB
*/
const CARTRIDGE_NAME_MAP = {
  Miro: 'revA',
  Canvas: 'revB'
}

export const currentProtocolSelector = ({ protocols }) =>
  protocols.currentProtocol

/**
 * Gets the reagents that this protocol uses, with aggregated volume
 * @param {AppState} state Redux store state
 */
export const getProtocolReagents = ({ protocols }) => Object.values(
  protocols.currentProtocol.graph.actions
    .filter(a => a.type === 'element')
    .reduce((reagentMap, { description, volume = 0, jacketVol = 0 }) => ({
      ...reagentMap,
      [description]: {
        description,
        jacketVol: jacketVol + (reagentMap[description]?.jacketVol ?? 0),
        volume: volume + (reagentMap[description]?.volume ?? 0)
      }
    }), {})
)

/**
 * Gets the reagents that this protocol uses + reagents added by the user
 * in this session
 * @param {AppState} state Redux store state
 */
export const getReagents = ({ protocols }) => uniqBy(
  ({ name }) => name,
  [
    ...protocols.currentProtocol.graph.actions
      .filter(a => a.type === 'element')
      .map(({ description }) => ({ name: description })),
    ...protocols.currentProtocol.library.reagents
  ])

/**
 * Gets the action modules stored in the state (currently loaded per-workspace)
 * @param {AppState} state Redux store state
 */
export const getActionModules = ({ protocols }) =>
  protocols.currentProtocol.library.actionModules

export const protocolTimeSelector = ({ protocols }) => {
  if (protocols.currentProtocol.time) return protocols.currentProtocol.time

  if (!protocols.currentProtocol.graph) return 0

  let total = 0

  protocols.currentProtocol.graph.actions.forEach(action => {
    const cyclesFactor = action.cycles || 1
    total = total +
        cyclesFactor * (action.time || 0) +
        cyclesFactor * (action.washTime || 0) +
        cyclesFactor * (action.incuTime || 0) +
        cyclesFactor * (action.dryTime || 0)

    // Thermocycle
    if (action.steps) {
      total = total + (action.steps.map(step => step.time).reduce((a, b) => (a + b)) * cyclesFactor)
    }
  })

  return total
}

export const paletteDocSelector = ({ protocols }) => graphToDocument(protocols.currentProtocol.graph)

export const getProtocolValidationErrors = ({ protocols }) =>
  protocols.currentProtocol.validationErrors

export const getProtocolErrorCycleIndex = ({ protocols }) =>
  protocols.currentProtocol.errorCycleIndex

// a protocol is considered "original" if it was generated from a template
// (aka "dynamic labscript") and hasn't been modified since then
// NOTE: we assume that if the updatedAt and createdAt are close enough in time
// then it was never updated
const isOriginal = protocol =>
  protocol.template && Math.abs(protocol.updatedAt - protocol.createdAt) < 1000

export const getProtocolsToDisplay = (state) => {
  const { filteredProtocols } = state.protocols
  return filteredProtocols
    .map(protocol => ({
      ...protocol.$permissions,
      type: protocol.type,
      id: protocol.id,
      name: protocol.name,
      time: protocol.time,
      createdAt: protocol.createdAt,
      cartridge: CARTRIDGE_NAME_MAP[protocol.cartridge.name] || protocol.cartridge.name,
      author: protocol.author,
      solutionStatus: protocol.solutionStatus || PROTOCOL_PENDING_STATUS,
      version: protocol.version,
      team: protocol.team,
      visibility: protocol.visibility,
      original: isOriginal(protocol)
    }))
}

export const getUsedProtocolTypes = (state) => {
  const { protocols, protocolTypes } = state.protocols
  const usedTypes = protocols.reduce((set, p) => set.add(p.type.id), new Set())
  return protocolTypes.filter(t => usedTypes.has(t.value))
}

export const pathsSelector = ({ protocols }) => {
  if (
    !protocols.currentProtocol.solution ||
    !protocols.currentProtocol.solution.paths
  ) {
    return []
  }
  const { paths } = protocols.currentProtocol.solution
  return paths.map((path, index) => {
    return path.map(electrode => [electrode, index])
  }).reduce((a, b) => a.concat(b))
}

/**
 * returns true if the current protocol has solution or the solution is loading
 */
export const loadingOrSolutionObtained = ({ protocols }) => {
  const { currentProtocol, loadingSolution } = protocols
  if (!currentProtocol) return false
  else return !!(currentProtocol.solution || loadingSolution)
}

export const protocolHasTemplate = ({ protocols }) =>
  !!protocols.currentProtocol.template
