import React from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import classnames from 'classnames/bind'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Input } from '@miroculus/nucleo'
import { LOGIN_URL, LOGOUT_URL } from 'cons/routes'
import { AuthPage } from 'components'
import { canSubmit, getFirstError, getFieldError } from 'utils/forms'
import { setNewPassword } from 'reduxModules/auth'
import { useQuery } from 'hooks'
import browserHistory from 'browserHistory'
import styles from './ResetPassword.scss'

const cx = classnames.bind(styles)

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(12, 'The password should contain at least 12 characters')
    .required('The password is required'),
  passwordRepeat: Yup.string()
    .required('The password confirmation is required')
    .test(
      'password-match',
      'Passwords don\'t match',
      (value, { parent }) => value === parent.password
    )
})

const ResetPassword = () => {
  const query = useQuery()
  const token = query.get('token')
  const dispatch = useDispatch()
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      password: '',
      passwordRepeat: ''
    },
    onSubmit: async (values, actions) => {
      actions.setStatus(undefined)
      try {
        await dispatch(setNewPassword(values.password, token))
        await browserHistory.push(LOGOUT_URL)
      } catch (e) {
        if (e.status === 400) {
          actions.setStatus(e.message)
        } else {
          throw e
        }
      }
    },
    validationSchema: ResetPasswordSchema
  })

  if (!token) return <Redirect to={LOGIN_URL} />

  const errorToDisplay = getFirstError(formik)

  const statusMessage = errorToDisplay || formik.status

  return (
    <AuthPage additionalInfo='Please enter your new password:'>
      <form className={cx('form')} onSubmit={formik.handleSubmit}>
        <Input
          {...formik.getFieldProps('password')}
          name='password'
          type='password'
          placeholder='Enter a new password'
          error={getFieldError(formik, 'password')}
          autoFocus
        />
        <Input
          {...formik.getFieldProps('passwordRepeat')}
          name='passwordRepeat'
          type='password'
          placeholder='Repeat your password'
          error={getFieldError(formik, 'passwordRepeat')}
        />
        <div className={cx('buttonContainer')}>
          <p className={cx('statusMessage')}>{statusMessage || <>&nbsp;</>}</p>
          <Button type='submit' disabled={!canSubmit(formik)}>Reset password</Button>
        </div>
      </form>
    </AuthPage>
  )
}

export default ResetPassword
