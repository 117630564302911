// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingWithText_loadingSpinner__K5WCy{display:flex;flex-direction:column;height:100%;width:100%;top:0;left:0;background-color:rgba(var(--color-white-rgb), 0.9);justify-content:center;align-items:center;position:absolute;z-index:1}.LoadingWithText_loadingSpinner__K5WCy .LoadingWithText_message__2FW7X{margin:1rem;font-size:1rem;color:var(--color-control-text)}.LoadingWithText_loadingSpinner__K5WCy .LoadingWithText_messageFirst__1GyOk{order:-1}", ""]);
// Exports
exports.locals = {
	"loadingSpinner": "LoadingWithText_loadingSpinner__K5WCy",
	"message": "LoadingWithText_message__2FW7X",
	"messageFirst": "LoadingWithText_messageFirst__1GyOk"
};
module.exports = exports;
