import React from 'react'
import { Layout } from 'containers'
import PrivateRoute from './PrivateRoute'
import ValidatorRoute from './ValidatorRoute'

const LayoutRoute = ({
  isPrivate = true,
  ...props
}) => (
  isPrivate
    ? <Layout><PrivateRoute {...props} /></Layout>
    : <Layout><ValidatorRoute {...props} /></Layout>
)

export default LayoutRoute
