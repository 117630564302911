// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OverflownTooltipText_overflown-text-container__2uTBy{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}", ""]);
// Exports
exports.locals = {
	"overflown-text-container": "OverflownTooltipText_overflown-text-container__2uTBy"
};
module.exports = exports;
