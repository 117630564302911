import React, { PureComponent } from 'react'
import { Button } from '@miroculus/nucleo'
import { Log } from '@miroculus/miroculus-ui'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import CodeEditor from '../CodeEditor/CodeEditor'
import styles from './IDE.scss'

const cx = classnames.bind(styles)

export const THEMES = {
  light: 'base16-light',
  dark: 'base16-dark'
}

export default class IDE extends PureComponent {
  static propTypes = {
    onScriptChange: PropTypes.func.isRequired,
    onThemeToggle: PropTypes.func.isRequired,
    script: PropTypes.string,
    theme: PropTypes.oneOf([THEMES.light, THEMES.dark]),
    registry: PropTypes.arrayOf(
      PropTypes.shape({
        logType: PropTypes.oneOf(['error', 'info', 'out', 'code']),
        message: PropTypes.string,
        source: PropTypes.string,
        timestamp: PropTypes.number
      })
    ),
    sources: PropTypes.any,
    onScriptRun: PropTypes.func.isRequired,
    line: PropTypes.shape({
      number: PropTypes.number,
      status: PropTypes.number
    }),
    status: PropTypes.number,
    showLogSources: PropTypes.bool,
    running: PropTypes.bool,
    onScriptStop: PropTypes.func.isRequired,
    onSendScriptAsQC: PropTypes.func.isRequired,
    onSendScriptAsProtocol: PropTypes.func.isRequired,
    showStopButton: PropTypes.bool,
    readOnly: PropTypes.bool
  }

  static defaultProps = {
    script: '',
    theme: THEMES.light,
    registry: [],
    sources: {},
    line: {
      number: -1,
      status: 0
    },
    status: 0,
    showLogSources: true,
    running: false,
    showStopButton: false,
    readOnly: false
  }

  render () {
    const {
      onScriptChange,
      script,
      theme,
      registry,
      sources,
      onScriptRun,
      line,
      status,
      showLogSources,
      onThemeToggle,
      running,
      onSendScriptAsProtocol,
      onScriptStop,
      onSendScriptAsQC,
      showStopButton,
      readOnly,
      hintItems
    } = this.props

    return (
      <div className={cx('container')}>
        <div className={cx('editorContainer', theme)}>
          <CodeEditor
            onScriptChange={onScriptChange}
            script={script}
            theme={theme}
            line={line}
            status={status}
            onThemeToggle={onThemeToggle}
            onScriptRun={onScriptRun}
            onScriptStop={onScriptStop}
            readOnly={readOnly}
            hintItems={hintItems}
          />
        </div>
        <div className={cx('buttonsContainer', theme)}>
          <Button
            size='small'
            onClick={onScriptRun}
            disabled={!script.trim() || running}
          >
            Run
          </Button>
          {showStopButton &&
            <Button
              flavor='secondary'
              size='small'
              onClick={onScriptStop}
              disabled={!running}
            >
              Stop
            </Button>}
        </div>
        <div className={cx('loadContainer', theme)}>
          <div className={cx('mainContainer')}>
            <div className={cx('title')}>
              <p>Load script</p>
            </div>
            <Button
              flavor='secondary'
              data-testid='send-as-qc-button'
              onClick={onSendScriptAsQC}
              disabled={!script.trim() || running}
              size='xsmall'
            >
              As QC
            </Button>
            <Button
              flavor='secondary'
              data-testid='send-as-protocol-button'
              onClick={onSendScriptAsProtocol}
              disabled={!script.trim() || running}
              size='xsmall'
            >
              As protocol
            </Button>
          </div>
        </div>
        <div className={cx('logContainer')}>
          <Log
            onSourceToggle={() => { /* we dont do anything */ }}
            registry={registry}
            sources={sources}
            showSources={showLogSources}
          />
        </div>
      </div>
    )
  }
}
