// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ResetPassword_form__2t_8Y>input{margin:.5rem 0}.ResetPassword_statusMessage__1fUW2{color:var(--color-error);font-weight:600;margin:2rem 0}.ResetPassword_buttonContainer__1YMz3{position:relative;text-align:center;width:100%}", ""]);
// Exports
exports.locals = {
	"form": "ResetPassword_form__2t_8Y",
	"statusMessage": "ResetPassword_statusMessage__1fUW2",
	"buttonContainer": "ResetPassword_buttonContainer__1YMz3"
};
module.exports = exports;
