import React, { lazy, Suspense } from 'react'
import { LoadingSpinner } from '@miroculus/miroculus-ui'

const Anaconda = lazy(() => import(
  /* webpackChunkName: 'anaconda' */
  './Anaconda'
))

const loading = <LoadingSpinner />

export default (props) => (
  <Suspense fallback={loading}>
    <Anaconda {...props} />
  </Suspense>
)
