import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { logout } from 'reduxModules/auth'

export class Logout extends Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired
  }

  static defaultProps = {
  }

  componentDidMount () {
    this.props.onLogout()
  }

  render () {
    return <Spinner />
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
  onLogout: () => { dispatch(logout()) }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(Logout)
