import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames/bind'
import styles from './ProjectSelection.scss'
import PropTypes from 'prop-types'
import { Button } from '@miroculus/nucleo'
import browserHistory from 'browserHistory'
import { selectProject, loadProjects } from 'reduxModules/experiment'
import { releaseDevice } from 'reduxModules/devices'
import { spinner } from 'images'

const cx = classnames.bind(styles)

export const ProjectSelection = ({
  loading,
  projects,
  onMount,
  onBackButtonClick,
  onProjectSelection
}) => {
  useEffect(() => {
    onMount()
  }, [])

  useEffect(() => {
    let timeout
    if (!loading && projects?.length === 0) {
      // We wait a sensible amount of time to prevent race conditions in the
      // messages that we send to the device
      timeout = setTimeout(() => {
        onProjectSelection(null)
      }, 1000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [loading, projects])

  return (
    <div className={cx('container')}>
      {(loading || !projects || projects.length === 0)
        ? (
          <div className={cx('spinnerContainer')}>
            <img src={spinner} alt='logo' height={50} width={50} />
          </div>
          )
        : (
          <>
            <div className={cx('titleContainer')}>
              <h2>Select your project</h2>
            </div>
            <div className={cx('projectsContainer')}>
              {
                projects.map((project) => (
                  <div
                    className={cx('project')}
                    onClick={() => onProjectSelection(project.id)}
                    key={project.id}
                  >
                    {project.name}
                  </div>
                ))
              }
            </div>
            <div className={cx('footer')}>
              <div className={cx('cancelButton')}>
                <Button onClick={onBackButtonClick}>
                  Cancel
                </Button>
              </div>
            </div>
          </>
          )}
    </div>
  )
}

ProjectSelection.propTypes = {
  projects: PropTypes.array,
  onProjectSelection: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

ProjectSelection.defaultProps = {
  loading: true
}

const mapStateToProps = (state) => ({
  projects: state.experiment.projects,
  loading: state.experiment.loadingProjects
})

const mapDispatchToProps = dispatch => ({
  onBackButtonClick: () => {
    dispatch(releaseDevice())
    browserHistory.goBack()
  },
  onProjectSelection: (projectId) => {
    dispatch(selectProject(projectId))
  },
  onMount: () => {
    dispatch(loadProjects())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelection)
