import React, { useCallback } from 'react'
import { Button, Input, Modal, Select } from '@miroculus/nucleo'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import classNames from 'classnames/bind'
import browserHistory from 'browserHistory'
import styles from './MemberModal.scss'
import { useParams } from 'react-router-dom'
import { MEMBERS_URL } from 'cons/routes'
import { FormField } from 'components'
import {
  inviteUserToWorkspace,
  updateWorkspaceMember,
  loadPendingInvites,
  MEMBER_ROLES
} from '../reduxModules/workspaces'
import {
  membersSelector,
  getLoadingMembers
} from '../reduxModules/workspaces/selectors'

const cx = classNames.bind(styles)

const ROLE_OPTIONS = Object.entries(MEMBER_ROLES).map(([value, label]) => ({
  value,
  label
}))

const ACTIVE_OPTIONS = [
  {
    value: 'true',
    label: 'Yes'
  },
  {
    value: 'false',
    label: 'No'
  }
]

const MemberForm = ({ id, name, email, role, active }) => {
  const dispatch = useDispatch()

  const submitForm = id
    ? (values) =>
        dispatch(
          updateWorkspaceMember({
            id,
            ...values
          })
        )
    : async (values) => {
      await dispatch(inviteUserToWorkspace(values.email, values.role))
      await dispatch(loadPendingInvites())
    }

  const initialValues = id
    ? {
        active,
        role
      }
    : {
        email,
        role
      }

  const { dirty, handleSubmit, getFieldProps, setFieldValue } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        await submitForm(values)
        browserHistory.push(MEMBERS_URL)
      } catch (e) {
        toast.error(e.message)
      }
    }
  })

  const emailProps = !id ? getFieldProps('email') : {}
  const roleProps = getFieldProps('role')
  const activeProps = getFieldProps('active')

  const handleRoleChange = useCallback((value) => {
    setFieldValue('role', value)
  }, [])

  const handleActiveChange = useCallback((value) => {
    setFieldValue('active', JSON.parse(value))
  }, [])

  const title = id ? name : 'Invite new member'
  const cta = id ? 'Edit member' : 'Send invite'

  return (
    <form className={cx('modal-content')} onSubmit={handleSubmit}>
      <h1>{title}</h1>
      {id && (
        <FormField title='Full name'>
          <Input frozen value={name} />
        </FormField>
      )}
      <FormField title='Email address'>
        <Input
          type='email'
          placeholder='Enter an email'
          value={email}
          {...emailProps}
          frozen={!!id}
        />
      </FormField>
      <FormField title='Role'>
        <Select
          {...roleProps}
          options={ROLE_OPTIONS}
          onChange={handleRoleChange}
        />
      </FormField>
      {id && (
        <FormField title='Active'>
          <Select
            {...activeProps}
            value={JSON.stringify(activeProps.value)}
            options={ACTIVE_OPTIONS}
            onChange={handleActiveChange}
          />
        </FormField>
      )}
      <Button type='submit' size='small' disabled={!dirty}>
        {cta}
      </Button>
    </form>
  )
}

const MemberModal = () => {
  const id = parseInt(useParams().id, 10)

  const loadingMembers = useSelector(getLoadingMembers)

  const member = useSelector((state) =>
    membersSelector(state).find((m) => m.id === id)
  )

  const handleClose = useCallback(() => {
    browserHistory.push(MEMBERS_URL)
  }, [])

  return (
    !loadingMembers && (
      <Modal label='MemberModal' onClose={handleClose}>
        {member
          ? (
            <MemberForm
              id={id}
              name={member.name}
              email={member.email}
              role={member.role}
              active={member.active}
            />
            )
          : (
            <MemberForm name='' email='' role='collaborator' active />
            )}
      </Modal>
    )
  )
}

export default MemberModal
