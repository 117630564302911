// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TeamModal_modal-content__2Z__g h1{text-transform:capitalize}.TeamModal_modal-content__2Z__g h1.TeamModal_creating__1Kg1Z{text-transform:none}.TeamModal_modal-content__2Z__g button{margin-top:3rem}", ""]);
// Exports
exports.locals = {
	"modal-content": "TeamModal_modal-content__2Z__g",
	"creating": "TeamModal_creating__1Kg1Z"
};
module.exports = exports;
