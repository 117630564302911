// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ExperimentInfo_footer__lO9qc{display:flex;align-items:center;width:100%;background-color:var(--color-input-background);color:var(--color-primary);padding:1rem 2rem}.ExperimentInfo_name__Hxiep{font-size:1.5rem;border-right:2px solid var(--color-primary);padding-right:2rem}.ExperimentInfo_runningTime__2BMX3{padding-left:2rem;flex-grow:1;font-size:1.5rem}", ""]);
// Exports
exports.locals = {
	"footer": "ExperimentInfo_footer__lO9qc",
	"name": "ExperimentInfo_name__Hxiep",
	"runningTime": "ExperimentInfo_runningTime__2BMX3"
};
module.exports = exports;
