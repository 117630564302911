import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  loadUserData, resetUserPassword, updateAccountSettings
} from 'reduxModules/auth'
import { getLoading, getUser } from 'reduxModules/auth/selectors'
import { Button, Input, Tag } from '@miroculus/nucleo'
import { FormField, useConfirm, useBreadcrumb, UserAccountHeader } from 'components'
import { useFormik } from 'formik'
import classNames from 'classnames/bind'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { getFieldError, canSubmit } from 'utils/forms'
import styles from './AccountSettings.scss'

const cx = classNames.bind(styles)

const breadcrumbLevels = [
  {
    text: 'Account settings'
  }
]

const OrganizationAndTeams = memo(({ organizations }) =>
  (
    <div className={cx('account-settings-form-organization')}>
      <h3>Organizations</h3>
      {organizations.map(({ name, teams }) => {
        const organizationName = name ?? 'No organization'
        return (
          <div className={cx('account-settings-form-organization-info')} key={`org-${organizationName}`}>
            <h4 className={cx('account-settings-form-organization__orgs-label')}>{organizationName}</h4>
            <strong className={cx('account-settings-form-organization__teams-label')}>Teams</strong>
            <div className={cx('account-settings-form-organization__teams')}>
              {teams.map(({ title: teamTitle }) => {
                return (
                  <Tag
                    key={`org-${organizationName}-team-${teamTitle}`}
                    textContent={teamTitle}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
)

OrganizationAndTeams.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    teams: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    }))
  }))
}

const UserInfoSchema = Yup.object().shape({
  name: Yup.string().required('A full name is required')
})

export const AccountSettings = ({
  user,
  getUserData,
  onUpdateUserAccount,
  onResetPassword,
  organizations,
  loading
}) => {
  const { name, email, createdAt } = user

  const formik = useFormik({
    initialValues: {
      name
    },
    onSubmit: async (values, actions) => {
      actions.setStatus(undefined)

      try {
        await onUpdateUserAccount({ ...values })
        toast.success('Your user information was successfully updated!')
      } catch (e) {
        toast.error('There was an error submitting your user information')
        if (e.status === 400) {
          actions.setStatus(e.message)
        } else {
          throw e
        }
      }
    },
    validationSchema: UserInfoSchema
  })

  const {
    getFieldProps,
    handleSubmit
  } = formik

  const { confirm } = useConfirm()

  const handleResetPassword = () => {
    confirm(
      <>
        <p>We'll send you an email with instructions to reset your password.</p>
        <p><strong>Are you sure you want to proceed?</strong></p>
      </>,
      async () => {
        try {
          await onResetPassword()
          toast.success('An email has been sent! Check your inbox.')
        } catch (e) {
          toast.error('There was an error trying to send the instructions')
        }
      },
      { okText: 'Yes', level: 'info' }
    )
  }

  useEffect(() => {
    if (!user.createdAt) {
      getUserData()
    }
  }, [user])

  useBreadcrumb(breadcrumbLevels)

  return (
    <section className={cx('container')}>
      <div className={cx('account-settings')}>
        {user?.createdAt &&
          <>
            <UserAccountHeader userFullName={name} joinedDate={createdAt} />
            <div className={cx('account-settings-form')}>
              <form className={cx('form')} onSubmit={handleSubmit}>
                <div className={cx('account-settings-form-personal-data')}>
                  <FormField title='FULL NAME'>
                    <Input
                      {...getFieldProps('name')}
                      name='name'
                      type='text'
                      placeholder='Enter your name'
                      error={getFieldError(formik, 'name')}
                      autoFocus
                    />
                  </FormField>
                  <div className={cx('fill-row')}>
                    <FormField title='EMAIL ADDRESS' className={cx('fill-col')}>
                      <Input
                        value={email}
                        name='email'
                        type='email'
                        placeholder='Enter your email'
                        error={getFieldError(formik, 'email')}
                        frozen
                      />
                    </FormField>
                    <FormField title='PASSWORD' className={cx('fill-col')}>
                      <Input
                        value='super secret phrase'
                        name='password'
                        type='password'
                        placeholder='Enter your password'
                        maxLength={512}
                        frozen
                      />
                      <button
                        type='button'
                        onClick={handleResetPassword}
                        className={cx('reset-password')}
                        data-testid='reset-password'
                      >
                        Reset password
                      </button>
                    </FormField>
                  </div>
                </div>
                <OrganizationAndTeams organizations={organizations} />
                <div className={cx('account-settings-form-submit')}>
                  <Button
                    size='small'
                    disabled={loading || !canSubmit(formik)}
                    type='submit'
                    data-testid='submit-account-settings'
                  >
                    SAVE
                  </Button>
                </div>
              </form>
            </div>
          </>}
      </div>
    </section>
  )
}

AccountSettings.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    createdAt: PropTypes.number
  }),
  organizations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    teams: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }))
  })),
  loading: PropTypes.bool.isRequired,
  onUpdateUserAccount: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const { organizations: { organizations } } = state

  return {
    organizations,
    loading: getLoading(state),
    user: getUser(state)
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateUserAccount: (userData) => { dispatch(updateAccountSettings(userData)) },
  onResetPassword: () => { dispatch(resetUserPassword()) },
  getUserData: () => { dispatch(loadUserData()) }
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)
