import { findTeamById } from 'reduxModules/workspaces/selectors'
import { devicesSelector } from 'reduxModules/devices/selectors'

export const getLoadingOrganizations = ({ organizations }) =>
  organizations.loadingOrganizations

export const getOrganizations = ({ organizations }) =>
  organizations.organizations

export const getOrganizationsAndTeamsList = ({ organizations }) => {
  const { organizations: organizationList } = organizations

  return organizationList
    .map(({ name: organizationName = 'No organization', teams }) => ({
      name: organizationName,
      teams: teams.map(({ id, title: name }) => ({ id, name }))
    }))
}

export const getCurrentOrganization = (state) =>
  state.organizations.currentOrganization
    ? ({
        ...state.organizations.currentOrganization,
        devices: devicesSelector(state)
      })
    : null

export const getCurrentOrganizationName = ({
  organizations: { organizations, currentOrganization }
}) =>
  organizations.find(o => o.name === currentOrganization?.name)?.name

export const getCurrentOrganizationPermissions = ({
  organizations: { currentOrganization }
}) => {
  return currentOrganization?.$permissions ?? {}
}

export const canEditOrganization = (state) =>
  getCurrentOrganizationPermissions(state).canEditOrganization

export const getSendingInvitation = ({ organizations }) => organizations.sendingInvitation

export const getUpdatingOrganization = ({ organizations }) => organizations.updatingOrganization

export const getOrganizationFromTeam = (organizations, teamId) =>
  organizations.find(({ teams }) => teams.some(({ id }) => id === teamId))

export const findOrganizationByTeamId = (organizations, teamId) =>
  organizations.find(({ teams: orgTeams }) => findTeamById(orgTeams, teamId))

export const findOrganizationBySlug = (organizations, orgSlug) =>
  organizations.find(({ slug }) => slug === orgSlug)

export const membersSelector = ({ organizations }) => organizations.currentOrganization.members ?? []
export const teamsSelector = ({ organizations }) => organizations.currentOrganization.teams ?? []
