import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { PaletteDndProvider } from '@miroculus/nucleo'
import {
  AccountSettings,
  ConnectedLogin,
  ConnectedLogout,
  ConnectedSignUp,
  ProtocolSelection,
  ProtocolCanvas,
  ProtocolCreation,
  DeviceDetails,
  Devices,
  Anaconda,
  ProjectSelection,
  Members,
  ProtocolRouting,
  ForgotPassword,
  ResetPassword,
  RecentProtocols,
  RunDetails,
  Drafts,
  CreateOrganization,
  Organization,
  OrganizationSettings,
  OrganizationInvite
} from 'containers'
import { ProtocolLoader, PageNotFoundError } from 'components'
import { SendProtocolModal, MemberModal, OrganizationMemberModal, TeamModal } from 'modals'
import {
  ACCOUNT_SETTINGS_URL,
  LOGIN_URL,
  SIGNUP_URL,
  LOGOUT_URL,
  DASHBOARD_URL,
  ANACONDA_URL,
  PROJECT_SELECTION_URL,
  MEMBERS_URL,
  NEW_MEMBER_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  RECENT_URL,
  DRAFTS_URL,
  CREATE_ORGANIZATION_URL,
  ORGANIZATION_INVITE_URL,
  organizationUrl,
  organizationSettingsUrl,
  instrumentsUrl,
  instrumentDetailsUrl,
  instrumentRunDetailsUrl,
  editProtocolUrl,
  protocolUrl,
  saveProtocolUrl,
  teamUrl,
  protocolRouteUrl,
  copyProtocolUrl,
  sendProtocolUrl,
  editMemberUrl,
  newOrganizationMemberUrl,
  editOrganizationMemberUrl,
  newOrganizationTeamUrl,
  editOrganizationTeamUrl
} from 'cons/routes'
import AutoLogin from './AutoLogin'
import OrganizationLoader from './OrganizationLoader'
import LayoutRoute from './LayoutRoute'
import PrivateRoute from './PrivateRoute'
import { getLogged } from 'reduxModules/auth/selectors'
import { currentDeviceSelector } from 'reduxModules/devices/selectors'
import { getCurrentWorkspace } from 'reduxModules/workspaces/selectors'
import { canEditOrganization } from 'reduxModules/organizations/selectors'

const Routes = () => {
  return (
    <AutoLogin>
      <OrganizationLoader>
        <Switch>
          <Route path={LOGIN_URL}>
            <ConnectedLogin />
          </Route>
          <Route path={SIGNUP_URL}>
            <ConnectedSignUp />
          </Route>
          <Route path={ORGANIZATION_INVITE_URL}>
            <OrganizationInvite />
          </Route>
          <Route path={FORGOT_PASSWORD_URL}>
            <ForgotPassword />
          </Route>
          <Route path={RESET_PASSWORD_URL}>
            <ResetPassword />
          </Route>
          <PrivateRoute path={CREATE_ORGANIZATION_URL} signUp redirect>
            <CreateOrganization />
          </PrivateRoute>
          <PrivateRoute path={LOGOUT_URL}>
            <ConnectedLogout />
          </PrivateRoute>
          <Route>
            <Switch>
              <LayoutRoute exact path={DASHBOARD_URL}>
                <RecentProtocols />
              </LayoutRoute>
              <LayoutRoute exact path={DRAFTS_URL}>
                <Drafts />
              </LayoutRoute>
              <LayoutRoute exact path={RECENT_URL}>
                <RecentProtocols />
              </LayoutRoute>
              <LayoutRoute exact path={ACCOUNT_SETTINGS_URL}>
                <AccountSettings />
              </LayoutRoute>
              <LayoutRoute path={sendProtocolUrl(':teamId', ':id')}>
                <SendProtocolModal />
              </LayoutRoute>
              <LayoutRoute path={saveProtocolUrl(':teamId', ':id')}>
                <ProtocolLoader>
                  <ProtocolCreation />
                </ProtocolLoader>
              </LayoutRoute>
              <LayoutRoute path={copyProtocolUrl(':teamId', ':id')}>
                <ProtocolLoader copy>
                  <ProtocolCreation />
                </ProtocolLoader>
              </LayoutRoute>
              <LayoutRoute path={editProtocolUrl(':teamId', ':id')}>
                <ProtocolLoader>
                  <PaletteDndProvider>
                    <ProtocolCanvas editing />
                  </PaletteDndProvider>
                </ProtocolLoader>
              </LayoutRoute>
              <LayoutRoute path={protocolRouteUrl(':teamId', ':id')}>
                <ProtocolLoader solution>
                  <ProtocolRouting />
                </ProtocolLoader>
              </LayoutRoute>
              <LayoutRoute path={protocolUrl(':teamId', ':id')}>
                <ProtocolLoader>
                  <ProtocolCanvas />
                </ProtocolLoader>
              </LayoutRoute>
              <LayoutRoute exact path={organizationUrl(':orgSlug')}>
                <Organization />
              </LayoutRoute>
              <LayoutRoute
                isPrivate={false}
                path={[newOrganizationMemberUrl(':orgSlug'), editOrganizationMemberUrl(':orgSlug', ':id')]}
                validators={[getLogged, canEditOrganization]}
                redirectionPath={DASHBOARD_URL}
              >
                <OrganizationSettings initialTab={1} />
                <OrganizationMemberModal />
              </LayoutRoute>
              <LayoutRoute
                isPrivate={false}
                path={[newOrganizationTeamUrl(':orgSlug'), editOrganizationTeamUrl(':orgSlug', ':id')]}
                validators={[getLogged, canEditOrganization]}
                redirectionPath={DASHBOARD_URL}
              >
                <OrganizationSettings initialTab={2} />
                <TeamModal />
              </LayoutRoute>
              <LayoutRoute
                isPrivate={false}
                path={organizationSettingsUrl(':orgSlug')}
                validators={[getLogged, canEditOrganization]}
                redirectionPath={DASHBOARD_URL}
              >
                <OrganizationSettings />
              </LayoutRoute>
              <LayoutRoute path={teamUrl(':teamId')}>
                <ProtocolSelection />
              </LayoutRoute>
              <LayoutRoute path={instrumentsUrl(':orgSlug')} exact>
                <Devices />
              </LayoutRoute>
              <LayoutRoute path={instrumentDetailsUrl(':orgSlug', ':deviceId')} exact>
                <DeviceDetails />
              </LayoutRoute>
              <LayoutRoute path={instrumentRunDetailsUrl(':orgSlug', ':deviceId', ':runId')}>
                <RunDetails />
              </LayoutRoute>
              <LayoutRoute
                isPrivate={false}
                path={NEW_MEMBER_URL}
                validators={[getCurrentWorkspace]}
              >
                <Members />
                <MemberModal />
              </LayoutRoute>
              <LayoutRoute
                isPrivate={false}
                path={editMemberUrl(':id')}
                validators={[getCurrentWorkspace]}
              >
                <Members />
                <MemberModal />
              </LayoutRoute>
              <LayoutRoute
                isPrivate={false}
                path={MEMBERS_URL}
                validators={[getCurrentWorkspace]}
              >
                <Members />
              </LayoutRoute>
              <LayoutRoute
                isPrivate={false}
                path={PROJECT_SELECTION_URL}
                validators={[currentDeviceSelector]}
              >
                <ProjectSelection />
              </LayoutRoute>
              <LayoutRoute
                isPrivate={false}
                path={ANACONDA_URL}
                validators={[currentDeviceSelector]}
              >
                <Anaconda />
              </LayoutRoute>
              <Route path='*'>
                <PageNotFoundError />
              </Route>
            </Switch>
          </Route>
        </Switch>
      </OrganizationLoader>
    </AutoLogin>
  )
}

export default withRouter(Routes)
